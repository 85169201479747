import { Component, Inject, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { ShInputTextComponent } from 'src/app/components/shared_components/sh-input-text/sh-input-text.component';
import { ShSelectDropdownComponent } from 'src/app/components/shared_components/sh-select-dropdown/sh-select-dropdown.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { closeDialog, dbConstants, educationForm } from '../util';
import { dynamicElementTypes } from '../../onboarding/utils';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';
import { ShCustomSelectComponent } from 'src/app/components/shared_components/sh-ng-select/sh-ng-select.component';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { catchError, map, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isResearcher } from 'src/app/utils/utils';
import { COLLECTION_NAMES } from 'src/app/constants/constants';
import { SortByPipe } from 'src/app/pipes/sortBy.pipe';

@Component({
  selector: 'app-sh-education-add-profile-model',
  standalone: true,
  imports: [CommonModule, MatButtonModule,
    // ShSelectDropdownComponent,
    ShInputTextComponent,
    ShCustomSelectComponent,
    ShSpinnerLoaderComponent,
    MatIconModule, MatButtonModule, MatMenuModule, SortByPipe],
  templateUrl: './sh-education-add-profile-model.component.html',
  styleUrls: ['./sh-education-add-profile-model.component.scss']
})
export class ShEducationAddProfileModelComponent {
  openForm: boolean = false;
  form: FormGroup;
  profile: any;
  currentUser: any;
  pageContents = educationForm;
  pageLayout: any;
  dynamicElementTypes = dynamicElementTypes;
  qualifications: any;
  isEdit: boolean;
  editIndex: any;
  uploadingData: boolean;

  constructor(
    private fb: FormBuilder,
    private fbService: FirebaseCollectionService,
    private snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: { profile: any, user: any },  // Inject bottom sheet data
    @Optional() public dialogRef: MatDialogRef<ShEducationAddProfileModelComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShEducationAddProfileModelComponent>
  ) {
    this.profile = data?.profile || bottomSheetData?.profile;
    this.currentUser = data?.user || bottomSheetData?.user;

    this.qualifications = [...this.profile?.qualifications || []];
    this.pageLayout = this.pageContents.elements;
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(qualification?) {
    const group = {};
    this.pageLayout.forEach((element: any) => {
      group[element?.attribute] = qualification ? new FormControl(qualification[element?.attribute]) : new FormControl();

      if (qualification) {
        if (element.attribute === 'yearOfGraduation') {
          element.selected = qualification[element?.attribute];
        }
      }
    });
    this.form = this.fb.group(group);
  }

  cancelClick(value): void {
    if (value === 'model' && this.dialogRef) {
      this.dialogRef.close();  // Close dialog if it exists
    }
    if (value === '' && this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();  // Close bottom sheet if it exists
    }
  }

  showForm() {
    this.openForm = !this.openForm;
    this.isEdit = false;
  }

  addQualification() {
    this.add();
    this.showForm();
  }

  edit(qualification, index) {
    this.openForm = true;
    this.buildForm(qualification);
    this.isEdit = true;
    this.editIndex = index;
  }

  delete(qualification, index) {
    this.qualifications.splice(index, 1);
  }

  add() {
    const qualification = { ...this.form.value };
    qualification.yearOfGraduation = this.form.value.yearOfGraduation.id || this.form.value.yearOfGraduation;
    if (!this.isEdit) {
      this.qualifications.push(qualification);
    } else {
      this.qualifications[this.editIndex] = qualification;
    }
    this.form.reset();
  }


  save() {
    this.uploadingData = true;
    const collectionName = this.getCollectionInfoRespectiveToTheSpecialization();
    this.fbService.updateDocument(collectionName, this.profile.id, { qualifications: this.qualifications }).pipe(
      map(res => {
        this.snackBar.open('Updated successfully!', 'OK');
        closeDialog(this.dialogRef, this.bottomSheetRef);
        this.uploadingData = false;
      }),
      catchError(e => {
        this.uploadingData = false;
        return of(e);
      })
    ).subscribe();
  }

  getCollectionInfoRespectiveToTheSpecialization() {
    let collection = "";
    if (isResearcher(this.currentUser)) {
      collection = COLLECTION_NAMES.researchers;
    } else {
      collection = COLLECTION_NAMES.institutes;
    }
    return collection;
  }

  onSearch(data) {
    const { term, element } = data;
    const isInternalSearch = dbConstants[element.attribute].isInternalSearch;
    if (isInternalSearch) {
      if (term || term === "") {
        element.filterData = [...element.data.filter(dd => dd.name?.toString()?.includes(term))];
      }
    }
  }

  trackById(data) {
    return data.id;
  }

}
