import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from 'src/app/pipes/dateAgo.pipe';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PROFILE } from 'src/app/containers/profile/sh-tab-profile/sh-tab-profile.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { LoginPageComponent } from 'src/app/containers/login/login-page/login-page.component';
import { catchError, map, Observable, of, take } from 'rxjs';
import { COLLECTION_NAMES } from 'src/app/constants/constants';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ShAddMyItemsModelComponent } from 'src/app/containers/profile/sh-myitems-profile/components/sh-profile-datasets/sh-add-datasets-model/sh-add-my-items-model.component';
import { myProfileItems } from 'src/app/containers/profile/util';
import { ShProfileDeleteModelComponent } from 'src/app/containers/profile/sh-profile-delete-model/sh-profile-delete-model.component';
import { AuthenticationService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/userInfo.model';

@Component({
  selector: 'app-sh-post-list',
  standalone: true,
  imports: [CommonModule, DateAgoPipe, MatMenuModule,
  MatIconModule, MatButtonModule, RouterModule],
  templateUrl: './sh-post-list.component.html',
  styleUrls: ['./sh-post-list.component.scss']
})
export class ShPostListComponent {
  @Input() posts: any;
  @Input() profileId: any;
  @Input() cssClass: string = 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3';
  @Input() profileImage: boolean;
  @Input() imageRatio : boolean = false;

  user$: Observable<User> = this.authService.getUser();
  entityId: string;
  userData: User;


  constructor(
    private snackBar: MatSnackBar, private dialogModel: MatDialog,  private authService: AuthenticationService,
    private breakpointObserver: BreakpointObserver, @Inject(PROFILE) private profileInjected: any,
    public dialog: MatDialog, private _bottomSheet: MatBottomSheet, private fbService: FirebaseCollectionService,) { }
    ngOnInit(): void {
      this.user$.subscribe((user) => {
        this.userData = user;
        this.entityId = user?.entityId; 
      });

    }

    updateLike(post): void {
      if (!this.profileId) {
        // Use BreakpointObserver to check screen size
        this.breakpointObserver.observe([
          '(max-width: 767px)' // Custom breakpoint for below 767px
        ]).pipe(take(1)).subscribe(result => {
          if (result.breakpoints['(max-width: 767px)']) {
            // If the screen size matches mobile or tablet, open a bottom sheet
            const bottomSheetRef = this._bottomSheet.open(LoginPageComponent, {
              data: {
                value: true,
              }
  
            });
            bottomSheetRef.afterDismissed().subscribe(result => { });
          } else {
            // Otherwise, open a dialog for larger screens
            const dialogRef = this.dialog.open(LoginPageComponent, {
              width: '450px',
              // height: '500px',
              panelClass: 'profile-model',
              data: {
                value: true,
              }
            });
          }
        });
        this.snackBar.open("You are not logged in please login and try again", 'OK');
  
      } else {
        let { likedBy, likes } = post;
        if (!likedBy?.includes(this.profileId)) {
          likedBy.push(this.profileId);
          likes = likes + 1;
        } else {
          const index = likedBy.indexOf(this.profileId);
          if (index > -1) {
            likedBy.splice(index, 1);
          }
          likes = (likes - 1) || 0;
        }
  
        const payload = { likes, likedBy };
        this.updatePost(payload, post);
      }
    }
  
    updatePost(payload, post) {
      let obs$ = null;
      const collection = COLLECTION_NAMES.posts;
  
      obs$ = this.fbService.updateDocument(collection, post.id, payload);
  
      obs$.pipe(
        map(res => {
          // Liked
        }),
        catchError(e => {
          return of(e)
        })
      ).subscribe();
    }

    edit(post?): void {
      // Use BreakpointObserver to check screen size
      this.breakpointObserver.observe([
        '(max-width: 767px)' // Custom breakpoint for below 767px
      ]).pipe(take(1)).subscribe(result => {
        if (result.breakpoints['(max-width: 767px)']) {
          // If the screen size matches mobile or tablet, open a bottom sheet
          const bottomSheetRef = this._bottomSheet.open(ShAddMyItemsModelComponent, {
            data: {
              entity: myProfileItems.posts,
              profile:  this.userData,
              data: post
            }
          });
          bottomSheetRef.afterDismissed().subscribe(result => { });
        } else {
          // Otherwise, open a dialog for larger screens
          const dialogRef = this.dialogModel.open(ShAddMyItemsModelComponent, {
            width: '450px',
            // height: '650px',
            panelClass: 'profile-model',
            data: {
              entity: myProfileItems.posts,
              profile:  this.userData,
              data: post
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
  
            }
          });
        }
      });
    }
  
    confirmDelete(item) {
      const modalRef = this.dialogModel.open(ShProfileDeleteModelComponent, {
        width: '350px',
        //  height: '300px',
        panelClass: 'profile-model',
        autoFocus: false,
        data: {
          entity: myProfileItems.posts
        }
      });
  
      modalRef.afterClosed().pipe(
        take(1),
        map(confirmed => {
          if (confirmed) {
            this.delete(item);
          }
        })
      ).subscribe();
  
    }
  
    delete(post) {
      const collection = COLLECTION_NAMES.posts;
      this.fbService.deleteDocument(collection, post?.id).pipe(
        take(1),
        map(res => {
          this.snackBar.open("Post deleted successfully!", "OK");
        })
      ).subscribe();
    }
}
