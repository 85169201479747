<div class="w-full gap-x-4 flex lg:flex-row flex-col mb-8">
    <!-- Sidebar Menu -->
    <div class="w-full lg:w-1/4 p-4 bg-white rounded-3xl flex flex-col mb-4 lg:mb-0 h-full">
        <span class="text-base font-bold text-darkBlack">Filter Items</span>

        <div class="w-full lg:w-1/4 bg-white rounded-3xl flex flex-col mb-4 lg:mb-0">
            <!-- Dropdown for small screens -->
            <div class="block lg:hidden">
                <select id="filterDropdown" (change)="setTab($event)"
                    class="mt-2 p-2 w-full rounded-lg border text-greyColor bg-white focus:outline-none">
                    <option *ngFor="let item of menuItems" [value]="item.value">
                        {{ item.name }}
                    </option>
                </select>

            </div>

            <!-- Regular list for larger screens -->
            <div class="hidden lg:flex flex-col gap-y-3 mt-4">

                <a *ngFor="let item of menuItems" href="javascript:void(0)" (click)="setTab(item.value)" [ngClass]="{
                  'text-primary': selectedTab === item.value,
                  'text-greyColor hover:text-primary': selectedTab !== item.value
                }" class="text-sm">
                    {{ item.name }}
                </a>
            </div>
        </div>

    </div>

    <!-- Content Section -->
    <div class="w-full lg:w-3/4 rounded-3xl flex flex-col gap-4">
        <div class="w-full flex justify-between items-center">
            <span class="font-bold text-2xl"  *ngIf="!isSavedItems">My {{ selectedTab | titlecase }}</span>
            <span class="font-bold text-2xl"  *ngIf="isSavedItems">Saved {{ selectedTab | titlecase }}</span>
            <button mat-raised-button color="primary" class="!rounded-lg !h-11 !px-5 !font-bold" *ngIf="!isSavedItems"
                (click)="openAddDatasets()">ADD NEW</button>
        </div>

        <!-- Dynamic content based on selected tab -->
        <div class="flex flex-col">
            <ng-container [ngSwitch]="selectedTab">
                <app-sh-profile-datasets-papers [isSavedItems]="isSavedItems" [Image]="true" [profile]="profile"
                    [entity]="myProfileItems.dataset" [selectedTab]="currentTab" *ngSwitchCase="'datasets'">
                </app-sh-profile-datasets-papers>

                <app-sh-profile-datasets-papers [isSavedItems]="isSavedItems" [Image]="false" [profile]="profile"
                    [entity]="myProfileItems.papers" [selectedTab]="currentTab" *ngSwitchCase="'papers'">
                </app-sh-profile-datasets-papers>

                <app-sh-profile-posts *ngSwitchCase="'posts'" [isSavedItems]="isSavedItems" [profile]="profile">
                </app-sh-profile-posts>

                <app-sh-profile-event-conference [isSavedItems]="isSavedItems" [profile]="profile"
                    [moduleEntity]="myProfileItems.events" *ngSwitchCase="'events'" [selectedTab]="currentTab">
                </app-sh-profile-event-conference>

                <app-sh-profile-event-conference [isSavedItems]="isSavedItems" *ngSwitchCase="'conferences'"
                    [isSavedItems]="isSavedItems" [profile]="profile" [moduleEntity]="myProfileItems.conference"
                    [selectedTab]="currentTab">
                </app-sh-profile-event-conference>

                <app-sh-profile-job *ngSwitchCase="'jobs'" [profile]="profile" [isSavedItems]="isSavedItems"
                    [moduleEntity]="myProfileItems.jobs" [selectedTab]="currentTab">
                </app-sh-profile-job>

                <app-sh-profile-grants *ngSwitchCase="'grants'" [profile]="profile" [isSavedItems]="isSavedItems"
                    [moduleEntity]="myProfileItems.grants" [selectedTab]="currentTab"></app-sh-profile-grants>

            </ng-container>
        </div>
    </div>
</div>