import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sortBy",
    standalone: true,
})
export class SortByPipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return array;
        }

        array.sort((a: any, b: any) => {
            if ((a[field] || a) < (b[field] || b)) {
                return -1;
            } else if ((a[field] || a) > (b[field] || b)) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}