import { format } from "../utils/date-service.utils";
import { getCharCombo, getSubThemes, getSubThemesKeywords, getTermsAsKeywords, getWordsCombo } from "./utils";

export interface DataSet {
    abstract?: string,
    dataset_access?: string,
    difficulty?: string,
    downloads?: number,
    format?: string,
    index?: number,
    licence?: string,
    link?: string,
    name?: string,
    publishDate?: string,
    source?: string,
    subThemeIds?: any[],
    views?: number,
    keywords?: any[],
    authorId?: string,
    year?: number,
    collectionMethodology?: string
}


export function datasetDbo(data, dataset?, profile?) {
    const dbo: DataSet = {};
    dbo.abstract = data?.abstract;
    dbo.dataset_access = data?.dataset_access?.id || data?.dataset_access;
    dbo.difficulty = data?.difficulty || 'Easy';
    dbo.downloads = 0;
    dbo.index = data?.index || 1;
    dbo.format = data?.format?.id || data?.format;
    dbo.keywords = buildKeywords(data, dataset?.keywords);
    dbo.licence = data?.licence || null;
    dbo.link = data?.link || null;
    dbo.name = data?.name || null;
    dbo.subThemeIds = getSubThemes(data?.subThemeIds);
    dbo.publishDate = format(new Date());
    dbo.source = data?.source || null;
    dbo.views = 0;
    dbo.authorId = profile?.id;
    dbo.year = data?.year?.id || data?.year;
    dbo.collectionMethodology = data?.collectionMethodology;

    // console.log(dbo);
    return dbo;
}

export function buildKeywords(data, existingKeywords) {
    let keywords = [];

    let nameArr = data?.name.split("");
    keywords = [...keywords, ...getCharCombo(nameArr, existingKeywords)];

    const wordKeyword = [...data?.name.split(" ")];
    keywords = [...keywords, ...getTermsAsKeywords(wordKeyword)];

    const format = data?.format?.id || data?.format;
    const access = data?.dataset_access?.id || data?.dataset_access;
    const otherKeywordAttr = [format, access];
    keywords = [...keywords, ...getTermsAsKeywords(otherKeywordAttr, existingKeywords)];

    // Sub themes into Keywords
    if (data?.subThemeIds?.length) {
        keywords = [...keywords, ...getSubThemesKeywords(data.subThemeIds, existingKeywords)];
    }

    keywords = [...new Set([...keywords, ...existingKeywords || []])];

    return keywords;
}
