import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsListingComponent } from '../common/cards-listing/cards-listing.component';
import { PROFILE } from 'src/app/containers/profile/sh-tab-profile/sh-tab-profile.component';
import { FilterType, FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { COLLECTION_NAMES } from 'src/app/constants/constants';
import { map, Subject, take, takeUntil } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { ShEmptyStateComponent } from 'src/app/containers/profile/sh-myitems-profile/components/sh-profile-datasets/sh-empty-state/sh-empty-state.component';

@Component({
  selector: 'app-sh-my-networks',
  standalone: true,
  imports: [CommonModule, RouterModule, CardsListingComponent, ShEmptyStateComponent],
templateUrl: './sh-my-networks.component.html',
  styleUrls: ['./sh-my-networks.component.scss']
})
export class ShMyNetworksComponent {

  destroy$ = new Subject();

  cssClasses = 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4';

  @Input() loadingNetwork;
  @Input() networks;
  @Input() university;

  @Output() private switchResearcher = new EventEmitter();

  profile: any;
  user: any;
  networksItemsCountForSingleRow = 4;
  networkLimit = 3;

  constructor(
    @Inject(PROFILE) private profileInjected: any,
    private fbService: FirebaseCollectionService
  ) {
    this.profile = profileInjected.profile;
    this.user = profileInjected.user;

    if (this.profile?.id) {
      this.networkLimit = 10;
      this.loadingNetwork = true;
      let isFetchingNetworks = true;
      if (this.profile?.university) {
        this.getUniversity();
      }

      if (this.profile?.university?.length) {
        this.getNetworkByUniversity();
      } else {
        isFetchingNetworks = false;
      }


      if (!isFetchingNetworks) {
        this.loadingNetwork = false;
      }
    }
  }

  getUniversity() {
    if (this.profile.universityId) {
      this.fbService
        .getDocument(COLLECTION_NAMES.universities, this.profile.universityId)
        .pipe(
          takeUntil(this.destroy$),
          map((doc) => {
            if (doc) {
              this.university = doc.payload.data();
              this.loadingNetwork = false;
            }
          })
        )
        .subscribe();
    }
  }

  getNetworkByUniversity(limit?: number) {
    const filter: FilterType[] = [
      {
        field: "university",
        comparator: "==",
        value: this.profile?.university
      }
    ];

    this.fbService
      .queryCollectionSnapshot(COLLECTION_NAMES.researchers, filter, null, limit || 12)
      .pipe(
        takeUntil(this.destroy$),
        map((researchers) => {
          this.networks = researchers.filter(researcher => researcher.id !== this.profile.id);
          if (this.profile?.InstituteIds?.length) {
            this.getNetworkByInstitute(limit);
          }
          this.loadingNetwork = false;
        })
      )
      .subscribe();
  }

  getNetworkByInstitute(limit?) {
    const instituteIds = Object.keys(this.profile.positions || {});
    const filter: FilterType[] = [
      {
        field: "InstituteIds",
        comparator: "array-contains-any",
        value: instituteIds
      }
    ];
    this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.researchers,
        instituteIds?.length ? filter : [],
        null, this.networkLimit || 10)
      .pipe(
        takeUntil(this.destroy$),
        map((researchers) => {
          this.loadingNetwork = false;
          const networks = researchers.filter(researcher => researcher.id !== this.profile.id);
          // this.networks = [...this.networks, ...networks];
          [...networks].forEach(network => {
            if (!this.networks.find(universityNetwork => universityNetwork.id === network.id)) {
              this.networks.push(network);
            }
          });
          // this.networks = this.networks.splice(0, this.networksItemsCountForSingleRow);
        })
      )
      .subscribe();
  }

  loadResearcher(researcher) {
    this.switchResearcher.emit(researcher);
  }

}
