<div class="flex flex-col gap-x-4">
  <span class="text-sm mb-1" *ngIf="label">{{ label }}</span>
  <div *ngIf="!selectedFileURL"
    class="border border-dashed border-[#E1E4E8] rounded-lg flex flex-col items-center justify-center"
    [ngClass]="selectedFileURL ? 'w-1/2': 'w-full'">
    <label for="postImageUploadInput" class="cursor-pointer text-center w-full h-full p-6">
      <div class="flex flex-col items-center">
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M34.7502 12.719L30.6582 1.44901C30.3562 0.617007 29.4682 0.175007 28.6802 0.465007L1.48416 10.391C0.696156 10.679 0.298156 11.585 0.602156 12.417L4.91416 24.299V17.933C4.91416 15.057 7.21016 12.719 10.0342 12.719H17.2202L25.7902 6.70301L30.7482 12.719H34.7502ZM38.9762 16.379H10.0342C9.83132 16.3784 9.63041 16.4184 9.44328 16.4967C9.25615 16.575 9.08657 16.6899 8.94455 16.8347C8.80252 16.9795 8.69092 17.1513 8.61632 17.34C8.54171 17.5286 8.50562 17.7302 8.51016 17.933V36.773C8.51216 37.661 9.19616 38.379 10.0342 38.379H38.9762C39.8162 38.379 40.5002 37.661 40.5002 36.773V17.933C40.5047 17.7302 40.4686 17.5286 40.394 17.34C40.3194 17.1513 40.2078 16.9795 40.0658 16.8347C39.9237 16.6899 39.7542 16.575 39.567 16.4967C39.3799 16.4184 39.179 16.3784 38.9762 16.379ZM36.5002 34.379H12.5002V30.379L16.4682 22.343L22.0042 29.215L27.2002 23.891L33.8762 21.481L36.5002 28.379V34.379Z"
            fill="#D6ECFF" />
        </svg>
        <button
          class="font-bold text-xs md:text-sm text-primary hover:bg-[#D6ECFF] px-3 rounded-lg border border-[#D6ECFF] bg-white h-8 uppercase mt-2"
          (click)="fileUpload()">
          {{buttonLabel}}
        </button>
      </div>
    </label>
    <input type="file" id="postImageUploadInput" class="hidden" accept="image/*" (change)="onFileSelected($event)" />
  </div>

  <!-- Display Selected File -->
  <div *ngIf="selectedFileURL" class="
       relative
       cursor-pointer
       rounded-lg
       overflow-hidden
       border border-lightGrey min-h-[200px]
     " [ngClass]="selectedFileURL ? 'w-full': 'w-1/2'">
    <img [src]="selectedFileURL" alt="Selected Image" class="w-full h-full object-cover" />
    <div class="absolute top-1 right-1 rounded-full p-1 text-white bg-white cursor-pointer" (click)="removeImage()">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.7961 11.522L17.7458 6.57227L19.16 7.98648L14.2103 12.9362L19.16 17.8859L17.7458 19.3001L12.7961 14.3504L7.84634 19.3001L6.43213 17.8859L11.3819 12.9362L6.43213 7.98648L7.84634 6.57227L12.7961 11.522Z"
          fill="#24292E" />
      </svg>

    </div>
  </div>
</div>