// image-conversion.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, from, map, Observable, of, switchMap } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  downloadURL: any;
  constructor(private http: HttpClient, private storage: AngularFireStorage) { }

  // Method to convert image URL to Base64 string
  convertImageUrlToBase64(imageUrl: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.http.get(imageUrl, { responseType: 'blob' }).subscribe(
        (blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            // Validate and add data URL prefix if missing
            const base64Prefix = 'data:image/png;base64,';
            if (!base64data.startsWith('data:image')) {
              observer.next(`${base64Prefix}${base64data.split(',')[1]}`);
            } else {
              observer.next(base64data);
            }
            observer.complete();
          };
          reader.onerror = (error) => {
            observer.error(error);
          };
          reader.readAsDataURL(blob); // Converts blob to base64 string
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  uploadPdf(file, filePath) {
    if (file) {
      const isValidFormat = file.type === 'application/pdf';
      if (!isValidFormat) {
        throw new Error('Please upload a valid pdf file.');
      }

      return this.upload(file, null, filePath);
    }

    throw new Error('No file uploaded!');
  }

  uploadImage(file, filePath) {
    if (file) {
      const isValidFormat = file.type === 'image/png' || file.type === 'image/jpeg';
      if (!isValidFormat) {
        return of({ error: true, message: 'Only .png or .jpg files are allowed.' });
      }

      if (file.size > (2 * 1024 * 1024)) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };

        return from(imageCompression(file, options)).pipe(
          switchMap((compressedFile) => {
            return this.upload(event, compressedFile, filePath)
          }),
          catchError(e => {
            return of(e);
          })
        );
      } else {
        return this.upload(file, null, filePath);
      }
    }

    return of({ error: true, message: "No file uploaded!" });
  }

  upload(event, compressedFile?, path?: string) {
    const filePath = path;
    return this.uploadToStorage(event, compressedFile, filePath);
  }

  uploadToStorage(event, compressedFile?, path?: string) {
    const file = !compressedFile ? event : compressedFile;
    const filePath = path;
    const task = this.storage.upload(filePath, file);
    return task.snapshotChanges();
  }

}
