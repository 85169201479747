import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MarkdownEditorComponent } from '../../markdown-editor/markdown-editor.component';


@Component({
  selector: 'app-sh-textarea-box',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MarkdownEditorComponent
  ],
  templateUrl: './sh-textarea-box.component.html',
  styleUrls: ['./sh-textarea-box.component.scss']
})
export class ShTextareaBoxComponent {

  @Input() element;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: any = new FormControl();
  @Input() rows: number = 4;
  selectionStartIndex: any;
  selectionEndIndex: any;
  selectedContent: string;
  markDownType = {
    bold: 'BOLD',
    italic: 'ITALIC',
    link: 'LINK',
    strike: 'STRIKE',
    block: 'BLOCK'
  };
  tabSelection: string = 'write';


  // select(event) {
  //   this.selectionStartIndex = event.target.selectionStart;
  //   this.selectionEndIndex = event.target.selectionEnd;
  //   this.selectedContent = window.getSelection().toString() || "";
  // }

  // formatText(type: string, event) {
  //   if (this.selectedContent !== null && this.selectedContent !== undefined) {
  //     const content = this.control.value;
  //     const isSelectedContentFormatted = this.isFormatted(type, content);
  //     let formattedContent = '';
  //     if (!isSelectedContentFormatted?.isFormatted) {
  //       const prefixSelectedIndexContent = content.slice(0, this.selectionStartIndex);
  //       const suffixSelectedIndexContent = content.slice(this.selectionEndIndex + 1, content?.length);
  //       formattedContent = this.getFormattedText(type, prefixSelectedIndexContent, suffixSelectedIndexContent);
  //     } else {
  //       formattedContent = isSelectedContentFormatted?.content || '';
  //     }
  //     this.control.setValue(formattedContent);
  //     this.selectedContent = null;
  //   }
  // }

  // getFormattedText(type: string, prefixSelectedIndexContent, suffixSelectedIndexContent) {
  //   switch (type) {
  //     case this.markDownType.bold:
  //       return prefixSelectedIndexContent + '**' + this.selectedContent + '** ' + suffixSelectedIndexContent;

  //     case this.markDownType.italic:
  //       return prefixSelectedIndexContent + '_' + this.selectedContent + '_ ' + suffixSelectedIndexContent;

  //     case this.markDownType.block:
  //       return prefixSelectedIndexContent + '```' + this.selectedContent + '``` ' + suffixSelectedIndexContent;

  //     case this.markDownType.link:
  //       // [link](https://example.com)
  //       return `${prefixSelectedIndexContent + '[' + this.selectedContent + ']' + '(' + this.selectedContent + ') ' + suffixSelectedIndexContent}`;

  //     case this.markDownType.strike:
  //       return prefixSelectedIndexContent + '~~' + this.selectedContent + '~~ ' + suffixSelectedIndexContent;

  //     default:
  //       return prefixSelectedIndexContent + '**' + this.selectedContent + '** ' + suffixSelectedIndexContent;
  //   }
  // }

  // isFormatted(type, text) {

  //   if (!this.selectedContent?.includes('**') && !this.selectedContent?.includes('```') && !this.selectedContent?.includes('~~') && !this.selectedContent?.includes('_') && !this.selectedContent?.includes('](')) {
  //     return { isFormatted: false, content: text };
  //   }

  //   let content = '';
  //   switch (type) {
  //     case this.markDownType.bold:
  //       content = text.replaceAll('**', '');
  //       break;

  //     case this.markDownType.italic:
  //       content = text.replaceAll('_', '');
  //       break;

  //     case this.markDownType.block:
  //       content = text.replaceAll('```', '');
  //       break;

  //     case this.markDownType.link:
  //       // [link](https://example.com)
  //       content = text.substring(
  //         text.indexOf("[") + 1,
  //         text.lastIndexOf("]")
  //       );
  //       break;

  //     case this.markDownType.strike:
  //       content = text.replaceAll('~~', '');
  //       break;
  //   }
  //   return { isFormatted: true, content };
  // }


  // tabClick(selectedTab: string): void {
  //   this.tabSelection = selectedTab;
  // }
}
