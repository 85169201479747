import { Component, Input, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { COLLECTION_NAMES } from 'src/app/constants/constants';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { catchError, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { DateAgoPipe } from 'src/app/pipes/dateAgo.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShAddMyItemsModelComponent } from '../sh-profile-datasets/sh-add-datasets-model/sh-add-my-items-model.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { myProfileItems } from '../../../util';
import { ShEmptyStateComponent } from '../sh-profile-datasets/sh-empty-state/sh-empty-state.component';
import { ShProfileDeleteModelComponent } from '../../../sh-profile-delete-model/sh-profile-delete-model.component';
import { MarkdownComponent } from 'src/app/components/markdown/markdown.component';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';
import { ShPostListComponent } from 'src/app/components/sh-post-list/sh-post-list.component';
import { RouterModule } from '@angular/router';
import { User } from 'src/app/models/userInfo.model';
import { AuthenticationService } from 'src/app/services/auth.service';
import { LoginPageComponent } from 'src/app/containers/login/login-page/login-page.component';

@Component({
  selector: 'app-sh-profile-posts',
  standalone: true,
  imports: [
  CommonModule, MatMenuModule,
    MatIconModule, MatButtonModule, RouterModule,
    DateAgoPipe, ShEmptyStateComponent, ShSpinnerLoaderComponent,
    MarkdownComponent, ShPostListComponent],
  templateUrl: './sh-profile-posts.component.html',
  styleUrls: ['./sh-profile-posts.component.scss']
})
export class ShProfilePostsComponent {
  profileData: any;
  fetchingData: any;
  onlyView: any;
  loadAllPosts: boolean;

  @Input() isSavedItems?: boolean;
  loading: boolean;
  entityId: string;
  @Input() set profile(data) {
    this.profileData = data;
    if (data?.id && !this.fetchingData) {
      this.fetchingData = true;
      this.loadData();
    }
  }
  @Input() set viewOnly(value) {
    this.onlyView = value;
  }
  @Input() set allPosts(value) {
    this.loadAllPosts = value;
  }
  @Input() postData?: boolean;
  @Input() queryClause?: any;

  destroy$ = new Subject();
  thumbClicked = false; // Track the thumb click state
  post$: any;
  post: any;
  label: string;

  user$: Observable<User> = this.authService.getUser();

  constructor(
    private authService: AuthenticationService, 
    private fbService: FirebaseCollectionService,
    private breakpointObserver: BreakpointObserver,
    public dialogModel: MatDialog,
    private _bottomSheet: MatBottomSheet,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShAddMyItemsModelComponent>,
    @Optional() public dialogRef: MatDialogRef<ShAddMyItemsModelComponent>,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.entityId = user?.entityId; 
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }



  updateLike(post): void {
    if (!this.entityId) {
      // Use BreakpointObserver to check screen size
      this.breakpointObserver.observe([
        '(max-width: 767px)' // Custom breakpoint for below 767px
      ]).pipe(take(1)).subscribe(result => {
        if (result.breakpoints['(max-width: 767px)']) {
          // If the screen size matches mobile or tablet, open a bottom sheet
          const bottomSheetRef = this._bottomSheet.open(LoginPageComponent, {
            data: {
              value: true,
            }

          });
          bottomSheetRef.afterDismissed().subscribe(result => { });
        } else {
          // Otherwise, open a dialog for larger screens
          const dialogRef = this.dialogModel.open(LoginPageComponent, {
            width: '450px',
            // height: '500px',
            panelClass: 'profile-model',
            data: {
              value: true,
            }
          });
        }
      });
      this.snackBar.open("You are not logged in please login and try again", 'OK');

    } else {
      let { likedBy, likes } = post;
      if (!likedBy?.includes(this.profileData?.id)) {
        likedBy.push(this.profileData?.id);
        likes = likes + 1;
      } else {
        const index = likedBy.indexOf(this.profileData?.id);
        if (index > -1) {
          likedBy.splice(index, 1);
        }
        likes = (likes - 1) || 0;
      }

      const payload = { likes, likedBy };
      this.updatePost(payload, post);
    }
  }

  // updateLike(post): void {
  //   let { likedBy, likes } = post;
  //   if (!likedBy?.includes(this.profileData?.id)) {
  //     likedBy.push(this.profileData.id);
  //     likes = likes + 1;
  //   } else {
  //     const index = likedBy.indexOf(this.profileData.id);
  //     if (index > -1) {
  //       likedBy.splice(index, 1);
  //     }
  //     likes = (likes - 1) || 0;
  //   }

  //   const payload = { likes, likedBy };
  //   this.updatePost(payload, post);
  // }

  loadData() {
    const authorId = this.profileData?.id;
    const collectionName = COLLECTION_NAMES.posts;
    this.fetchData(authorId, collectionName);
  }

  fetchData(authorId, collectionName) {
    this.loading = true;
    const filters: any = this.queryClause ? [this.queryClause] : !this.isSavedItems ? [{
      field: 'authorId',
      comparator: '==',
      value: authorId,
    }] : [{
      field: 'savedBy',
      comparator: 'array-contains',
      value: authorId,
    }];

    this.post$ = this.fbService
      .queryCollectionSnapshot(
        collectionName,
        filters || [],
        [{ field: 'date', condition: 'desc' }],
        !this.loadAllPosts ? 3 : 10,
        null,
        null,
        true
      ).pipe(
        takeUntil(this.destroy$),
        switchMap(res => {
          this.loading = false;
          return of(res);
        }),
        catchError(e => {
          this.loading = false
          return of(e)
        })
      );
  }





  updatePost(payload, post) {
    let obs$ = null;
    const collection = COLLECTION_NAMES.posts;

    obs$ = this.fbService.updateDocument(collection, post.id, payload);

    obs$.pipe(
      map(res => {
        // Liked
      }),
      catchError(e => {
        return of(e)
      })
    ).subscribe();
  }

  edit(post?): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShAddMyItemsModelComponent, {
          data: {
            entity: myProfileItems.posts,
            profile: this.profileData,
            data: post
          }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialogModel.open(ShAddMyItemsModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: {
            entity: myProfileItems.posts,
            profile: this.profileData,
            data: post
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {

          }
        });
      }
    });
  }

  confirmDelete(item) {
    const modalRef = this.dialogModel.open(ShProfileDeleteModelComponent, {
      width: '350px',
      //  height: '300px',
      panelClass: 'profile-model',
      autoFocus: false,
      data: {
        entity: myProfileItems.posts
      }
    });

    modalRef.afterClosed().pipe(
      take(1),
      map(confirmed => {
        if (confirmed) {
          this.delete(item);
        }
      })
    ).subscribe();

  }

  delete(post) {
    const collection = COLLECTION_NAMES.posts;
    this.fbService.deleteDocument(collection, post?.id).pipe(
      take(1),
      map(res => {
        this.snackBar.open("Post deleted successfully!", "OK");
      })
    ).subscribe();
  }
}
