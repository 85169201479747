<ng-container *ngIf="data.length === 0 && !loading">
  <app-sh-empty-state [btnText]="Image ? 'ADD A DATASET' : 'ADD A PAPER'" (add)="edit()"></app-sh-empty-state>
</ng-container>

<div class="flex flex-col rounded-xl">

  <div class="flex align-center justify-center">
    <app-sh-spinner-loader *ngIf="loading" [colorCode]="'#004481'" [roundWidth]="30"
      [thinWidth]="2"></app-sh-spinner-loader>
  </div>

  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
    <!-- Loop through the items and render them in the grid -->
    <!-- [routerLink]="Image ? ['/datasets', item.id] : ['/research-papers', item.id]" -->

    <a [routerLink]="Image ? ['/datasets', item.id] : ['/research-papers', item.id]" *ngFor="let item of data"
      class="bg-white p-4 rounded-xl shadow-sm flex gap-2">

      <!-- Image Container -->
      <div *ngIf="Image && subThemes?.[item?.subThemeIds?.[0]]?.logo"
        class="h-24 w-24 bg-white flex items-center justify-center rounded-xl border border-[#E1E4E8]">
        <img class="rounded-lg w-full h-full object-cover" src="{{subThemes?.[item?.subThemeIds?.[0]]?.logo}}" alt="">
      </div>

      <!-- Content Container -->
      <div class="flex flex-col w-3/4" [ngClass]="{'!w-full': !Image || !subThemes?.[item?.subThemeIds?.[0]]?.logo}">
        <div class="flex justify-between items-center w-full">

          <!-- Item Source/Journal and Format/Year -->
          <div class="text-xs text-darkBlack flex gap-x-2 align-center normal-case">
            <div>{{ item?.source || item?.journal }}</div>
            <div>&nbsp;•&nbsp;</div>
            <div>{{ item?.format || item?.year }}</div>
          </div>

          <!-- Menu Button with stopPropagation -->
          <button [matMenuTriggerFor]="menu" (click)="onClickMenu($event)" *ngIf="item?.authorId === profile?.id">
            <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu-dropdown">
            <button mat-menu-item (click)="view(item)" *ngIf="moduleEntity == myProfileItems.dataset">Open</button>
            <button mat-menu-item (click)="edit(item)">Edit</button>
            <button mat-menu-item (click)="confirmDelete(item)">Delete</button>
          </mat-menu>
        </div>

        <!-- Item Name -->
        <span class="text-sm text-darkBlack font-bold py-2 normal-case">{{ item?.name }}</span>

        <!-- Subtheme Chips -->
        <div class="flex flex-wrap mt-1">
          <div class="text-xs text-primary px-2 rounded-full flex justify-center items-center"
            *ngFor="let id of item.subThemeIds">
            <mat-chip-set>
              <ng-container *ngIf="subThemes?.[id]?.name">
                <app-sh-theme-item [classesData]="true" [themeData]="subThemes?.[id]"
                  [imageShow]="true"></app-sh-theme-item>
              </ng-container>
            </mat-chip-set>
          </div>
        </div>
      </div>

    </a>
  </div>

  <div class="flex align-center justify-center">
    <app-sh-load-more (loadMore)="loadMoreData()" *ngIf="showLoadMore"></app-sh-load-more>
  </div>
</div>