import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormControl, FormsModule } from '@angular/forms';
import { debounce, debounceTime, distinctUntilChanged, map, Subject, switchMap, tap } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { dynamicElementTypes } from 'src/app/containers/onboarding/utils';

@Component({
  selector: 'app-ng-custom-select',
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule, MatCheckboxModule, MatTooltipModule],
  templateUrl: './sh-ng-select.component.html',
  styleUrls: ['./sh-ng-select.component.scss']
})
export class ShCustomSelectComponent {

  input$ = new Subject<string>();

  dynamicElementTypes = dynamicElementTypes;
  values: any;

  @Input() set data(values) {
    // sort if object has name
    const obj = values?.length ? values[0] : {};
    const isNameOrId = obj?.journalName || obj?.name || obj?.id;
    if (isNameOrId && (typeof isNameOrId === 'string' || isNameOrId instanceof String)) {
      try {
        this.values = values?.sort((value1, value2) => {
          const val1 = (value1?.journalName || value1?.name || value1?.id);
          const val2 = (value2.journalName || value2?.name || value2?.id);
          return val1?.localeCompare(val2)
        });
      } catch (error) {
        this.values = values;
      }
    } else {
      this.values = values;
    }
  }
  @Input() LabelText;
  @Input() PlaceholderText;
  @Input() Error: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() control: any = new FormControl();
  @Input() element: any;
  @Input() model: any;
  @Input() canAddTag: boolean = false;
  @Input() bindValue?: string;
  @Input() bindLabel?: string;
  @Input() infoText: any;
  @Input() info: boolean;

  @Output() search = new EventEmitter();


  selectedItem: number;
  loading: boolean;
  stopDropDownEvent: boolean;

  ngOnInit() {
    this.loadData();
  }

  onChange(item: any) {
    this.control.setValue(item);
    this.control.markAsDirty();
  }
  addNewItems(term: string) {
    if (this.bindValue !== 'name') {
      if (this.bindLabel === 'firstName') {
        return ({
          id: 'CUSTOM',
          firstName: term
        })
      }
      return ({
        id: term,
        name: term
      })
    } else {
      return term;
    }
  };

  private loadData() {
    this.input$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.loading = true)),
      map((term) => {
        if (this.stopDropDownEvent) {
          this.stopDropDownEvent = false;
          return false;
        }
        this.search.emit({
          term: term,
          element: this.element
        });
        return 1;
      })
    ).subscribe();
  }

  onClose(ev) {
    this.stopDropDownEvent = true;
  }

  onClear() {
    this.search.emit({
      term: "",
      element: this.element
    });
  }
}
