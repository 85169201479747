import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataPassService {
  private dataSource = new BehaviorSubject<string>(''); // default value
  currentData = this.dataSource.asObservable();
  changeData = new BehaviorSubject<string>(null);
}
