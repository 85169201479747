<div [class.dark-theme]="true" class="flex flex-col overflow-hidden">
  <div class="flex w-full justify-between p-6">
    <span class="text-lg md:text-2xl text-darkBlack font-bold">{{ value }}</span>

    <mat-icon (click)="cancelClick('model')"
      class="text-[#959DA5] text-lg cursor-pointer !hidden md:!block">close</mat-icon>
    <mat-icon (click)="cancelClick('')" class="text-[#959DA5] text-lg cursor-pointer md:!hidden !block">close</mat-icon>
  </div>
  <div class="flex w-full gap-x-4 px-6">
    <div class="w-full relative">
      <!-- <img class="absolute top-[12px] left-4" src="assets/images/search.svg" alt="search" /> -->
      <input type="search" placeholder="Search Connects" (search)="onSearch($event)" (ngModelChange)="onSearch($event)"
        [(ngModel)]="searchTerm"
        class="focus:outline-none hover:border-primary border border-lightGrey h-11 rounded-lg py-3 pl-4 pr-3 w-full" />
    </div>
    <!-- <button mat-raised-button class="!h-11 !rounded-lg sm:!w-[120px]" color="primary">
      <img class="sm:hidden block" src="../../../../assets/images/search.svg" alt="">
      <strong class="sm:block hidden">SEARCH</strong>
    </button> -->
  </div>
  <div class="flex flex-col gap-y-2 mt-4 h-[calc(100vh_-_21rem)] overflow-y-auto px-6 pb-6" formGroup="form">

    <div class="flex align-center justify-center" *ngIf="loading">
      <app-sh-spinner-loader [colorCode]="'#D6ECFF'" [roundWidth]="20" [thinWidth]="2"></app-sh-spinner-loader>
    </div>
    <div class="flex align-center justify-center" *ngIf="!loading && !researchers?.length">
      No result found!
    </div>

    <ng-container *ngIf="!loading">
      <app-sh-members-list [isModel]='true' [isAdd]="isAdd"
        [borderClass]="'border gap-0 p-2 justify-between rounded-md'"
        [boxClass]="'grid grid-cols-1 sm:grid-cols-2 gap-2'" [teams]="researchers"
        (updateResearcher)="updateResearcher($event)"></app-sh-members-list>

      <div class="flex align-center justify-center mt-4" *ngIf="loadMore">
        <app-sh-load-more (loadMore)="getResearchers()"></app-sh-load-more>
      </div>

    </ng-container>

  </div>

  <!-- <div class="flex justify-end mt-4 px-6 pb-6">
      <button mat-raised-button color="primary" class="w-24" [disabled]="uploadingData">
        <app-sh-spinner-loader *ngIf="uploadingData" [colorCode]="'#fff'" [roundWidth]=20
          [thinWidth]=2></app-sh-spinner-loader>
        {{uploadingData ? '' : value ? 'UPDATE' : 'CREATE'}}
      </button>
    </div> -->

</div>