import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sh-common-upload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sh-common-upload.component.html',
  styleUrls: ['./sh-common-upload.component.scss']
})
export class ShCommonUploadComponent {
  selectedFile: File | null = null;
  selectedFileURL: string | null = null;
  @Input() label?: any;
  @Input() buttonLabel: any;
  @Input() set data(imageUrl) {
    this.selectedFileURL = imageUrl;
  }

  @Output() fileSelected = new EventEmitter<File>();

  fileUpload() {
    document.getElementById('postImageUploadInput').click();
  }

  onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      this.selectedFileURL = URL.createObjectURL(this.selectedFile);
      this.fileSelected.emit(this.selectedFile);  // Emit the selected file
    }
  }

  removeImage() {
    this.selectedFileURL = null;
    this.fileSelected.emit(null);
  }
}
