import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { DataPassService } from 'src/app/services/datapass.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sh-empty-state',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './sh-empty-state.component.html',
  styleUrls: ['./sh-empty-state.component.scss']
})
export class ShEmptyStateComponent implements OnInit {
  @Input() btnText: string;
  @Input() labelShow: string;
  @Input() viewOnly?: boolean;

  @Output() add = new EventEmitter()
  btnHide: boolean;
  destroy$ = new Subject();
  isSavedItems: boolean;
  routes: any;

  constructor(private dataService: DataPassService, private router: Router) { }

  ngOnInit() {
    this.dataService.changeData.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.isSavedItems = (data === 'Saved Items');
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete();
  }

  addItem() {
    this.add.emit(true);
  }
}
