import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { COLLECTION_NAMES, COLORS, dateFormat } from 'src/app/constants/constants';
import { LoginPageComponent } from 'src/app/containers/login/login-page/login-page.component';
import { PROFILE } from 'src/app/containers/profile/sh-tab-profile/sh-tab-profile.component';
import { User } from 'src/app/models/userInfo.model';
import { AuthenticationService } from 'src/app/services/auth.service';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { format } from 'src/app/utils/date-service.utils';
import { getColorByName, getLighterColor } from 'src/app/utils/utils';
import { isResearcher } from 'src/app/utils/utils';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  public dataSetCount = '3.5';
  public paperCount = '150';
  public conferenceCount = '1.2';
  dateFormat = dateFormat;
  destroy$ = new Subject();

  public dataSets = [];
  public papers = [];
  public conferences = [];
  public subThemesObj = {};
  public researchersObj = {};

  public isDataSetLoaded = false;
  public isPapersLoaded = false;
  public isConferencesLoaded = false;

  eventsHomepageLink = 'events';
  dsThemesArray: any;
  dsSubThemes: any;
  themesArray: any;
  subThemes: any;
  grants: any[];
  jobs$: any;
  posts$: any;
  events$: any;
  events: any[];

  user$: Observable<User> = this.authService.getUser();
  userAuth$ = this.authService.getAuthUser();
  userAuthUser$: Observable<User> = this.authService.getUser();
  profileId: any;

  constructor(
    private fbService: FirebaseCollectionService,
    private afa: AngularFireAuth,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver, @Inject(PROFILE) private profileInjected: any,
    public dialog: MatDialog, private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.getDataSets();

    this.getPublications();

    this.getConferences();

    this.getGrants();

    this.getJobs();

    this.getEvents();

    this.getPosts();

    this.subscribeUserData();

    // this.getAuth();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // getAuth() {
  //   this.afa.authState.subscribe(res => {
  //   });
  // }

  getEvents() {
    this.events$ = this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.events,
        [{
          field: 'startDate',
          comparator: '>=',
          value: format(new Date()),
        }],
        [{ field: 'startDate', condition: 'asc' }],
        3
      ).pipe(
        take(1),
        map(events => {
          this.events = [...events];
          this.events.forEach(event => {
            event.day = this.getDays(event.startDate, event.endDate);
            event.month = this.getMonths(event.startDate, event.endDate);
            event.year = this.getYear(event.startDate);
          });
          this.fetchThemes();
        })
      ).subscribe();
  }

  getDataSets() {
    this.fbService
      .queryCollectionSnapshot(COLLECTION_NAMES.datasets, null, null, 3)
      .pipe(
        take(1),
        map((collectionData: any) => {
          this.dataSets = [...collectionData];
          this.isDataSetLoaded = true;
          this.fetchDataSetThemes();
        })
      ).subscribe();
  }

  getGrants() {
    this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.grants,
        [{
          field: 'deadline',
          comparator: '>=',
          value: format(new Date()),
        }],
        [{ field: 'deadline', condition: 'asc' }],
        3)
      .pipe(
        take(1),
        map((collectionData) => {

          this.grants = [...collectionData];
        })
      ).subscribe();
  }

  getPublications() {
    this.fbService
      .queryCollection(COLLECTION_NAMES.publications, null, null, 3)
      .pipe(take(1))
      .subscribe((collectionData) => {
        const papers = [];
        for (const data of collectionData) {
          papers.push(data.payload.doc.data());
        }
        this.papers = papers;
        this.isPapersLoaded = true;
        // this.getSubthemes();
        // this.getResearchers();
      });
  }

  getJobs() {
    this.jobs$ = this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.jobs,
        [],
        [{ field: 'index', condition: 'asc' }],
        3
      );
  }

  getPosts() {
    this.posts$ = this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.posts,
        null,
        [{
          field: 'date', condition: 'desc'
        }],
      3)
      .pipe(
        take(1)
      );
  }



  getConferences() {
    this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.conferences,
        [{
          field: 'startDate',
          comparator: '>=',
          value: format(new Date()),
        }],
        [{ field: 'startDate', condition: 'asc' }],
        3)
      .pipe(
        take(1),
        map((collectionData) => {
          this.conferences = [...collectionData];
          this.conferences.forEach(conference => {
            conference.day = this.getDays(conference.startDate, conference.endDate);
            conference.month = this.getMonths(conference.startDate, conference.endDate);
            conference.year = this.getYear(conference.startDate);
          });
          this.isConferencesLoaded = true;
          this.fetchThemes();
        })
      )
      .subscribe();
  }

  fetchThemes() {
    this.fbService
      .queryCollectionSnapshot(COLLECTION_NAMES.themes)
      .pipe(
        take(1),
        map((themes) => {
          this.themesArray = themes;
          themes.forEach((theme, index) => {
            theme['color'] = theme.color || getColorByName(theme.name);
            theme['secondaryColor'] = getLighterColor(theme.color, 0.3);

            (theme?.subThemes || []).forEach(subTheme => {
              subTheme.secondaryColor = theme.secondaryColor;
              this.subThemes = Object.assign(this.subThemes || {}, { [subTheme.id]: subTheme }); // [subTheme.id] = subTheme;
            });
          });
        }),
        catchError(err => {
          return of(err);
        })
      )
      .subscribe();
  }

  fetchDataSetThemes() {
    this.fbService
      .queryCollectionSnapshot(COLLECTION_NAMES.dataSetThemes)
      .pipe(
        take(1),
        map((themes) => {
          this.dsThemesArray = themes;
          themes.forEach((theme, index) => {
            theme['color'] = theme.color || getColorByName(theme.name);
            theme['secondaryColor'] = getLighterColor(theme.color, 0.3);

            (theme?.subThemes || []).forEach(subTheme => {
              subTheme.secondaryColor = theme.secondaryColor;
              this.dsSubThemes = Object.assign(this.dsSubThemes || {}, { [subTheme.id]: subTheme });
            });
          });
        }),
        catchError(err => {
          return of(err);
        })
      )
      .subscribe();
  }

  getSubthemes() {
    if (
      this.isDataSetLoaded &&
      this.isPapersLoaded &&
      this.isConferencesLoaded
    ) {
      const subThemeIds = [];
      for (const data of this.dataSets) {
        if (data.subThemeIds?.length) {
          for (const id of data.subThemeIds) {
            if (!subThemeIds.includes(id)) {
              subThemeIds.push(id);
            }
          }
        }
      }

      for (const data of this.papers) {
        if (data.subThemeIds?.length) {
          for (const id of data.subThemeIds) {
            if (!subThemeIds.includes(id)) {
              subThemeIds.push(id);
            }
          }
        }
      }

      for (const data of this.conferences) {
        if (data.subThemeIds?.length) {
          for (const id of data.subThemeIds) {
            if (!subThemeIds.includes(id)) {
              subThemeIds.push(id);
            }
          }
        }
      }

      if (subThemeIds.length) {
        this.fbService
          .queryCollection(COLLECTION_NAMES.subThemes, [
            { field: 'id', comparator: 'in', value: subThemeIds.slice(0, 30) },
          ])
          .pipe(take(1))
          .subscribe((collectionData) => {
            const subThemesObj = {};
            for (const docData of collectionData) {
              const data = docData.payload.doc.data() as any;
              subThemesObj[data.id] = data;
            }
            this.subThemesObj = subThemesObj;
          });
      }
    }
  }

  getResearchers() {
    const researcherIds = [];
    for (const data of this.papers) {
      if (data.authorId && !researcherIds.includes(data.authorId)) {
        researcherIds.push(data.authorId);
      }
    }

    if (researcherIds.length) {
      this.fbService
        .queryCollection(COLLECTION_NAMES.researchers, [
          { field: 'id', comparator: 'in', value: researcherIds },
        ])
        .pipe(take(1))
        .subscribe((collectionData) => {
          const researchersObj = {};
          for (const docData of collectionData) {
            const data = docData.payload.doc.data() as any;
            researchersObj[data.id] = data;
          }
          this.researchersObj = researchersObj;
        });
    }
  }

  getYear(date) {
    return new Date(date)
      .toLocaleString('default', { year: 'numeric' })
      .toString();
  }

  getDays(startDate, endDate) {
    const d1 = new Date(startDate).toLocaleString('default', {
      day: '2-digit',
    });
    const d2 = new Date(endDate).toLocaleString('default', { day: '2-digit' });
    return d1.toString();
    // not showing now.
    // if (d1 == d2) {
    //   return d1.toString();
    // } else {
    //  return d1.toString() + " to " + d2.toString();
    // }
  }

  getMonths(startDate, endDate) {
    const d1 = new Date(startDate).toLocaleString('default', {
      month: 'short',
    });
    const d2 = new Date(endDate).toLocaleString('default', { month: 'short' });
    return d1.toString();
  }

  subscribeUserData() {
    this.userAuthUser$
      .pipe(
        take(1), // Take only the first emitted value
        map((user: any) => {
          this.profileId = user?.entityId || null;

          return user; // Return the user object if further processing is needed
        })
      )
      .subscribe();

  }




}
