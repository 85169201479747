export function getCharCombo(arr: string[], existingKeywords?) {
    const keywords = [];
    for (let rowIndex = 0; rowIndex < arr.length; rowIndex++) {
        let keyword = "";
        for (let colIndex = 0; colIndex <= rowIndex; colIndex++) {
            keyword = keyword.toString() + arr[colIndex].toString().toLowerCase();
        }

        const kWord = keyword.toLowerCase();
        if (existingKeywords?.length && !existingKeywords?.includes(kWord)) {
            keywords.push(kWord.toLowerCase());
        } else {
            keywords.push(kWord.toLowerCase());
        }
    }

    return keywords;
}

export function getWordsCombo(arr: any[]) {
    try {
        const keywords = [];
        arr.forEach((word: any) => {
            const words = word ? word?.name.split(" ") : [word];
            words.forEach(wrd => {
                if (wrd?.length && wrd) {
                    keywords.push(wrd.toLowerCase());
                }
            });
        });
        return keywords;
    } catch (error) {
        return [];
    }
}

export function getTermsAsKeywords(arr: string[], existingKeywords?) {
    try {
        const keywords = [];
        arr.forEach((word: any) => {
            // if (word?.name) {
            const name = (word?.name || word)?.toLowerCase();
            if (name) {
                keywords.push(name);
            }
        });
        return keywords;
    } catch (error) {
        return [];
    }
}

export function getSubThemesKeywords(arr: string[], existingKeywordskeywords?) {
    try {
        let keywords = [];
        arr.forEach((theme: any) => {
            // if (theme?.name) {
            const name = (theme?.name)?.toLowerCase();
            if (name) {
                if (existingKeywordskeywords?.length) {
                    if (!existingKeywordskeywords?.includes(name)) {
                        keywords.push(name);
                        const charCombo = getWordsCombo([theme]);
                        if (charCombo?.length) {
                            keywords = [...keywords, ...charCombo]
                        }
                    }
                } else {
                    keywords.push(name);
                    const charCombo = getWordsCombo([theme]);
                    if (charCombo?.length) {
                        keywords = [...keywords, ...charCombo]
                    }
                }
            }
        });
        return keywords;
    } catch (error) {
        return [];
    }
}

export function getSubThemes(subThemes) {
    const subThemeIds = [];
    if (subThemes?.length) {
        subThemes.forEach(subTheme => {
            if (subTheme?.id) {
                if (!subThemeIds.includes(subTheme.id)) {
                    subThemeIds.push(subTheme.id);
                }
            } else {
                subThemeIds.push(subTheme);
            }
        });
    }
    return subThemeIds;
}

export function getThemeIds(subThemes, exThemeIds?: string[]) {
    const themeIds = [];
    if (subThemes?.length) {
        subThemes.forEach(subTheme => {
            if (!themeIds.includes(subTheme.themeId) && subTheme.themeId) {
                themeIds.push(subTheme.themeId);
            }
        });
    }
    return [...new Set([...themeIds, ...(exThemeIds || [])])];
}

export function getMonthYearTerms(startDate: string) {
    const date = new Date(startDate);
    const month = date.toLocaleString('default', { month: 'short' });

    const year = new Date(startDate).getFullYear();

    return [month.toLowerCase(), year];
}
