<div class="flex flex-col">


  <ng-container *ngIf="!(grants$ | async)?.['length'] && !loading">
    <app-sh-empty-state [btnText]="emptyStateAddButtonLabel" (add)="edit()"></app-sh-empty-state>
  </ng-container>

  <div class="flex align-center justify-center">
    <app-sh-spinner-loader *ngIf="loading" [colorCode]="'#004481'" [roundWidth]="30"
      [thinWidth]="2"></app-sh-spinner-loader>
  </div>


  <ng-container>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <a [routerLink]="['/grants', grant?.id]"
        class="flex rounded-lg border border-lightGrey bg-white p-4 rounded-xl shadow-sm mb-3"
        *ngFor="let grant of (grants$ | async)">
        <div class="flex flex-col gap-y-1 w-full">
          <div class="flex justify-between items-center w-full">
            <div class="w-min rounded-full px-3 text-xs bg-[#BEF5CB] text-[#0A6E20]"
              [ngClass]="{'pb-[2px]': !grant?.isClosed}"
              [ngStyle]="{'width': grant?.isClosed ? '' : '', 'background-color': grant?.isClosed ? 'rgb(255 187 165)':'' }">
              {{
              grant?.isClosed ?
              'Closed' : 'Open' }}</div>

            <button [matMenuTriggerFor]="menu" (click)="onClickMenu($event)" *ngIf="grant?.authorId === profile?.id">
              <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="menu-dropdown">
              <button mat-menu-item (click)="edit(grant)">Edit</button>
              <button mat-menu-item (click)="confirmDelete(grant)">Delete</button>
            </mat-menu>
          </div>
          <span class="text-darkBlack text-sm font-bold normal-case">{{grant?.name}}</span>
          <div class="flex align-center pt-1 text-darkBlack">
            <img class="mr-1" src="/assets/images/grants/dollar-sign.svg" />&nbsp; {{grant?.currency}} {{grant?.amount}}
          </div>
          <div class="flex align-center pt-1">
            <img class="mr-1" src="/assets/images/grants/calendar-line.svg" />&nbsp;{{grant?.deadline |
            date:dateFormat}}
          </div>
        </div>

      </a>
    </div>
  </ng-container>

</div>