<div class="flex flex-col">
    <span class="text-sm mb-1 flex gap-x-1">{{ LabelText }} <span *ngIf="element?.required" style="color: red;">*</span>
        <img *ngIf="info" class="cursor-pointer" src="../../../../assets/images/info.svg" matTooltip="{{ infoText }}"
            alt="">
    </span>

    <div class="auto-complete relative">
        <ng-select
            class="border w-full min-h-12 rounded-md focus:outline-none hover:border-primary focus:border-primary placeholder:font-thin placeholder:text-[#959DA5] px-4"
            [items]="values" [closeOnSelect]="!isMultiple" bindLabel="{{bindLabel || 'name'}}"
            [bindValue]="bindValue || 'id'" placeholder={{PlaceholderText}} [multiple]="isMultiple"
            (change)="onChange($event)" [typeahead]="input$" [(ngModel)]="model" (close)="onClose($event)"
            (clear)="onClear()" [addTag]="canAddTag ? addNewItems : false" [searchable]="element?.showSearch"
            [addTagText]=" element?.loading ? 'Loading...' : 'Add'"
            [closeOnSelect]="element?.type === dynamicElementTypes.multiSelect ? (element?.closeDropdownOnSelect ? true : false) : true">

            <ng-container *ngIf="isMultiple">

                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected">{{ item.gender |
                        uppercase }}</mat-checkbox>

                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected">
                        {{
                        (bindLabel === 'firstName') ? ((item?.firstName + ' ' + item?.lastName) + ' ( '+
                        item?.university) +' )'
                        :
                        (item?.journalName || item?.name)
                        }}</mat-checkbox>

                </ng-template>
            </ng-container>

        </ng-select>
    </div>
</div>