<section class="home" [class.dark-theme]="true">
  <section class="heading">
    <div class="heading__image-section">
      <div class="
          app-container
          heading__image-section__apply-display
          extra-padding
        ">
        <div class="heading__image-section__section">
          <h1>
            <div class="heading__image-section__section__h1-1 text-white">
              <strong>Sustainability</strong>
            </div>
            <div class="heading__image-section__section__h1-2 text-white">
              <strong>Academic Network</strong>
            </div>
          </h1>
          <div class="heading__image-section__section__desc text-white">
            <strong>The platform that unites sustainability researchers</strong>
          </div>
          <div class="heading__image-section__section__join-link text-white">
            <a class="
                hover:!shadow-lg
                hover:!border-[#002572]
                hover:!bg-white
                hover:!text-[#002572]
                mr-4
              " routerLink="/onboarding" *ngIf="!(userAuth$ | async)"><strong>JOIN FOR FREE</strong></a>
            <a class="
                hover:!shadow-lg
                hover:!border-[#002572]
                hover:!bg-white
                hover:!text-[#002572]
              " routerLink="/about"><strong>Learn More</strong></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="explore">
    <div class="app-container extra-padding">
      <div class="explore__text text-[#24292e]">
        <strong>Explore thousands of datasets, academic papers, conferences, jobs, grants, local events, and
          institutes—all dedicated to
          <span class="explore__text__special-text">Sustainability</span></strong>
      </div>
      <div class="explore__display-elem text-[#24292e]">
        <a routerLink="/events" class="explore__elem flex items-center ">
          <div class="flex items-center gap-x-2 md:gap-x-3">
            <img class="w-6 md:w-8" src="../../../assets/images/Homepage_icons/Events.svg" alt="">
            <span> <strong>Local Events</strong></span>
          </div>

          <img src="/assets/images/arrow-right-line.svg" alt="arrow-right" />
        </a>
        <a routerLink="/datasets" class="explore__elem">

          <div class="flex items-center gap-x-2 md:gap-x-3">
            <img class="w-6 md:w-8" src="../../../assets/images/Homepage_icons/Datasets.svg" alt="">
            <span> <strong>Datasets</strong></span>
          </div><img src="/assets/images/arrow-right-line.svg" alt="arrow-right" />
        </a>
        <a routerLink="/research-papers" class="explore__elem">
          <div class="flex items-center gap-x-2 md:gap-x-3">
            <img class="w-6 md:w-8" src="../../../assets/images/Homepage_icons/Research Papers.svg" alt="">
            <span> <strong>Academic Articles</strong></span>
          </div>
          <img src="/assets/images/arrow-right-line.svg" alt="arrow-right" />
        </a>
      </div>
      <div class="explore__display-elem text-[#24292e]">
        <a routerLink="/conferences" class="explore__elem">
          <div class="flex items-center gap-x-2 md:gap-x-3">
            <img class="w-6 md:w-8" src="../../../assets/images/Homepage_icons/Conferences.svg" alt="">
            <span> <strong>Conferences</strong></span>
          </div>
          <img src="/assets/images/arrow-right-line.svg" alt="arrow-right" />
        </a>
        <a class="explore__elem" routerLink="/grants">

          <div class="flex items-center gap-x-2 md:gap-x-3">
            <img class="w-6 md:w-8" src="../../../assets/images/Homepage_icons/Grants.svg" alt="">
            <span> <strong>Grants</strong></span>
          </div>
          <img src="/assets/images/arrow-right-line.svg" alt="arrow-right" />
        </a>
        <!-- <a routerLink="/posts" class="explore__elem"><span><strong>Posts</strong></span><img
              src="/assets/images/arrow-right-line.svg" alt="arrow-right" /></a> -->
      </div>
      <div class="explore__display-elem text-[#24292e]">
        <a class="explore__elem" routerLink="/jobs">
          <div class="flex items-center gap-x-2 md:gap-x-3">
            <img class="w-6 md:w-8" src="../../../assets/images/Homepage_icons/Jobs.svg" alt="">
            <span> <strong>Jobs</strong></span>
          </div>
          <img src="/assets/images/arrow-right-line.svg" alt="arrow-right" />
        </a>

        <a routerLink="/institutes" class="explore__elem">
          <div class="flex items-center gap-x-2 md:gap-x-3">
            <img class="w-6 md:w-8" src="../../../assets/images/Homepage_icons/Institutes.svg" alt="">
            <span> <strong>Institutes</strong></span>
          </div>
          <img src="/assets/images/arrow-right-line.svg" alt="arrow-right" />
        </a>
      </div>
    </div>
  </section>

  <ng-container *ngIf="
      this.isDataSetLoaded && this.isPapersLoaded && this.isConferencesLoaded;
      else dataLoading
    ">
    <section class="app-container extra-padding infos text-[#24292e]">

      <!-- Events -->
      <div class="infos__heading">
        <div><strong>Events</strong></div>
        <a routerLink="/events"><strong>SEE ALL</strong><img src="/assets/images/arrow-right-blue.svg"
            alt="arrow-right" /></a>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <a routerLink="/events/{{ event.id }}" *ngFor="let event of events" class="
                                    flex flex-col
                                    bg-white
                                    rounded-xl
                                    hover:shadow-md
                                    gap-x-3
                                  ">
          <img class="w-full h-36 rounded-lg object-cover" [src]="event?.logo || '../../../assets/noImage.WEBP'"
            alt="no-img" />

          <div class=" normal-case
                                  overflow-hidden
                                  text-ellipsis
                                  whitespace-normal
                                  leading-tight
                                  text-base
                                  font-semibold
                                  px-2 pt-2 pb-1
                                ">
            {{ event.name }}
          </div>
          <div class="text-xs flex items-center gap-x-2 px-2">
            <span *ngIf="event.city">{{ event.city }},</span>&nbsp; <span class="capitalize">{{ event.country }}</span>
          </div>

          <div class="flex gap-x-2  p-2">
            <img src="/assets/images/grants/calendar-line.svg" />
            {{ event?.startDate | date: dateFormat}}
          </div>

          <div class="mt-2 ml-2 flex flex-wrap gap-x-2 px-2 pb-2">
            <mat-chip-set *ngFor="let id of event.subThemeIds">
              <ng-container *ngIf="subThemes?.[id]?.name">
                <app-sh-theme-item [classesData]="true" [themeData]="subThemes?.[id]"
                  [imageShow]="true"></app-sh-theme-item>
              </ng-container>
            </mat-chip-set>
          </div>
        </a>
      </div>

      <!-- Datasets -->
      <div class="infos__heading">
        <div><strong>Datasets</strong></div>
        <a routerLink="/datasets"><strong>SEE ALL</strong><img src="/assets/images/arrow-right-blue.svg"
            alt="arrow-right" /></a>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <a routerLink="/datasets/{{ item.id }}" *ngFor="let item of dataSets" class="
            flex
            border border-lightGrey
            rounded-xl
            hover:shadow-md
            p-3
            gap-x-3
            bg-white
          ">
          <div class="w-1/3 h-24 border border-lightGrey rounded-lg">
            <img class="w-full h-full rounded-lg" src="{{dsSubThemes?.[item?.subThemeIds?.[0]]?.logoOriginal}}"
              alt="no-img" />
          </div>
          <div class="flex flex-col justify-between w-2/3">
            <div class="text-xs flex items-center gap-x-2">
              {{ item?.source }}
              <div class="w-1 h-1 rounded-full bg-darkBlack"></div>
              {{ item.format }}
            </div>

            <div class="
                overflow-hidden
                text-ellipsis
                whitespace-normal
                h-8
                max-h-[2rem]
                leading-tight
                text-sm
                font-semibold
              ">
              {{ item.name }}
            </div>

            <div class="mt-2 ml-2">
              <mat-chip-set *ngFor="let id of item.subThemeIds">
                <ng-container *ngIf="dsSubThemes?.[id]?.name">
                  <app-sh-theme-item [classesData]="true" [themeData]="dsSubThemes?.[id]"
                    [imageShow]="true"></app-sh-theme-item>
                </ng-container>
              </mat-chip-set>
            </div>
          </div>
        </a>
      </div>

      <!-- Papers -->
      <div class="infos__heading">
        <div><strong>Academic Articles</strong></div>
        <a routerLink="/research-papers"><strong>SEE ALL</strong><img src="/assets/images/arrow-right-blue.svg"
            alt="arrow-right" /></a>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <a routerLink="/research-papers/{{ item.id }}" *ngFor="let item of papers"
          class="flex border border-lightGrey rounded-xl hover:shadow-md p-3 bg-white">
          <div class="flex flex-col">
            <div class="text-xs flex items-center gap-x-2">
              {{ item.journal | safeValue  }}
              <div class="w-1 h-1 rounded-full bg-darkBlack"></div>
              {{ getYear(item.publishDate) }}
            </div>

            <div class="
                overflow-hidden
                text-ellipsis
                whitespace-normal
                mt-2
                leading-tight
                text-sm
                font-semibold
              ">
              {{ item.name }}
            </div>

            <div class="flex gap-x-2" *ngIf="researchersObj[item.authorId]?.firstName">
              <img class="w-5 h-5 rounded-full" src="/assets/images/person.png" alt="" />
              {{ researchersObj[item.authorId]?.firstName }}&nbsp;{{
              researchersObj[item.authorId]?.lastName
              }}
            </div>

            <div class="mt-2 ml-2" *ngIf="dsSubThemes">
              <mat-chip-set *ngFor="let id of item.subThemeIds">
                <ng-container *ngIf="dsSubThemes?.[id]?.name">
                  <app-sh-theme-item [themeData]="dsSubThemes?.[id]" [imageShow]="true"></app-sh-theme-item>
                </ng-container>
              </mat-chip-set>
            </div>
          </div>
        </a>
      </div>

      <!-- Conferences -->
      <div class="infos__heading">
        <div><strong>Conferences</strong></div>
        <a routerLink="/conferences"><strong>SEE ALL</strong><img src="/assets/images/arrow-right-blue.svg"
            alt="arrow-right" /></a>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <a routerLink="/conferences/{{ item.id }}" *ngFor="let item of conferences" class="
            flex flex-col
            bg-white
            rounded-xl
            hover:shadow-md
            gap-x-3
          ">
          <img class="w-full h-36 rounded-lg object-cover" [src]="item?.logo || '../../../assets/noImage.WEBP'"
            alt="no-img" />

          <div class="flex-col flex gap-y-1 p-2">
            <div class="
                overflow-hidden
                text-ellipsis
                whitespace-normal
                leading-tight
                text-base
                font-semibold 
              ">
              {{ item.name }}
            </div>
            <div class="text-xs flex items-center ">
              {{ item.city }},&nbsp; <span class="capitalize">{{ item.country }}</span>
            </div>

            <div class="flex">
              <img src="/assets/images/grants/calendar-line.svg" />
              {{ item.startDate | date: dateFormat }}
            </div>

            <div class="mt-2 ml-2 flex flex-wrap gap-x-2">
              <mat-chip-set *ngFor="let id of item.subThemeIds">
                <ng-container *ngIf="dsSubThemes?.[id]?.name">
                  <app-sh-theme-item [classesData]="true" [themeData]="dsSubThemes?.[id]"
                    [imageShow]="true"></app-sh-theme-item>
                </ng-container>
              </mat-chip-set>
            </div>
          </div>


        </a>
      </div>

      <!-- Grants -->
      <div class="infos__heading" *ngIf="grants?.length">
        <div><strong>Grants, Awards & Scholarships</strong></div>
        <a routerLink="/grants"><strong>SEE ALL</strong><img src="/assets/images/arrow-right-blue.svg"
            alt="arrow-right" /></a>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <a routerLink="/grants/{{ item.id }}" *ngFor="let item of grants" class="
            flex bg-white
            border border-lightGrey
            rounded-xl
            hover:shadow-md
            p-3
            gap-x-3
          ">
          <div class="flex flex-col justify-between">
            <div class="
                w-min
                rounded-full
                px-3
                text-xs
                bg-[#BEF5CB]
                text-[#0A6E20]
              " [ngClass]="{ 'pb-[2px]': !item?.isClosed }" [ngStyle]="{
                width: item?.isClosed ? '' : '',
                'background-color': item?.isClosed ? 'rgb(255 187 165)' : ''
              }">
              {{ item.isClosed ? "Closed" : "Open" }}
            </div>

            <div class="
                overflow-hidden
                text-ellipsis
                whitespace-normal
                h-8
                max-h-[2rem]
                leading-tight
                text-sm
                font-semibold
                mt-1
              ">
              {{ item.name }}
            </div>
            <div class="flex gap-x-2 py-2">
              <img src="/assets/images/grants/dollar-sign.svg" />
              {{ item.amount }}
            </div>

            <div class="flex gap-x-2">
              <img src="/assets/images/grants/calendar-line.svg" />
              {{ item.deadline | date: dateFormat }}
            </div>
            <!-- <div class="mt-2 ml-2">
              <mat-chip-set *ngFor="let id of item.subThemeIds">
                <ng-container *ngIf="dsSubThemes?.[id]?.name">
                  <app-sh-theme-item [classesData]=true [themeData]="dsSubThemes?.[id]" [imageShow]="true"></app-sh-theme-item>
                </ng-container>
              </mat-chip-set>
            </div> -->
          </div>
        </a>
      </div>


      <!-- Jobs -->
      <div class="infos__heading">
        <div><strong>Jobs</strong></div>
        <a routerLink="/jobs"><strong>SEE ALL</strong><img src="/assets/images/arrow-right-blue.svg"
            alt="arrow-right" /></a>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <div class="card shadow-sm hover:shadow-lg bg-white" *ngFor="let item of (jobs$ | async)">
          <a routerLink="/jobs/{{ item.id }}" class="card__sec-1 ">
            <div>
              <strong class="text-base">{{ item.name }}</strong>
              <span class="text-xs text-[#6A737D]">&nbsp;•&nbsp;{{ item?.jobSector }}</span>
            </div>

            <ng-container>
              <div class="card__sec-1__uni">
                <img class="card__sec-1__uni__logo" [src]="item?.logo || './assets/job_none.svg'"
                  alt="university-logo" />
                <div>
                  <div>{{ item?.company }}</div>
                  <div class="text-xs">
                    {{ item?.unorderedAddress }}
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- 
            <div class="flex flex-wrap gap-x-2 mt-2 ml-2">
              <mat-chip-set *ngFor="let skill of item.jobSkills">
                <ng-container *ngIf="skill">
                  <app-sh-theme-item [themeData]="{name: skill}" [imageShow]="false"></app-sh-theme-item>
                </ng-container>
              </mat-chip-set>
            </div> -->

            <div class="text-[13px] text-darkBlack">
              <span *ngIf="item?.type">{{ item.type }}</span>
              <span *ngIf="item?.salary">&nbsp;•&nbsp;<span *ngIf="item?.currency">{{ item.currency }}&nbsp;</span>{{
                item.salaryVo }}</span>
              <span *ngIf="item?.applicantLevel">&nbsp;•&nbsp;{{ item.experience }}</span>
            </div>
          </a>

          <!-- <div class="card__sec-2">
            <a href="{{ item.applyLink }}" target="_blank" mat-raised-button color="primary"><strong
                class="card__sec-2__color-blue flex align-center"><strong>APPLY</strong>&nbsp;<img
                  src="/assets/images/ext-link-white.svg" alt="ext-link" /></strong></a>
          </div> -->
        </div>
      </div>




      <!-- Posts -->
      <div class="infos__heading">
        <div><strong>Posts</strong></div>
        <a routerLink="/posts"><strong>SEE ALL</strong><img src="/assets/images/arrow-right-blue.svg"
            alt="arrow-right" /></a>
      </div>

      <div class="w-full">
        <app-sh-post-list [profileId]="profileId" [posts]="posts$"></app-sh-post-list>
      </div>


    </section>
  </ng-container>
  <ng-template #dataLoading>
    <div class="loader">
      <mat-spinner class="loader__spinner"></mat-spinner>
    </div>
  </ng-template>
</section>