import { format } from "../utils/date-service.utils";
import { getCharCombo, getSubThemes, getSubThemesKeywords, getTermsAsKeywords, getWordsCombo } from "./utils";

export interface Paper {
    abstract?: string,
    authorId?: string,
    cited?: number,
    coAuthorIds?: string[],
    image?: string,
    issue?: string | null,
    journal?: string,
    journalId?: string,
    langCode?: string | null,
    link?: string,
    name?: string,
    pages?: string | null,
    publishDate?: string,
    researcherIds?: string[],
    scholarLink?: string,
    status?: string,
    subThemeIds?: string[],
    views?: number,
    volume?: number,
    year?: number,
    keywords?: string[],
    index?: number,
    authors?: any[],
    authorIds?: any[],
    updatedBy?: string,
    created?: string
}

export function paperDBO(data) {
    const dbo: Paper = {};

    const authorsInfo = getAuthors(data?.researchers);

    dbo.created = format(new Date());
    dbo.abstract = data.abstract || null;
    dbo.authorId = data?.authorId || null;
    dbo.cited = data?.cited || 0;
    dbo.coAuthorIds = data?.coAuthorIds || [];
    dbo.image = data?.image || null;
    dbo.issue = data?.issue || null;
    dbo.journal = data?.journal?.journalName || data?.journal?.name || data?.journal || null;
    dbo.journalId = data?.journal?.id || data?.journalId || null;
    dbo.langCode = data?.langCode || null;
    dbo.link = data?.link || null;
    dbo.name = data?.name || null;
    dbo.pages = data?.pages || null;
    dbo.publishDate = format(new Date()) || null;
    dbo.index = data?.index || 1;
    dbo.scholarLink = data?.scholarLink || null;
    dbo.status = data?.status || null;
    dbo.subThemeIds = data?.subThemeIds || [];
    dbo.views = data?.views || 0;
    dbo.volume = data?.volume || 0;
    dbo.year = data?.year?.id || data?.year || null;
    dbo.subThemeIds = getSubThemes(data?.subThemeIds);
    dbo.keywords = buildKeywords(data);
    dbo.authors = authorsInfo.authors;
    dbo.authorIds = authorsInfo.authorIds;
    dbo.updatedBy = data?.authorId || null;

    // console.log(dbo);

    return dbo;
}

export function getAuthors(researchers) {
    const authors = [];
    const authorIds = [];
    researchers.forEach(researcher => {
        authors.push(
            {
                authorId: researcher.id,
                authorName: researcher?.name || (researcher?.firstName + ' ' + researcher?.lastName),
                logo: researcher?.logo || null
            }
        );
        authorIds.push(researcher?.id);
    });
    return { authors, authorIds };
}


export function buildKeywords(data) {
    let keywords = [];
    const existingKeywords = data?.keywords || [];

    let nameArr = data?.name.split("");
    keywords = [...keywords, ...getCharCombo(nameArr, existingKeywords)];

    const wordKeyword = [...data?.name.split(" ")];
    keywords = [...keywords, ...getTermsAsKeywords(wordKeyword)];

    const journal = data?.journal?.journalName || (typeof data?.journal === 'string' ? data?.journal : data?.journal?.id);
    const otherKeywordAttr = [journal, data?.year?.name?.toString(), data?.name];
    keywords = [...keywords, ...getTermsAsKeywords(otherKeywordAttr, existingKeywords)];

    // Sub themes into Keywords
    if (data?.subThemeIds?.length) {
        keywords = [...keywords, ...getSubThemesKeywords(data.subThemeIds, existingKeywords)];
    }

    keywords = [...new Set([...keywords, ...existingKeywords || []])];

    return keywords;
}
