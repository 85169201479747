import { of, takeUntil } from "rxjs";
import { COLLECTION_NAMES, dateRangeConstants, filterParameters, FILTERS } from "../constants/constants";
import { entityConstants } from "../containers/onboarding/utils";


export function isElementInViewPort(el) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:me
    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight) || elemTop < window.innerHeight && elemBottom >= 0;

    return isVisible;
}

export function getColorByName(str) {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour
}

export function getContrastTextStyle(hex: string) {
    if (!hex || hex.length !== 7 || hex[0] !== '#') {
        return { color: '#24292E', filter: 'none' }; // Default dark text with no filter
    }

    // Convert HEX to RGB
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return white text with a filter for dark backgrounds, and dark text without a filter
    if (luminance > 0.5) {
        return { color: '#24292E', filter: 'none' }; // Dark text
    } else {
        return { color: '#FFFFFF', filter: 'invert(89%) sepia(100%) saturate(2%) hue-rotate(307deg) brightness(113%) contrast(101%)' }; // Light text with filter
    }
}


  export function getLighterColor(hex: string, opacity: number): string {
    if (!hex || hex.length !== 7 || hex[0] !== '#') {
        console.error('Invalid HEX color:', hex);
        return `rgba(0, 0, 0, ${opacity})`; // Fallback to black color with specified opacity
    }

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function isMultiFilterApplied(collectionData, multiFilters) {
    let entities = [];
    const multipleFilters = getFilters(multiFilters);
    if (multipleFilters?.length > 1) {
        let groupByFilterResults = [];
        multipleFilters.forEach((filterType) => {
            const filterKeywords = Object.keys(multiFilters[filterType]);
            const filter = [];
            for (let institute of collectionData) {
                if (filterKeywords.some(filtertkeyword => institute.keywords.includes(filtertkeyword))) {
                    filter.push(institute);
                }
            }
            groupByFilterResults.push(filter);
        });

        // If any filter group that does not have institute
        // It wont AND with any other filters
        const emptyGroupFilters = groupByFilterResults.filter((a) => !a.length);
        if (emptyGroupFilters?.length) {
            return emptyState();
        }

        const groupOfFilters = groupByFilterResults.filter((a) => a.length);
        groupByFilterResults = groupOfFilters.flat(1);

        const map = new Map();
        for (let entity of groupByFilterResults) {
            map.has(entity.id)
                ? (map.get(entity.id).count += 1)
                : map.set(entity.id, { ...entity, count: 1 });
        }


        const result = [];
        for (let [, obj] of map) {
            const { count, ...rest } = obj;
            if (count === groupOfFilters?.length) {
                result.push(rest);
            }
        }

        entities = [...result];
    }

    return {
        isMultiFilterApplied: multipleFilters?.length > 1,
        data: entities
    };
}

export function emptyState() {
    return {
        isMultiFilterApplied: true,
        data: []
    };
}

export function getFilters(multipleFilters) {
    const filters = [];
    Object.keys(multipleFilters).forEach(key => {
        const filter = !dateRangeConstants.includes(key.toLowerCase()) ? multipleFilters[key] : {};
        if (Object.keys(filter)?.length) {
            filters.push(key);
        }
    });

    return filters;
}

export function numberWithCommas(value) {
    if (!value) {
        return "";
    }
    return value.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isResearcher(user, entityType?) {
    const userSpecialization = (entityType || (user?.['entityType']))?.replace("_", "")?.toLowerCase();
    return (
        userSpecialization === entityConstants.researcher.toLowerCase() ||
        userSpecialization === entityConstants.student.toLowerCase()
    )
}