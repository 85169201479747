import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, Observable, Subject, take, takeUntil } from 'rxjs';
import { COLLECTION_NAMES, USER_ROLES } from 'src/app/constants/constants';
import { User } from 'src/app/models/userInfo.model';
import { AuthenticationService } from 'src/app/services/auth.service';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { isResearcher } from 'src/app/utils/utils';

@Component({
  selector: 'app-new-top-nav',
  templateUrl: './new-top-nav.component.html',
  styleUrls: ['./new-top-nav.component.scss'],
})
export class NewTopNavComponent implements OnInit {
  destroy$ = new Subject();
  currentPath = '';
  user$: Observable<User> = this.authService.getUser();
  userAuth$ = this.authService.getAuthUser();
  userAuthUser$: Observable<User> = this.authService.getUser();
  profile: any;
  showAdminPanel: boolean = false;

  constructor(private router: Router, private authService: AuthenticationService, private fbService: FirebaseCollectionService) {
    this.currentPath = document.location.pathname.split('/')[1] || '';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    document.body.addEventListener('click', () => {
      const seeMoreElem = document.getElementById('see-more');
      if (seeMoreElem && seeMoreElem.style.display !== 'none') {
        seeMoreElem.style.display = 'none';
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data: NavigationEnd) => {
        this.currentPath = data.url.split('/')[1] || '';
      });

    this.subscribeUserData();


  }

  subscribeUserData() {
    this.showAdminPanel = false;
    this.userAuthUser$.pipe(
      takeUntil(this.destroy$),
      map((user: any) => {
        const collection = this.getCollectionInfoRespectiveToTheSpecialization(user);
        this.subscribeProfile(collection, user?.email, user?.entityId);
        // console.log(user);
        if (user?.roles?.includes(USER_ROLES.superAdmin)) {
          this.showAdminPanel = true;
        }
      })
    ).subscribe();
  }

  getCollectionInfoRespectiveToTheSpecialization(user) {
    let collection = "";
    if (isResearcher(user)) {
      collection = COLLECTION_NAMES.researchers;
    } else {
      collection = COLLECTION_NAMES.institutes;
    }
    return collection;
  }

  subscribeProfile(collection, email, entityId?) {
    let obs$ = null;
    if (entityId) {
      obs$ = this.fbService.getDocument(collection, entityId);
    } else {
      obs$ = this.fbService.getDocumentByAtrribute(collection, 'email', email);
    }

    obs$.pipe(
      takeUntil(this.destroy$),
      map((data: any) => {
        this.profile = data?.payload?.data() ? { ...data?.payload?.data(), id: data?.payload?.id } : data[0];
      })
    ).subscribe();
  }

  onclickSeeMore(event: MouseEvent) {
    event.stopPropagation();
    const seeMoreBtnPosition = document
      .getElementById('see-more-btn')
      .getClientRects();

    const seeMoreElem = document.getElementById('see-more');
    if (seeMoreElem.style.display === 'none') {
      seeMoreElem.style.display = 'block';
      seeMoreElem.style.top =
        (seeMoreBtnPosition[0].top + 52).toString() + 'px';
      seeMoreElem.style.left = seeMoreBtnPosition[0].left.toString() + 'px';
    } else {
      seeMoreElem.style.display = 'none';
    }
  }

  onClickSideMenu(event: MouseEvent) {
    event.stopPropagation();
    const sideMenuElem = document.getElementById('side-menu');
    if (sideMenuElem.style.display === 'none') {
      sideMenuElem.style.display = 'flex';
    } else {
      sideMenuElem.style.display = 'none';
    }
  }
}
