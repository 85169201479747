<div class="relative">
    <span class="text-sm mb-1 flex gap-x-1" *ngIf="title">{{ title }} </span>
    <div (click)="toggleOpen()"
        class="border border-lightGrey rounded-lg cursor-pointer hover:border-primary focus:border-primary focus:ring-2 focus:ring flex justify-between h-12 p-3">
        <div class="flex items-center w-full">
            <div class="mr-2 bg-[#D6ECFF] w-8 h-8 rounded-full flex justify-center items-center">
                <img class="w-5" [src]="value.icon" alt="">
            </div>
            <div class="text-sm font-bold text-darkBlack w-[85%] truncate normal-case">{{value.title}}
            </div>
        </div>

        <img [ngClass]="open ? '' : '-rotate-180'" class=" w-4 cursor-pointer"
            src="../../../../../assets/images/arrow-icon.svg" alt="">
    </div>
    <div *ngIf="open"
        class="border border-[#E1E4E8] rounded-lg  absolute top-[3.25rem] w-full z-10 bg-white max-h-[300px] overflow-y-auto">
        <div class="flex flex-col p-1">
            <div class="" *ngFor="let item of dropList">
                <div class="hover:bg-[#F7F7F7] rounded-md flex" (click)="selectOption(item)">
                    <div class="bg-[#D6ECFF] w-5 h-5 rounded-full flex justify-center items-center m-2">
                        <img class="w-3 h-3" [src]="item.icon" alt="{{item.title}}">
                    </div>
                    <div class="flex flex-col py-2 w-11/12">
                        <span class="text-sm text-darkBlack cursor-pointer font-medium normal-case">{{item.title}}</span>
                        <span class="text-[#6A737D] text-xs cursor-pointer">{{item.description}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>