import { Component, Inject, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ShMembersListComponent } from '../sh-members-list/sh-members-list.component';
import { COLLECTION_NAMES, PAGINATION_PER_PAGE_DATA } from 'src/app/constants/constants';
import { catchError, debounce, debounceTime, distinctUntilChanged, map, of, Subject, takeUntil } from 'rxjs';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { ShLoadMoreComponent } from '../../sh-load-more/sh-load-more.component';
import { FormsModule } from '@angular/forms';
import { ShSpinnerLoaderComponent } from '../../sh-spinner-loader/sh-spinner-loader.component';

@Component({
  selector: 'app-sh-modify-members',
  standalone: true,
  imports: [CommonModule, MatIconModule, ShLoadMoreComponent, ShSpinnerLoaderComponent,
    MatButtonModule, ShMembersListComponent, FormsModule],
  templateUrl: './sh-modify-members.component.html',
  styleUrls: ['./sh-modify-members.component.scss']
})
export class ShModifyMembersComponent {

  _destroy$ = new Subject();
  value: string = 'Add members';
  loadMore: boolean;
  researchers: any[] = [];
  isAdd: boolean;
  entityId: any;
  startAfter: any;
  searchTerm: string;
  searchResearcher = new Subject();
  allResearchers: any[];
  loading: boolean;
  isLoaded: any;
  profile: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShModifyMembersComponent>,
    @Optional() public dialogRef: MatDialogRef<ShModifyMembersComponent>,
    private fbService: FirebaseCollectionService
  ) {
    // console.log(data);
    this.isAdd = data?.isAdd || bottomSheetData?.isAdd;
    if (!this.isAdd) {
      this.value = 'Modify team';
    }
    this.entityId = data?.entityId || bottomSheetData?.entityId;
    this.profile = data?.institute || bottomSheetData?.institute;
  }

  ngOnInit() {
    this.getResearchers();
    this.search();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  getResearchers(filters?) {
    let filter = filters || [];
    if (!this.isAdd) {
      filter = [{
        field: 'InstituteIds',
        comparator: 'array-contains',
        value: this.entityId,

      }];
    } else {
      filter = filters || [];
    }

    return this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.researchers,
        filter,
        [{ field: 'index', condition: 'asc' }],
        PAGINATION_PER_PAGE_DATA,
        null,
        this.startAfter,
        true
      )
      .pipe(
        takeUntil(this._destroy$),
        map((collectionData) => {
          this.loading = false;
          this.loadMore = false;
          if (collectionData?.length >= PAGINATION_PER_PAGE_DATA) {
            this.loadMore = true;
          }
          this.startAfter = collectionData[collectionData?.length - 1]?.ref || null;
          collectionData.forEach(data => {
            if (!this.researchers?.find(researcher => researcher?.id === data?.id)) {
              if (this.isAdd) {
                if (Array.isArray(data?.InstituteIds) && !data?.InstituteIds?.includes(this.entityId)) {
                  this.researchers.push(data);
                }
              } else {
                this.researchers.push(data);
              }
            }
          });
          if (!this.isLoaded && this.researchers?.length) {
            this.isLoaded = true;
            this.allResearchers = [...this.researchers];

          }

        }),
        catchError(e => {
          return of(e);
        })
      ).subscribe();
  }

  onSearch(searchTerm) {
    this.searchResearcher.next(searchTerm);
  }

  search() {
    this.searchResearcher.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map((searchTerm: string) => {
        if (searchTerm?.length) {
          this.loading = true;
          this.researchers = [];
          const filter = [this.fbService.getSearchQueryFilter(searchTerm)];
          this.getResearchers(filter);
        } else {
          this.researchers = this.allResearchers;
        }

      }),
      catchError(e => {
        return of(e);
      })
    ).subscribe();
  }

  buildPosition(instituteIds, positions, researcher) {
    const position = {};
    instituteIds.forEach(instituteId => {
      position[instituteId] = positions?.[instituteId] || researcher?.universityFaculty || "";
    });

    return position;
  }

  updateResearcher(event) {
    const { researcher, isAdd } = event;
    const { InstituteIds, positions } = researcher;

    let instituteIds = [];
    let payload = null;
    if (isAdd) {
      // this.entityId
      instituteIds = [...InstituteIds];
      instituteIds.push(this.entityId);

      const position = this.buildPosition(instituteIds, positions, researcher);
      payload = { InstituteIds: instituteIds, positions: position };
    } else {
      // this.entityId
      const index = InstituteIds.indexOf(this.entityId);
      InstituteIds.splice(index, 1);
      const position = this.buildPosition(InstituteIds, positions, researcher);
      payload = { InstituteIds, positions: position };
    }

    this.fbService.updateDocument(COLLECTION_NAMES.researchers, researcher?.id, payload)
      .pipe(
        map(res => {
          event.researcher.loading = false;
          event.researcher.isDone = true;
        }),
        catchError(e => {
          event.researcher.loading = false;
          event.researcher.isDone = false;
          return of(e);
        })
      ).subscribe()
  }

  cancelClick(value): void {
    if (value === 'model' && this.dialogRef) {
      this.dialogRef.close();  // Close dialog if it exists
    }
    if (value === '' && this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();  // Close bottom sheet if it exists
    }
  }
}
