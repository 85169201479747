import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { formatDate } from 'src/app/utils/date-service.utils';
import { map } from 'rxjs';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-sh-calendar',
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  providers: [
    MatDatepickerModule,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  templateUrl: './sh-calendar.component.html',
  styleUrls: ['./sh-calendar.component.scss']
})
export class ShCalendarBoxFilterComponent {
  currentSelectedDate: any;
  @Input() addCss: boolean = false;
  @Input() set isFilterCleared(val) {
    this.range.patchValue({ from: null, to: null });
  }

  @Input() isSignleDateSelectionComponent?: boolean;
  @Input() form: FormGroup;
  @Input() control: any = new FormControl();

  @Input() title?: string;
  @Input() element?: any;

  range = new FormGroup({
    from: new FormControl<any>(new Date(), [Validators.required]),
    to: new FormControl<Date | null>(null, [Validators.required]),
  });

  @Output() deadLineDate = new EventEmitter();

  selectDate: any;

  ngOnInit() {
    this.range.valueChanges.pipe(
      map(dates => {
        if (this.range.value.to) {
          // For Filter
          if (!this.isSignleDateSelectionComponent) {
            const fromDate = formatDate(new Date()) + 'T00:00';
            const toDate = formatDate(this.range.value.to) + 'T23:59';

            let param = { [fromDate + '_' + toDate]: true };
            if (this.currentSelectedDate) {
              param = Object.assign(param, { [this.currentSelectedDate]: false });
            }
            this.deadLineDate.emit(param);
            this.currentSelectedDate = fromDate + '_' + toDate;
          } else {
            const date = formatDate(this.range.value.to) + 'T00:00';
            this.deadLineDate.emit(date);
          }
        }
      })
    ).subscribe();
  }

  // addEvent() {
  //   if (this.range.valid) {
  //     const fromDate = formatDate(this.range.value.from);
  //     const toDate = formatDate(this.range.value.to);

  //     let param = { [fromDate + '_' + toDate]: true };
  //     if (this.currentSelectedDate) {
  //       param = Object.assign(param, { [this.currentSelectedDate]: false });
  //     }
  //     // this.deadLineDate.emit(param);
  //     this.currentSelectedDate = fromDate + '_' + toDate;
  //   }
  // }
}
