<div class="flex lg:flex-row flex-col w-full gap-x-4 mb-8 lg:p-0 ">
    <div class="w-full lg:w-4/6 flex flex-col gap-y-4">
        <div class="p-4 bg-white rounded-3xl flex flex-col">
            <div class="flex items-center normal-case justify-between w-full">
                <span class="text-base font-bold text-darkBlack ">About</span>

                <div (click)="openEditAboutModel()"
                    class=" rounded-full border border-[#D6ECFF] bg-white h-8 w-8 flex justify-center items-center cursor-pointer ">
                    <img src="../../../../assets/profile/edit_icon.svg" alt="">
                </div>
            </div>

            <p class="text-sm text-darkBlack leading-6 -tracking-[0.5px] pt-4" [innerHtml]="profileData?.description">
            </p>
        </div>


        <div class="p-4 bg-white rounded-3xl flex flex-col" *ngIf="isResearcher">
            <div class="flex items-center normal-case justify-between w-full">
                <span class="text-base font-bold text-darkBlack ">Education</span>

                <div (click)="openEditEducationModel()"
                    class=" rounded-full border border-[#D6ECFF] bg-white h-8 w-8 flex justify-center items-center cursor-pointer ">
                    <img src="../../../../assets/profile/edit_icon.svg" alt="">
                </div>
            </div>
            <!-- <div>{{ profileData | json }}</div> -->
            <div class="flex flex-col mt-3">
                <div class="flex items-center gap-2 mb-3"
                    *ngFor="let qualification of profileData?.qualifications | sortBy: 'university'; trackBy: trackById">
                    <div
                        class="h-16 w-16 bg-white p-2 flex items-center justify-center rounded-xl border border-[#E1E4E8]">
                        <img class="rounded-sm w-8" src="../../../../assets/signup-list/unicvercity_student_Icon.svg"
                            alt="">
                    </div>
                    <div class="flex flex-col">
                        <span class="text-sm font-bold text-[#171A1F] normal-case">{{ qualification?.university
                            }}</span>
                        <span class="text-sm text-[#171A1F] normal-case">{{ qualification?.fieldOfStudy }}</span>
                        <span class="text-xs text-[#171A1F]">{{ qualification?.yearOfGraduation }}</span>
                    </div>
                </div>
                <ng-container *ngIf="!profileData?.qualifications?.length">
                    <div class="flex items-center gap-2 mb-3 align-center justify-center">
                        <strong>No data found!</strong>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="p-4 bg-white rounded-3xl flex flex-col" *ngIf="isResearcher">
            <div class="flex items-center normal-case justify-between w-full">
                <span class="text-base font-bold text-darkBlack ">Affiliations </span>

                <div (click)="openEditAffiliation()"
                    class=" rounded-full border border-[#D6ECFF] bg-white h-8 w-8 flex justify-center items-center cursor-pointer ">
                    <img src="../../../../assets/profile/edit_icon.svg" alt="">
                </div>
            </div>

            <div class="flex flex-col mt-3">
                <ng-container *ngIf="loadingAffiliation">
                    <div class="flex align-center justify-center w-full h-36">
                        <app-sh-spinner-loader [colorCode]="'#004481'" [roundWidth]=20
                            [thinWidth]=2></app-sh-spinner-loader>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loadingAffiliation">
                    <ng-container *ngIf="profileData?.InstituteIds?.length">
                        <div class="flex items-center gap-2 mb-3"
                            *ngFor="let instituteId of profileData?.InstituteIds | sortBy: 'id'; trackBy: trackById">
                            <ng-container *ngIf="institutesObj?.[instituteId]">
                                <div
                                    class="h-16 w-16 bg-white p-2 flex items-center justify-center rounded-xl border border-[#E1E4E8]">
                                    <img class="rounded-sm"
                                        [src]="institutesObj?.[instituteId]?.logo ? institutesObj[instituteId]?.logo : 'https://firebasestorage.googleapis.com/v0/b/susanhub-qa.appspot.com/o/institute-logos%2FinstituteSample.jpeg?alt=media&token=fbb023e7-3b63-4839-8252-3dc6296d05b6'"
                                        alt="">
                                </div>
                                <div class="flex flex-col w-5/6">
                                    <span class="text-sm font-bold text-[#171A1F] normal-case">
                                        {{ institutesObj?.[instituteId]?.["name"] }}
                                    </span>
                                    <span
                                        class="text-sm text-[#171A1F] normal-case">{{profileData?.positions?.[instituteId]}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!profileData?.InstituteIds?.length">
                        <div class="flex items-center gap-2 mb-3 align-center justify-center">
                            <strong>No data found!</strong>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>

    </div>
    <div class="w-full flex flex-col gap-y-4 lg:w-2/6 lg:mt-0 mt-4 h-full">
        <div class="bg-white p-4 flex flex-col rounded-3xl h-full" *ngIf="isResearcher">
            <span class="text-base font-bold text-darkBlack ">Resume</span>

            <div class="flex flex-col">

                <div class="flex flex-col w-full">
                    <span class="text-[#6A737D] text-sm text-center pt-3" *ngIf="!profileData?.resume">You haven’t
                        uploaded the resume yet.</span>
                    <div class="relative mt-2">
                        <!-- Hidden File Input -->
                        <input type="file" id="fileInputResume" class="hidden"
                            (change)="!uploadingData ? uploadResume($event) : ''">

                        <!-- Custom Button -->
                        <label for="fileInputResume" *ngIf="!profileData?.resume"
                            class=" rounded-lg border border-[#D6ECFF] bg-white text-primary text-sm font-bold h-9 w-full flex justify-center items-center cursor-pointer ">
                            <div class="flex justify-center items-center gap-x-3">
                                <app-sh-spinner-loader [colorCode]="'#004481'" [roundWidth]=20 *ngIf="uploadingData"
                                    [thinWidth]=2></app-sh-spinner-loader>

                                {{uploadingData ? 'UPLOADING...' : 'UPLOAD RESUME'}}
                            </div>
                        </label>
                    </div>
                </div>



                <div class="flex justify-between items-center w-full gap-y-2" *ngIf="profileData?.resume">
                    <div class="flex py-4  items-center gap-x-2">
                        <div class="h-12 w-12 bg-[#FFE3E6] flex items-center justify-center rounded-full">
                            <img src="../../../../assets/profile/pdf_icon.svg" alt="">
                        </div>
                        <div class="flex flex-col">
                            <span class="text-sm font-bold text-darkBlack normal-case">{{ profileData?.resume?.name ||
                                'Updated resume'}}</span>
                            <span class="text-xs text-[#6A737D] normal-case">Uploaded on {{
                                profileData?.resume?.uploadedDate | date:
                                dateFormat}}</span>
                        </div>
                    </div>
                    <button [matMenuTriggerFor]="menu" mat-icon-button
                        aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon class="!font-material-icons text-[#24292E] rotate-90">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-dropdown ">
                        <button mat-menu-item (click)="updateResume()">Update</button>
                        <button mat-menu-item (click)="viewResume()">View</button>
                        <button mat-menu-item (click)="downloadResume()">Download</button>
                        <button mat-menu-item (click)="confirmDelete()">Delete</button>
                    </mat-menu>
                </div>

            </div>

        </div>

        <div class="bg-white p-4 flex flex-col rounded-3xl h-full">
            <span class="text-base font-bold text-darkBlack ">Other Links</span>


            <div class="flex flex-col w-full text-sm text-[#6A737D] gap-y-3 pt-4">
                <a class="cursor-pointer" (click)="resetPassword()">Reset Password</a>
                <a class="cursor-pointer" href="javascript:void(0)" (click)="privacyRoute($event)">Privacy Policy</a>
            </div>
        </div>
    </div>
</div>