import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-sh-spinner-loader',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './sh-spinner-loader.component.html',
  styleUrls: ['./sh-spinner-loader.component.scss']
})
export class ShSpinnerLoaderComponent {
  @Input() colorCode:any;
  @Input() roundWidth:number;
  @Input() thinWidth:number;
}
