<div class="flex flex-col w-full relative">

  <span class="text-sm mb-1 flex gap-x-1">{{ label }} <span *ngIf="element?.required" style="color: red;">*</span>
    <img *ngIf="info" class="cursor-pointer" src="../../../../assets/images/info.svg" matTooltip="{{ infoText }}"
      alt="">
  </span>

  <input [formControl]="control" [placeholder]="isUrl ? 'Eg: https://susanhub.com' : placeholder" [ngClass]="{
      'pl-4 pr-10': password, 
      'px-4': !password, 
      'border-[#EA4A5A]': control?.touched && (error || control?.hasError('required') || control?.hasError('isPasswordNotMatch') || control?.hasError('minlength') || control?.hasError('email') || control?.hasError('pattern')),
      'border-lightGrey': !control?.touched || !control?.errors,
      'h-12': !heightClass , 
      'h-10': heightClass, 
    }"
    class="border  rounded-md focus:outline-none hover:border-primary focus:border-primary placeholder:font-thin placeholder:text-[#959DA5]"
    [type]="password && hidePassword ? 'password' : 'text'" />

  <!-- Password visibility toggle -->
  <div *ngIf="password" class="absolute right-4 top-[2.4rem]" (click)="togglePasswordVisibility()">
    <mat-icon class="text-[#98A2B3]">{{ hidePassword ? 'visibility' : 'visibility_off' }}</mat-icon>
  </div>

  <!-- Error Message -->
  <span *ngIf="control?.touched && (error || control?.errors)" class="text-xs text-[#B60A1C] pl-4 pt-1">
    {{ errorMessage }}
  </span>
</div>