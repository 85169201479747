import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FirebaseCollectionService } from "./firebase-collection.service";
import { catchError, map, of, switchMap, take } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { COLLECTION_NAMES } from "../constants/constants";



@Injectable({
    providedIn: 'root',
})
export class SharedService {

    constructor(private _http: HttpClient,  private fbService: FirebaseCollectionService,) { }

    getCities() {
        return this._http.get("/assets/files/countries-cities.min.json");
    }

    getCountries() {
        return this._http.get("/assets/files/countries.json");
    }

    getSkills() {
        return this._http.get("/assets/files/skills.json");
    }

    getDesignation() {
        return this._http.get("/assets/files/designation.json");
    }

    getAuthor(authorId){
        if(!authorId){
            return of({})
        }
        return this.fbService
        .getDocument(COLLECTION_NAMES.researchers, authorId).pipe(
          take(1),
          switchMap(data => {
            return of( {...data.payload.data() as any, id: data.payload.id} )
          }),
          catchError(e => e)
        );
      }
}
/**
 * 
 */