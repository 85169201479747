import { Component, Inject, OnInit, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShSignRoleComponent } from '../../onboarding/sh-onboarding-sign/sh-sign-role/sh-sign-role.component';
import { ShInputTextComponent } from 'src/app/components/shared_components/sh-input-text/sh-input-text.component';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';
import { AuthenticationService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    CommonModule,
    ShSignRoleComponent,
    ShInputTextComponent,
    MatButtonModule,
    ShSpinnerLoaderComponent,
    MatBottomSheetModule,
    MatDialogModule
  ],
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  form: FormGroup;
  loggingIn: boolean;
  fbErr: string;
  showFBError: boolean;
  currentRouteName: string;
  unAuthPage: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private modal: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    // public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {
    this.buildForm();
    this.unAuthPage = (data?.page || bottomSheetData?.page);

  }

  ngOnInit(): void {
    // this.getCurrentRouteName();
  }

  buildForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  forgot() {
    this.router.navigateByUrl("/auth/forgot")
  }

  signup() {
    this.modal.closeAll();
    this.router.navigateByUrl("/onboarding")
  }
  login() {
    const { email, password } = this.form.value;
  
    this.loggingIn = true;
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        this.loggingIn = false;
  
        // Get the current route
        const currentUrl = this.router.url;
  
        if (this.unAuthPage) {
          // Close modal if applicable
          // this.modal.closeAll();
        }
  
        // Navigate to the current route (stay on the same page)

        if(currentUrl === '/auth/login'){
          this.router.navigate(['/']);
        } else {
          this.router.navigateByUrl(currentUrl);
        }
      
  
        this.close();
        // Signed in 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        this.loggingIn = false;
        this.fbErr = this.authService.generateAuthErrorMessage(error);
        this.showFBError = true;
      });
  }
  
  // login() {
  //   const { email, password } = this.form.value;

  //   this.loggingIn = true;
  //   const auth = getAuth();
  //   signInWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       this.loggingIn = false;
  //       if (this.unAuthPage) {
  //         // this.modal.closeAll();
  //       }
  //       this.router.navigate(['/']);
  //       this.close();
  //       // Signed in 
  //       const user = userCredential.user;
  //       // ...
  //     })
  //     .catch((error) => {
  //       this.loggingIn = false;
  //       this.fbErr = this.authService.generateAuthErrorMessage(error);
  //       this.showFBError = true;
  //     });
  // }

  close() {
    if (this.modal) {
      this.modal.closeAll();
    }
    if (this._bottomSheet) {
      this._bottomSheet.dismiss();
    }
  }
}
