import { Component, HostListener, Inject, Input, Optional, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShProfileDeleteModelComponent } from '../../../sh-profile-delete-model/sh-profile-delete-model.component';
import { myProfileItems } from '../../../util';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { COLLECTION_NAMES, COLORS, PAGINATION_PER_PAGE_DATA } from 'src/app/constants/constants';
import { catchError, map, of, Subject, take, takeUntil } from 'rxjs';
import { getColorByName, getLighterColor } from 'src/app/utils/utils';
import { ShThemeItemComponent } from 'src/app/components/sh-theme-item/sh-theme-item.component';
import { MatChipsModule } from '@angular/material/chips';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ShAddMyItemsModelComponent } from './sh-add-datasets-model/sh-add-my-items-model.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import { ShEmptyStateComponent } from './sh-empty-state/sh-empty-state.component';
import { ShLoadMoreComponent } from 'src/app/components/common/sh-load-more/sh-load-more.component';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';

@Component({
  selector: 'app-sh-profile-datasets-papers',
  standalone: true,
  imports: [CommonModule, RouterModule,
    MatMenuModule, MatIconModule, MatButtonModule,
    ShThemeItemComponent, MatChipsModule,
    ShEmptyStateComponent, ShLoadMoreComponent, ShSpinnerLoaderComponent],
  templateUrl: './sh-profile-datasets-papers.component.html',
  styleUrls: ['./sh-profile-datasets-papers.component.scss']
})
export class ShProfileDatasetsPapersComponent implements OnInit {
  @Input() Image: boolean;
  @Input() profile: any;
  @Input() isSavedItems?: boolean;

  myProfileItems = myProfileItems;
  themesArray: any;
  subThemes: any;
  collectionName: string;
  moduleEntity: string;
  entityModule: string;
  label: string;
  @Input() entity;
  showLoadMore: boolean;
  loading: boolean;

  @Input() set selectedTab(value) {
    this.loadData(this.entity)
  }



  destroy$ = new Subject();
  data = [];

  constructor(
    public dialogModel: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private fbService: FirebaseCollectionService,
    private breakpointObserver: BreakpointObserver,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShAddMyItemsModelComponent>,
    @Optional() public dialogRef: MatDialogRef<ShAddMyItemsModelComponent>,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {

  }

  ngOnInit() {

  }

  @HostListener('click', ['$event'])
  public onClickMenu(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  loadData(entity: string) {
    this.entityModule = entity;
    const authorId = this.profile?.id;
    if (entity.toLowerCase() === myProfileItems.dataset.toLowerCase()) {
      this.moduleEntity = myProfileItems.dataset;
      const collectionName = COLLECTION_NAMES.dataSetThemes;
      this.fetchThemes(collectionName);

      this.collectionName = COLLECTION_NAMES.datasets;
      this.fetchData(authorId);
    } else {
      this.moduleEntity = myProfileItems.papers;
      const collectionName = COLLECTION_NAMES.themes;
      this.fetchThemes(collectionName);

      this.collectionName = COLLECTION_NAMES.publications;
      this.fetchData(authorId);
    }
  }

  loadMoreData() {
    this.fetchData(this.profile?.id, true);
  }

  // getAuthorsByAuthorIds() {}

  fetchData(authorId, next?) {
    this.loading = true;
    const filters: any = !this.isSavedItems ? [{
      field: 'authorId',
      comparator: '==',
      value: authorId,
    }] : [{
      field: 'savedBy',
      comparator: 'array-contains',
      value: authorId,
    }];
    // this.collectionName = COLLECTION_NAMES.datasets;
    this.fbService
      .queryCollectionSnapshot(
        this.collectionName,
        filters || [],
        [{ field: 'index', condition: 'desc' }],
        PAGINATION_PER_PAGE_DATA,
        !next ? null : this.data[this.data?.length - 1]['ref'],
        null,
        true
      ).pipe(
        takeUntil(this.destroy$),
        map(data => {
          this.loading = false;
          if (!next) {
            this.data = data;
          } else {
            this.data = [...(this.data || []), ...(data || [])];
          }
          if (data?.length >= PAGINATION_PER_PAGE_DATA) {
            this.showLoadMore = true;
          } else {
            this.showLoadMore = false;
          }
        }),
        catchError(e => {
          this.loading = false;
          return of(e)
        })
      ).subscribe();
  }

  fetchThemes(collectionName) {
    this.fbService
      .queryCollectionSnapshot(collectionName)
      .pipe(
        take(1),
        map((themes) => {
          this.themesArray = themes;
          themes.forEach((theme, index) => {
            theme['color'] = theme.color || getColorByName(theme.name);
            theme['secondaryColor'] = getLighterColor(theme.color, 0.3);

            (theme?.subThemes || []).forEach(subTheme => {
              subTheme.secondaryColor = theme.secondaryColor;
              this.subThemes = Object.assign(this.subThemes || {}, { [subTheme.id]: subTheme });
            });
          });

        }),
        catchError(err => {
          return of(err);
        })
      )
      .subscribe();
  }

  confirmDelete(item) {
    const modalRef = this.dialogModel.open(ShProfileDeleteModelComponent, {
      width: '350px',
      //  height: '300px',
      panelClass: 'profile-model',
      autoFocus: false,
      data: {
        entity: this.entityModule
      }
    });

    modalRef.afterClosed().pipe(
      take(1),
      map(confirmed => {
        if (confirmed) {
          this.delete(item);
        }
      })
    ).subscribe();

  }

  edit(item?): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShAddMyItemsModelComponent, {
          data: {
            entity: this.entityModule,
            profile: this.profile,
            data: item
          }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialogModel.open(ShAddMyItemsModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: {
            entity: this.entityModule,
            profile: this.profile,
            data: item
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {

          }
        });
      }
    });
  }

  delete(item) {
    this.fbService.deleteDocument(this.collectionName, item?.id).pipe(
      take(1),
      map(res => {
        const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
        this.snackbar.open(`"${capitalize(item?.name)}" deleted successfully!, "OK"`);
        // this.snackbar.open( + " deleted successfully!", "OK")
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  view(item) {
    if (this.moduleEntity === myProfileItems.dataset) {
      this.router.navigate(['./datasets/' + item?.id]);
    }
  }
}
