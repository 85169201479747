
import { getCharCombo, getWordsCombo, getSubThemesKeywords, getTermsAsKeywords } from "./utils";
import { formatDate } from "../utils/date-service.utils";

export interface Jobs {
    city?: string,
    company?: string,
    companyId?: string,
    country?: string,
    datePosted?: string,
    description?: string,
    experience?: string,
    index?: number,
    jobSector?: string,
    jobSkills?: string[],
    jobUrl?: string,
    keywords?: string[],
    logo?: string,
    name?: string,
    type?: string,
    unorderedAddress?: string,
    authorId?: string
}


export function jobsDbo(data, job?, profile?) {
    const dbo: Jobs = {};

    dbo.city = data?.city?.id || data?.city || null;
    dbo.company = data?.company;
    dbo.companyId = data?.companyId || null;
    dbo.country = data?.country?.id || data?.country || null;
    dbo.description = data?.description || null;
    dbo.experience = data?.experience || null;
    dbo.index = data?.index || 1;
    dbo.jobSector = data?.jobSector || null;
    dbo.jobSkills = getJobSkills(data?.jobSkills) || [];
    dbo.jobUrl = data?.jobUrl || null;
    dbo.keywords = buildKeywords(data, job?.keywords);
    dbo.logo = data?.logo || null;
    dbo.name = data?.name || null;
    dbo.type = data?.type?.id || data?.type || null;
    dbo.unorderedAddress = (data?.city?.id || data?.city) + ', ' + (data?.country?.id || data?.country);
    dbo.authorId = profile?.id;

    if (!job) {
        dbo.datePosted = formatDate(new Date());
    }

    // console.log(dbo);
    return dbo;
}

export function getJobSkills(skills) {
    const jobSkills = [];
    const sklls = [...skills || []];
    sklls.forEach(skill => {
        const item = skill?.id || skill;
        if (!jobSkills?.includes(item)) {
            jobSkills.push(item);
        }
    });

    return jobSkills;
}


export function buildKeywords(data, existingKeywords) {
    let keywords = [];

    let nameArr = data?.name?.split("") || [];
    keywords = [...keywords, ...getCharCombo(nameArr, (existingKeywords || []))];


    const wordKeyword = [data?.type || '', ...data?.name?.split(" ")];
    keywords = [...keywords, ...getTermsAsKeywords(wordKeyword)];

    const country = data?.country?.id || data?.country;
    const city = data?.city?.id || data?.city;
    const type = data?.type?.id || data?.type;
    const skills = getSkills([...data?.jobSkills || []]);
    const otherKeywordAttr = [type, country, city, data?.jobSector, data.type];
    keywords = [...keywords, ...skills, ...getTermsAsKeywords(otherKeywordAttr, existingKeywords)];

    // Sub themes into Keywords
    if (data?.subThemeIds?.length) {
        keywords = [...keywords, ...getSubThemesKeywords(data.subThemeIds, existingKeywords)];
    }

    keywords = [...new Set([...keywords, ...existingKeywords || []])];

    return keywords;
}

export function getSkills(skills) {
    const allSkills = [];
    skills.forEach(skill => {
        if (skill?.id) {
            allSkills.push(skill?.name);
        } else {
            allSkills.push(skill);
        }
    });

    return allSkills;
}

