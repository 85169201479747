<section class="rounded-3xl p-5 text-[#24292E] tracking-[-0.5px] bg-white">
    <div class="card-container__heading"><strong>Network</strong></div>
    <div class="overflow-x">

        <div *ngIf="loadingNetwork">
            <cards-listing [cardWidth]="280" [colNumMobile]="3" [colNumTablet]="3" [colNumLarge]="3"
                [colNumExtraLarge]="3">
                <div class="animate-pulse shadow-lg rounded-lg p-4 team-card" *ngFor="let items of [0, 1, 2]">
                    <div class="w-full flex justify-center items-center">
                        <div class="bg-gray-300 h-[150px] w-[150px] rounded-full mb-4 "></div>
                    </div>
                    <div class="space-y-2 w-full flex flex-col justify-center items-center">
                        <div class="bg-gray-300 h-5 w-3/4 rounded-xl"></div>
                        <div class="bg-gray-300 h-3 w-full mt-4 rounded-xl"></div>
                        <div class="bg-gray-300 h-3 w-2/3 rounded-xl"></div>
                        <div class="bg-gray-300 h-4 w-1/2 rounded-xl"></div>
                    </div>
                    <div class="mt-4 gap-x-2 flex">
                        <div class="bg-gray-300 h-4 w-1/2 rounded-xl"></div>
                        <div class="bg-gray-300 h-4 w-1/2 rounded-xl"></div>
                    </div>
                </div>
            </cards-listing>
        </div>

        <cards-listing [cssClasses]="cssClasses" [cardWidth]="350" [colNumMobile]="3" [colNumTablet]="3"
            [colNumLarge]="3" [colNumExtraLarge]="3">

            <ng-container *ngIf="networks?.length && !loadingNetwork">
                <a routerLink="/researchers/{{researcher?.id}}"  target="_blank" rel="network" class="team-card border border-[#e1e4e8] p-4 rounded-md"
                    *ngFor="let researcher of networks">
                    <div class="team-card__pic">
                        <div class="lenin" [ngStyle]="{
                            'background-image': 'url(' + (researcher?.logo ? researcher?.logo : '/assets/images/person.png') + ')'
                        }"></div>
                    </div>
                    <div class="team-card__name">
                        <strong>{{ researcher?.firstName }}&nbsp;{{
                            researcher?.lastName
                            }}</strong>
                    </div>
                    <div class="team-card__faculty text-sm text-[#24292E]">
                        <div>{{ researcher?.universityTitle }}</div>
                        <div>
                            {{ university?.university }}
                        </div>
                    </div>
                </a>
            </ng-container>
        </cards-listing>
        <ng-container *ngIf="!networks?.length && !loadingNetwork">
            <app-sh-empty-state [viewOnly]="true"></app-sh-empty-state>
        </ng-container>
    </div>
</section>