<div [ngClass]="addCss ? 'date-picker gap-y-1': 'filter-date-picker gap-y-4 mt-4'"
    class="flex relative  flex flex-col w-full ">
    <mat-label>{{title}} <span *ngIf="element?.required" style="color: red;">*</span> </mat-label>
    <mat-form-field class="w-full" (click)="picker.open()" [formGroup]="range">
        <input [formControl]="control" matInput [matDatepicker]="picker"
            [ngClass]="{'border-[#EA4A5A]': control?.dirty && form?.errors?.['invalidDate'] }"
            placeholder="Select {{title ? title.toLowerCase() : 'deadline'}}" formControlName="to">
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button [ngClass]="addCss ? 'top-[2.5rem]': 'top-[2.9rem]'" class="absolute  px-2" mat-raised-button
        (click)="picker.open()">
        <img class="w-[20px]" src="../../../../assets/images/calendar-line.svg" alt="">
    </button>
    <div [ngClass]="addCss ? 'right-3 top-[2.5rem]': 'right-2 top-[2.8rem]'" class="absolute  cursor-pointer"
        (click)="picker.open()">
        <img [ngClass]="addCss ? 'w-5': 'w-6'" src="../../../../assets/images/arrow-down-s-fill.svg" alt="">
    </div>

    <div *ngIf="form?.errors?.['invalidDate']" class="text-red-600"> Invalid {{title?.toLowerCase()}}
    </div>
</div>