import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-sh-input-text',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatIconModule, MatTooltipModule],
  templateUrl: './sh-input-text.component.html',
  styleUrls: ['./sh-input-text.component.scss']
})
export class ShInputTextComponent {

  hidePassword = true;

  @Input() control: any = new FormControl();
  @Input() errorMessage: string;
  @Input() label: any;
  @Input() placeholder: any;
  @Input() password: boolean;
  @Input() error: boolean;
  @Input() errorMsg: any;
  @Input() heightClass: boolean;
  @Input() isUrl?: boolean;
  @Input() infoText: any;
  @Input() info: boolean;
  @Input() element?: any;

  // Toggle password visibility
  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
