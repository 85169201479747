import { Component, Inject, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { ShInputTextComponent } from 'src/app/components/shared_components/sh-input-text/sh-input-text.component';
import { ShSelectDropdownComponent } from 'src/app/components/shared_components/sh-select-dropdown/sh-select-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { affiliationForm, closeDialog, dbConstants } from '../util';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { catchError, map, of, Subject, take, takeUntil } from 'rxjs';
import { isResearcher } from 'src/app/utils/utils';
import { COLLECTION_NAMES } from 'src/app/constants/constants';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';
import { ShCustomSelectComponent } from 'src/app/components/shared_components/sh-ng-select/sh-ng-select.component';
import { dynamicElementTypes } from '../../onboarding/utils';
import { SortByPipe } from 'src/app/pipes/sortBy.pipe';

@Component({
  selector: 'app-sh-affiliation-add-profile-model',
  standalone: true,
  imports: [CommonModule,
    ShInputTextComponent,
    ShCustomSelectComponent,
    ShSpinnerLoaderComponent, MatIconModule, MatButtonModule, MatMenuModule, SortByPipe],
  templateUrl: './sh-affiliation-add-profile-model.component.html',
  styleUrls: ['./sh-affiliation-add-profile-model.component.scss']
})
export class ShAffiliationAddProfileModelComponent {

  destroy$ = new Subject();

  openForm: boolean = false;
  form: FormGroup;
  pageContents = affiliationForm;
  dynamicElementTypes = dynamicElementTypes;
  profile: any;
  currentUser: any;
  pageLayout: any;
  affiliations: any;
  isEdit: boolean;
  editIndex: any;
  uploadingData: boolean;
  loadingAffiliation: boolean;
  currentAffiliations: any[] = [];

  constructor(
    private fb: FormBuilder,
    private fbService: FirebaseCollectionService,
    private snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: { profile: any, user: any },
    @Optional() public dialogRef: MatDialogRef<ShAffiliationAddProfileModelComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShAffiliationAddProfileModelComponent>
  ) {
    this.profile = JSON.parse(JSON.stringify(data?.profile || bottomSheetData?.profile));
    this.currentUser = data?.user || bottomSheetData?.user;
    this.pageLayout = this.pageContents.elements;
  }

  ngOnInit() {
    this.buildForm();
    this.getAffiliation();
  }

  buildForm(affiliation?) {
    const group = {};
    this.pageLayout.forEach((element: any) => {
      group[element?.attribute] = affiliation ? new FormControl(affiliation[element?.attribute]) : new FormControl();

      if (affiliation) {
        if (element.attribute === 'institute') {
          element.selected = affiliation[element?.attribute];
        }
      }

      if (element.attribute === 'institute') {
        this.loadData(element);
      }
    });

    this.form = this.fb.group(group);
  }

  loadData(element) {
    this.subscribeDataFromFirestore(element);
  }

  subscribeDataFromFirestore(element, filter?) {
    this.fbService
      .queryCollectionSnapshot(
        dbConstants[element.attribute]?.db,
        filter || [],
        [{ field: 'name', condition: 'asc' }],
        25
      ).pipe(
        // takeUntil(this.destroy$),
        take(1),
        map(data => {
          // console.log(data);
          element.data = [...data];
          element.filterData = [...data];
        }),
        catchError(e => {
          console.log(e);
          return of(e);
        })
      ).subscribe();
  }

  onSearch(data) {
    const { term, element } = data;
    const searchTerm = term.trim();
    if (searchTerm) {
      const filter = [this.fbService.getSearchQueryFilter(searchTerm?.toLowerCase())];
      this.subscribeDataFromFirestore(element, filter);
    } else {
      this.subscribeDataFromFirestore(element);
    }
  }

  edit(affiliation, index) {
    this.openForm = true;
    this.buildForm(affiliation);
    this.isEdit = true;
    this.editIndex = index;
  }

  delete(affiliation, index) {
    this.currentAffiliations = this.currentAffiliations.filter(currentAff => currentAff?.institute !== affiliation?.institute);

    const InstituteIds = this.profile?.InstituteIds.filter(instituteId => instituteId !== affiliation?.institute);
    this.profile.InstituteIds = InstituteIds;

    const positions = this.profile?.positions;
    delete positions[affiliation?.institute];
    this.profile.positions = positions;

    this.affiliations = {
      InstituteIds,
      positions
    };
  }

  add() {
    const affiliation = { ...this.form.value };
    const instituteId = affiliation?.institute?.id || affiliation?.institute || null;
    const designation = affiliation?.designation;

    const InstituteIds = this.profile?.InstituteIds || [];
    let positions = this.profile?.positions || {};

    const affiliationData = {
      institute: instituteId,
      name: this.isEdit ? this.currentAffiliations[this.editIndex]?.name : affiliation?.institute?.name,
      designation: affiliation?.designation,
      logo: this.isEdit ? this.currentAffiliations[this.editIndex]?.logo : affiliation?.institute?.logo || null
    };

    if (this.isEdit) {
      this.currentAffiliations[this.editIndex] = affiliationData;
      InstituteIds[this.editIndex] = instituteId;
    } else {
      InstituteIds.push(instituteId);
      this.currentAffiliations.push(affiliationData);
    }

    positions = Object.assign(positions || {}, { [instituteId]: designation });

    this.affiliations = {
      InstituteIds,
      positions
    };

    this.form.reset();
    this.showForm();
  }

  save() {
    this.uploadingData = true;
    const collectionName = this.getCollectionInfoRespectiveToTheSpecialization();

    // console.log(this.affiliations);

    this.fbService.updateDocument(collectionName, this.profile.id, this.affiliations).pipe(
      map(res => {
        this.snackBar.open('Updated successfully!', 'OK');
        closeDialog(this.dialogRef, this.bottomSheetRef);
        this.uploadingData = false;
      }),
      catchError(e => {
        this.uploadingData = false;
        return of(e);
      })
    ).subscribe();
  }

  getCollectionInfoRespectiveToTheSpecialization() {
    let collection = "";
    if (isResearcher(this.currentUser)) {
      collection = COLLECTION_NAMES.researchers;
    } else {
      collection = COLLECTION_NAMES.institutes;
    }
    return collection;
  }

  cancelClick(value): void {
    if (value === 'model' && this.dialogRef) {
      this.dialogRef.close();  // Close dialog if it exists
    }
    if (value === '' && this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();  // Close bottom sheet if it exists
    }
  }

  showForm() {
    this.openForm = !this.openForm;
    this.isEdit = false;
  }

  getAffiliation() {
    this.loadingAffiliation = true;
    if (this.profile.InstituteIds?.length) {
      this.fbService
        .queryCollection(COLLECTION_NAMES.institutes, [
          {
            field: 'id',
            comparator: 'in',
            value: this.profile.InstituteIds.slice(0, 30),
          },
        ])
        .pipe(take(1))
        .subscribe((institutesCollection) => {
          this.loadingAffiliation = false;
          const affiliations = [];
          for (const doc of institutesCollection) {
            const data = doc.payload.doc.data() as any;
            affiliations.push({
              institute: data?.id,
              name: data?.name,
              designation: this.profile?.positions?.[data?.id],
              logo: data?.logo
            });
          }

          this.currentAffiliations = [...affiliations];
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  trackById(data) {
    return data?.id;
  }
}
