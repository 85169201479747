import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { entityConstants, instituteEntityElements, reasearchEntityElements, signUpEntityElements } from '../../utils';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/models/userInfo.model';
import { AuthenticationService } from 'src/app/services/auth.service';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';

@Component({
  selector: 'app-sh-sign-role',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sh-sign-role.component.html',
  styleUrls: ['./sh-sign-role.component.scss']
})
export class ShSignRoleComponent {
  destroy$ = new Subject();
  userAuthUser$: Observable<User> = this.authService.getUser();
  user: User;
  formControl: any = new FormControl();
  @Output() currentRole = new EventEmitter();

  // @Input() control: any = new FormControl();
  @Input() set control(formControl) {
    this.formControl = formControl;
    const value = formControl?.value;
    if (value) {
      this.value = signUpEntityElements.find(element => element.constant === value);
    }
  }
  @Input() title: string;
  open: any;
  dropList = signUpEntityElements;
  value: any = this.dropList[0];


  constructor(private authService: AuthenticationService,
    private fbService: FirebaseCollectionService,) {


  }

  ngOnInit() {
    this.getUserInfo();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getUserInfo() {
    this.userAuthUser$.pipe(
      takeUntil(this.destroy$),
      // take(1),
      map(user => {
        this.user = user;
        if (this.user?.entityType) {
          if (this.user?.entityType === entityConstants.researcher || this.user?.entityType === entityConstants.student) {
            this.dropList = reasearchEntityElements;
          } else {
            this.dropList = instituteEntityElements;
          }
        }

      })
    ).subscribe();
  }

  toggleOpen() {
    this.open = !this.open;
  }

  selectOption(option) {
    this.value = option;
    this.formControl.setValue(option.constant);
    this.toggleOpen();
    this.currentRole.emit(option);
  }
}
