<div [class.dark-theme]="true" class="flex flex-col p-6 overflow-hidden">
    <div class="flex w-full justify-between">
        <span class="text-lg md:text-2xl text-darkBlack font-bold">Edit Affiliation</span>

        <mat-icon (click)="cancelClick('model')"
            class="text-[#959DA5] text-lg cursor-pointer !hidden md:!block">close</mat-icon>
        <mat-icon (click)="cancelClick('')"
            class="text-[#959DA5] text-lg cursor-pointer md:!hidden !block">close</mat-icon>
    </div>

    <section class="w-full flex flex-col mt-4">

        <div class="min-h-[50px] max-h-[150px] overflow-y-auto " *ngIf="currentAffiliations.length > 0">
            <div class="flex justify-between items-center w-full gap-y-3 mb-3"
                *ngFor="let affiliation of currentAffiliations | sortBy:'institute'; let index=index; trackBy:trackById">

                <ng-container *ngIf="affiliation?.institute">
                    <div class="flex gap-x-3 w-full">
                        <div class="w-16 h-16 border border-[#E1E4E8] rounded-xl flex justify-center items-center p-1">
                            <img [src]="affiliation?.logo ? affiliation.logo : 'https://firebasestorage.googleapis.com/v0/b/susanhub-qa.appspot.com/o/institute-logos%2FinstituteSample.jpeg?alt=media&token=fbb023e7-3b63-4839-8252-3dc6296d05b6'"
                                alt="university" class="w-full rounded-xl" />
                        </div>

                        <div class="w-3/4">
                            <div class="text-sm font-bold text-[#171A1F] normal-case">
                                {{ affiliation?.["name"] }}
                            </div>
                            <div class="text-sm text-[#171A1F] pt-1 normal-case">{{ affiliation?.designation }}</div>
                        </div>
                    </div>
                </ng-container>
                <button class="focus:outline-none" [matMenuTriggerFor]="menu">
                    <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu-dropdown">
                    <button mat-menu-item (click)="edit(affiliation, index)">Edit</button>
                    <button mat-menu-item (click)="delete(affiliation, index)">Delete</button>
                </mat-menu>
            </div>
        </div>

        <div class="flex flex-col gap-y-2 rounded-lg border p-3" *ngIf="openForm">
            <span class="text-sm text-[#171A1F] font-semibold">{{!isEdit ? 'Add affiliation' : 'Update affiliation'}}</span>

            <form formGroup="form" class="flex flex-col gap-y-2">
                <ng-container *ngFor="let element of pageLayout">
                    <ng-container *ngIf="element.type == dynamicElementTypes.text">
                        <app-sh-input-text [control]="form.get(element?.attribute)" [placeholder]="element?.title"
                            [label]="element?.title"></app-sh-input-text>
                    </ng-container>

                    <ng-container
                        *ngIf="element.type == dynamicElementTypes.select || element.type == dynamicElementTypes.multiSelect">
                        <app-ng-custom-select [control]="form.get(element?.attribute)"
                            [isMultiple]="element.type == dynamicElementTypes.multiSelect" [data]="element.filterData"
                            [PlaceholderText]="element?.title" [LabelText]="element?.title" [element]="element"
                            [model]="element?.selected" (search)="onSearch($event)"></app-ng-custom-select>
                    </ng-container>

                </ng-container>
            </form>

            <div class="flex justify-start mt-2 gap-x-3">
                <button mat-raised-button class="w-[90px] h-8 !bg-white !text-black !rounded-lg"
                    (click)="showForm()">CANCEL</button>
                <button mat-raised-button class="w-[90px] h-8 !bg-black !text-white !rounded-lg"
                    (click)="add()">{{!isEdit ? 'ADD' : 'UPDATE'}}</button>
            </div>
        </div>
        <div class="w-full" *ngIf="!openForm">
            <button class="!h-11 w-full" mat-stroked-button (click)="showForm()">
                <span class="load-more text-base !text-primary font-semibold">
                    {{ currentAffiliations?.length > 0 ? 'ADD ANOTHER' : 'ADD NEW' }}
                </span>
            </button>
        </div>




        <div class="flex justify-end mt-4">
            <button mat-raised-button color="primary" class="w-24" (click)="save()"
                [disabled]="uploadingData || isEdit || openForm">
                <app-sh-spinner-loader *ngIf="uploadingData" [colorCode]="'#fff'" [roundWidth]=20
                    [thinWidth]=2></app-sh-spinner-loader>
                {{uploadingData ? '' : 'SAVE'}}
            </button>
        </div>
    </section>

</div>