<div class="flex flex-col gap-y-2">
    <div class="flex md:flex-row flex-col md:justify-between md:items-center">
        <span class="text-[24px] text-darkBlack font-bold">All Members</span>
        <div class="flex gap-x-2 pt-2 md:pt-0">
            <button *ngIf="researchers?.length" mat-raised-button color="primary"
                class="!rounded-lg !h-11 !px-5 !font-bold" (click)="openAddMebers(false)">MODIFY MEMBERS</button>
            <button mat-raised-button color="primary" class="!rounded-lg !h-11 !px-5 !font-bold"
                (click)="openAddMebers(true)">ADD MEMBERS</button>
        </div>
    </div>

    <div class="flex align-center justify-center pt-5 pb-5 bg-white rounded-xl mb-5" *ngIf="loading">
        <app-sh-spinner-loader [colorCode]="'#D6ECFF'" [roundWidth]="20" [thinWidth]="2"></app-sh-spinner-loader>
    </div>

    <div class=" my-4" *ngIf="researchers?.length">
        <app-sh-members-list [teams]="researchers"></app-sh-members-list>
    </div>
    <div class="flex align-center justify-center mt-4" *ngIf="loadMore">
        <app-sh-load-more (loadMore)="getResearchers(true)"></app-sh-load-more>
    </div>

    <div class="flex align-center justify-center pt-5 pb-5 bg-white rounded-xl mb-5"
        *ngIf="!researchers?.length && !loading">
        <div>
            No team found!
        </div>
    </div>

</div>