<div *ngIf="!teams?.length" class="text-sm text-center w-full py-6">
  No teams found!
</div>

<div [class]="boxClass">
  <ng-container *ngFor="let member of teams; trackBy:trackByResearcher">

    <a [class]="borderClass" *ngIf="!member?.isDone" [routerLink]="!isModel ? '/researchers/'+member?.id : []"
      [target]="!isModel ? '_blank' : '_self'" rel="team"
      class="flex flex-col bg-white  hover:shadow-lg transition-shadow duration-300 border border-[#f7f7f7] cursor-pointer">
      <!-- Member Image -->
      <img [ngClass]="!isModel ? 'w-[150px] h-[150px]' : 'w-12 h-12'" class=" mx-auto rounded-full object-cover"
        [src]="member?.logo || './../../../../../../assets/images/person.png'" alt="no-img" />

      <!-- Member Role -->
      <span class="text-xs text-primary text-center">
        {{member?.universityTitle || ''}}
      </span>

      <!-- Member Name -->
      <div [ngClass]="!isModel ?  'text-base font-semibold' : 'text-sm font-medium'"
        class=" text-center capitalize text-[#24292E] overflow-hidden text-ellipsis whitespace-normal leading-tight">
        {{ member?.firstName }} {{ member?.lastName }}
      </div>

      <!-- Member Details -->
      <div class="text-xs text-gray-600 text-center flex flex-col space-y-1">
        <span *ngIf="member?.universityFaculty">{{ member?.universityFaculty}}</span>
        <span *ngIf="member?.university">{{ member?.university }}</span>
      </div>
      <div *ngIf="isModel" class="mt-2">
        <button *ngIf="!isAdd" (click)="updateMember(member)" [disabled]="member?.loading"
          class="!rounded-md !h-8 w-full !px-5 !font-bold !text-xs !text-[#B60A1C] border !border-[#FFE3E6]" mat-button
          color="warn">
          <app-sh-spinner-loader *ngIf="member.loading" [colorCode]="'#B60A1C'" [roundWidth]=20
            [thinWidth]=2></app-sh-spinner-loader>

          {{member.loading ? '' : (member?.isDone ? 'REMOVED' : 'REMOVE')}}
        </button>

        <button *ngIf="isAdd" (click)="updateMember(member)" [disabled]="member?.loading"
          class="!rounded-md !h-8 w-full !px-5 !font-bold !text-xs !text-white " mat-raised-button color="primary">
          <app-sh-spinner-loader *ngIf="member.loading" [colorCode]="'#004481'" [roundWidth]=20
            [thinWidth]=2></app-sh-spinner-loader>

          {{member.loading? '' : (member?.isDone ? 'ADDED' : 'ADD')}}
        </button>
      </div>

    </a>
  </ng-container>
</div>