import { Component, Inject, Input, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sh-profile-delete-model',
  standalone: true,
  imports: [CommonModule, MatIconModule,
    MatButtonModule,],
  templateUrl: './sh-profile-delete-model.component.html',
  styleUrls: ['./sh-profile-delete-model.component.scss']
})
export class ShProfileDeleteModelComponent {
  public subject: Subject<boolean>;

  @Input() content?: string;

  constructor(
    public dialogRef: MatDialogRef<ShProfileDeleteModelComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public title: any,
  ) { }

  cancel(): void {
    if (this.subject) {
      this.subject.next(true);
      this.subject.complete();
    }
    this.dialogRef.close();
    // this.dialog.closeAll()
  }

  confirm() {
    if (this.subject) {
      this.subject.next(false);
      this.subject.complete();
    }
    this.dialogRef.close(true);
    // this.dialog.closeAll()
  }
}
