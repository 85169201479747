import { Component, Inject, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ShInputTextComponent } from 'src/app/components/shared_components/sh-input-text/sh-input-text.component';
import { ShSelectDropdownComponent } from 'src/app/components/shared_components/sh-select-dropdown/sh-select-dropdown.component';
import { ShTextareaBoxComponent } from 'src/app/components/shared_components/sh-textarea-box/sh-textarea-box.component';
import { ShCustomSelectComponent } from 'src/app/components/shared_components/sh-ng-select/sh-ng-select.component';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { closeDialog, myProfileItems, profileMyItems } from 'src/app/containers/profile/util';
import { dbConstants, dynamicElementTypes } from 'src/app/containers/onboarding/utils';
import { capitalizeFirstLetter, COLLECTION_NAMES, logoStoragePath, urlRegex } from 'src/app/constants/constants';
import { catchError, elementAt, finalize, map, of, Subject, take, takeUntil, tap } from 'rxjs';
import { datasetDbo } from 'src/app/schema/dataset.schema';
import { ShCommonUploadComponent } from 'src/app/components/shared_components/sh-common-upload/sh-common-upload.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { postDbo } from 'src/app/schema/post.schema';
import { paperDBO } from 'src/app/schema/paper.schema';
import { ShCalendarBoxFilterComponent } from 'src/app/components/common/sh-calendar.component/sh-calendar.component';
import { eventDbo } from 'src/app/schema/event.schema';
import { conferenceDbo } from 'src/app/schema/conference.schema';
import { grantsDbo } from 'src/app/schema/grants.schema';
import { isConference, isDataSet, isEvent, isGrants, isJobs, isPapers, isPosts } from './util';
import { jobsDbo } from 'src/app/schema/jobs.schema';
import { ShCheckBoxComponent } from 'src/app/components/shared_components/sh-check-box/sh-check-box.component';
import { SharedService } from 'src/app/services/shared.service';
import * as EventEmitter from 'events';

@Component({
  selector: 'app-sh-add-datasets-model',
  standalone: true,
  imports: [CommonModule, MatIconModule,
    MatButtonModule,
    ShInputTextComponent,
    ShTextareaBoxComponent,
    ShCustomSelectComponent,
    ShSpinnerLoaderComponent,
    ShCalendarBoxFilterComponent,
    ShCheckBoxComponent,
    ShCommonUploadComponent],
  templateUrl: './sh-add-my-items-model.component.html',
  styleUrls: ['./sh-add-my-items-model.component.scss']
})
export class ShAddMyItemsModelComponent {

  postAdded = new EventEmitter();
  destroyed$ = new Subject();
  pageLayout = [];
  form: FormGroup;
  dynamicElementTypes = dynamicElementTypes;
  lastDocIndex: any;
  collection: string;
  myItem: string;
  uploadingData: boolean;
  profile: any;
  value: any;
  entity: any;
  imageFile: any;
  downloadURL: any;
  countryAndCities: Object;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShAddMyItemsModelComponent>,
    @Optional() public dialogRef: MatDialogRef<ShAddMyItemsModelComponent>,
    private formBuilder: FormBuilder,
    private fbService: FirebaseCollectionService,
    private _http: HttpClient,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage,
    private imageUploadService: ImageUploadService,
    private sharedService: SharedService,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData?: any,

  ) {


    this.profile = "";
    this.profile = (data?.profile || bottomSheetData?.profile);
    this.value = (data?.data || bottomSheetData?.data);
    this.entity = (data?.entity || bottomSheetData?.entity);
  }

  ngOnInit() {
    this.buildForm();
    this.getLastDocumentIndex();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.resetLayout();
  }

  resetLayout() {
    this.pageLayout.forEach(element => {
      if (element) {
        element['selected'] = null;
      }
    });
  }

  buildForm(data?: any) {

    if (isDataSet(this.entity)) {
      this.pageLayout = [...profileMyItems.datasets];
    } else if (isPapers(this.entity)) {
      this.pageLayout = [...profileMyItems.papers];
    } else if (isPosts(this.entity)) {
      this.pageLayout = [...profileMyItems.posts];
      if (this.profile.InstituteIds?.length) {
        this.pageLayout.push(
          {
            type: dynamicElementTypes.multiSelect,
            title: "Add this post on behalf of the institutes",
            placeHolder: "Select institutes",
            attribute: "instituteIds",
            customFSData: true,
            loadResearcherInstitutes: true,
            data: []
          }
        );
      }
    } else if (isEvent(this.entity)) {
      this.pageLayout = this.pageLayout?.length ? [...this.pageLayout] : [...profileMyItems.events.inPerson];
    } else if (isConference(this.entity)) {
      this.pageLayout = [...profileMyItems.conference];
    } else if (isGrants(this.entity)) {
      this.pageLayout = [...profileMyItems.grants];
    } else if (isJobs(this.entity)) {
      this.pageLayout = [...profileMyItems.jobs];
    }

    const group = {};
    this.pageLayout.forEach((element: any) => {
      const validators = element?.required ? [Validators.required] : [];
      if (this.value) {
        if (element?.attribute === 'link' || element?.type === dynamicElementTypes.url) {
          group[element?.attribute] = new FormControl(this.value[element?.attribute], [Validators.pattern(urlRegex)]);
        } else {
          if (isEvent(this.entity) && element.attribute === 'mode') {
            group[element?.attribute] = new FormControl(element?.mode || this.value['mode'], validators);
          } else {
            if (element?.attribute === 'researchers') {
              group[element?.attribute] = new FormControl((this.value?.authorIds || []) || null, validators);
            } else {
              group[element?.attribute] = new FormControl(this.value[element?.attribute] || null, validators);
            }
          }
        }
      } else {
        if (element?.attribute === 'link' || element?.type === dynamicElementTypes.url) {
          group[element?.attribute] = new FormControl('', [Validators.pattern(urlRegex)]);
        } else {
          if (isEvent(this.entity) && element.attribute === 'mode') {
            element.selected = data?.mode || 'in-person';
            group[element?.attribute] = new FormControl(element.selected, validators);
          } else {
            if (element?.isNumber) {
              group[element?.attribute] = new FormControl('', [Validators.pattern("^[0-9]*$")]);
            } else {
              group[element?.attribute] = new FormControl('', validators);
            }
          }
        }
      }

      if (element?.type === dynamicElementTypes.select || element?.type === dynamicElementTypes.multiSelect) {
        this.loadData(element);

        if (this.value) {
          element.selected = this.value[element.attribute];
          if (element.attribute === 'journal') {
            element.selected = this.value['journalId'];
          } else if (element?.attribute === 'researchers') {
            // element.selected = this.value['authorIds'];
            this.getResearchers(element, this.value['authorIds'])
          }

          if (element.isCustomChips) {
            const arr = [];
            this.value[element.attribute].forEach(value => {
              arr.push({
                id: value, name: value
              })
            });
            element.data = arr;
            element.filterData = arr;
          }
        }
      }
    });

    this.form = this.formBuilder.group(group, { validators: this.dateValidator });
    // console.log(this.pageLayout);
    this.loadDependantData();
  }

  getResearchers(element, authorIds) {
    this.fbService
      .getDocumentsById(COLLECTION_NAMES.researchers, authorIds)
      .pipe(
        takeUntil(this.destroyed$),
        map((collectionData: any[]) => {
          // console.log(collectionData);
          const researchersArr = [];
          for (const docData of collectionData) {
            const data = { ...docData.payload.data() as any, id: docData.payload.id };
            researchersArr.push(data);
          }
          // this.researchersArr = researchersArr;
          element.data = researchersArr;
          element.filterData = researchersArr;
          element.selected = authorIds;
          console.log(element);
        })
      )
      .subscribe();
  }

  dateValidator(control: AbstractControl) {
    const start = control.get('startDate');
    const end = control.get('endDate');

    let ret = null;
    if (
      start?.value !== null && end?.value !== null &&
      start?.value !== undefined && end?.value !== undefined &&
      start?.value !== '' && end?.value !== '') {
      ret = new Date(start?.value) > new Date(end?.value)
        ? { invalidDate: true } : null;
    }

    return ret;
  }

  loadDependantData() {
    this.cityUpdateOnCountry();
    this.eventModeChange();
  }

  cityUpdateOnCountry() {
    const element = this.pageLayout.find(element => element?.attribute === 'country');
    if (element?.loadCityByCountry) {
      if (this.value && !!this.form.get('country').value) {
        const country = { name: capitalizeFirstLetter(this.value?.country) };
        this.loadCities(country);
      } else {
        this.form.get('country').valueChanges.pipe(
          takeUntil(this.destroyed$),
          map(formData => {
            const country = this.form.get('country').value;
            this.loadCities(country);
          })
        ).subscribe();
      }
    }
  }

  eventModeChange() {
    const element = this.pageLayout.find(element => element?.attribute === 'mode');
    if (element?.dynamicLayoutUpdate) {
      this.form.get('mode').valueChanges.pipe(
        takeUntil(this.destroyed$),
        map(formData => {
          const mode = (this.form.get('mode')?.value?.id || this.form.get('mode')?.value)?.toLowerCase();
          if (mode === 'virtual') {
            this.pageLayout = profileMyItems.events.virtual;
            this.buildForm({ mode: 'virtual' });
          } else {
            this.pageLayout = profileMyItems.events.inPerson;
            this.buildForm({ mode: 'in-person' });
          }
        })
      ).subscribe();
    }
  }

  loadCities(country) {
    const countryName = country?.name;
    if (!this.countryAndCities) {
      this.sharedService.getCities().pipe(
        take(1),
        map(data => {
          this.countryAndCities = data;
          const element = this.pageLayout.find(element => element?.attribute === 'city');
          element.data = this.countryAndCities[countryName];
          element.filterData = this.countryAndCities[countryName].splice(0, 25);
        })
      ).subscribe();
    } else {
      const element = this.pageLayout.find(element => element?.attribute === 'city');
      element.data = this.countryAndCities[countryName];
      element.filterData = this.countryAndCities[countryName].splice(0, 25);
    }
  }

  getLastDocumentIndex() {
    const { collection, myItem } = this.getCollectionNameAndConstant();
    this.collection = collection;
    this.myItem = myItem;

    if (this.collection) {
      this.fbService.queryCollectionSnapshot(this.collection, [], [{ field: 'index', condition: 'desc' }], 1)
        .pipe(
          take(1),
          map(lastDoc => {
            this.lastDocIndex = lastDoc?.length ? lastDoc[0].index : 1;
          })
        ).subscribe();
    }
  }

  getCollectionNameAndConstant() {
    let collection = null;
    let myItem = null;
    if (isDataSet(this.entity)) {
      collection = COLLECTION_NAMES.datasets;
      myItem = 'Dataset';
    } else if (isPapers(this.entity)) {
      collection = COLLECTION_NAMES.publications;
      myItem = 'Paper';
    } else if (isPosts(this.entity)) {
      collection = COLLECTION_NAMES.posts;
      myItem = 'Post';
    } else if (isEvent(this.entity)) {
      collection = COLLECTION_NAMES.events;
      myItem = 'Events';
    } else if (isConference(this.entity)) {
      collection = COLLECTION_NAMES.conferences;
      myItem = 'Conference';
    } else if (isGrants(this.entity)) {
      collection = COLLECTION_NAMES.grants;
      myItem = 'Grant';
    } else if (isJobs(this.entity)) {
      collection = COLLECTION_NAMES.jobs;
      myItem = 'Jobs';
    }

    return {
      collection, myItem
    };
  }

  loadData(element) {
    if (element?.customFSData) {
      this.loadCustomFirestoreData(element);
    } else {
      let journal: any = null;
      if (element.attribute === 'journal' && this.value) {
        journal = {
          id: this.value?.journalId,
          name: this.value?.journal
        };
      }
      const elementInfo = dbConstants[element.attribute || element.constant];
      if (!elementInfo?.loadFromDB && !elementInfo?.loadAsset) {
        // Hard coded temp data
        element.data = element?.data;
        element.filterData = element?.data;
      } else if (elementInfo?.isThemes) {
        this.fetchThemes(element);
      } else if (elementInfo?.loadAsset) {
        let obs$ = null;
        const constant = elementInfo?.constant;
        if (constant === 'countries') {
          obs$ = this.sharedService.getCountries();
        } else if (constant === 'skills') {
          obs$ = this.sharedService.getSkills();
        }

        obs$.pipe(
          map(data => {
            const firebaseData = data[constant];
            element.data = [...firebaseData];
            element.filterData = (constant === 'skills') ? [...firebaseData].splice(0, 50) : [...firebaseData];
          })
        ).subscribe();
      } else {
        if (!element?.isLocalData) {
          this.subscribeDataFromFirestore(element, null, journal);
        } else {
          // Hard coded temp data
          element.data = element?.data;
          element.filterData = element?.data;
        }
      }
    }

  }

  fetchThemes(element) {
    let collection = COLLECTION_NAMES.themes;

    if (isDataSet(this.entity)) {
      collection = COLLECTION_NAMES.dataSetThemes;
    } else if (isPapers(this.entity)) {
      collection = COLLECTION_NAMES.themes;
    }
    element.selected = [];
    this.fbService
      .queryCollectionSnapshot(collection)
      .pipe(
        take(1),
        map((themes) => {
          let subThemes = [];
          themes.forEach((theme, index) => {
            theme?.subThemes.forEach(subTheme => {
              subTheme['themeId'] = theme.id;
            });
            subThemes = [...subThemes, ...theme?.subThemes];
          });

          element.data = subThemes;
          element.filterData = subThemes;
          // element.selected = subThemes.filter(subTheme => this.profile?.subThemeIds?.includes(subTheme.id));
        }),
        catchError(err => {
          return of(err);
        })
      )
      .subscribe();
  }

  loadCustomFirestoreData(element) {
    if (element?.loadResearcherInstitutes) {
      this.loadInstitutes(element);
    }
  }

  loadInstitutes(element) {
    element.data = [];
    element.filterData = [];
    const institutes = [];
    for (let index = 0; index < this.profile?.InstituteIds?.length; index++) {
      const instituteId = this.profile?.InstituteIds[index];
      this.profile?.InstituteIds.forEach(instituteId => {
        this.fbService
          .getDocument(
            COLLECTION_NAMES.institutes,
            instituteId
          ).pipe(
            take(1),
            map(doc => {
              if (!institutes.find(institute => institute.id === doc.payload.id)) {
                institutes.push({ ...doc.payload.data() as object, id: doc.payload.id });
                element.data = [...institutes];
                element.filterData = [...institutes];
              }
            })
          ).subscribe();
      });
    }
  }

  onSearch(data) {
    const { term, element } = data;
    element.filterData = [];
    if (element.showSearch) {
      const isInternalSearch = dbConstants[element.attribute]?.isInternalSearch || null;
      if (isInternalSearch) {
        if (term || term === "") {
          const elementData = [...element?.data || []]
          element.filterData = [...elementData?.filter(dd => (dd.name || dd)?.toString()?.toLowerCase().includes(term?.toString()?.toLowerCase()))].splice(0, 10);
        }
      } else {
        element.loading = true;
        if (term) {
          const filter = [this.fbService.getSearchQueryFilter(term)];
          this.subscribeDataFromFirestore(element, filter);
        } else {
          this.subscribeDataFromFirestore(element);
        }
      }
    }
  }

  subscribeDataFromFirestore(element, filter?, customData?, collectionName?) {
    let fld = 'name';
    if (element.attribute === 'journal') {
      fld = "journalName";
    } else if (element.attribute === 'researchers') {
      fld = "firstName";
    }

    this.fbService
      .queryCollectionSnapshot(
        collectionName || dbConstants[element.attribute]?.db,
        filter || [],
        [{ field: fld, condition: 'asc' }],
        25
      ).pipe(
        // takeUntil(this.destroyed$),
        take(1),
        map(data => {
          element.loading = false;
          const firebaseData = [...data];
          if (customData) {
            firebaseData.push(customData);
          }
          element.data = firebaseData;
          element.filterData = firebaseData;
        }),
        catchError(e => {
          return of(e);
        })
      ).subscribe();
  }

  cancelClick(value): void {
    if (value === 'model' && this.dialogRef) {
      this.dialogRef.close();  // Close dialog if it exists
    }
    if (value === '' && this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();  // Close bottom sheet if it exists
    }
  }

  create() {
    this.uploadingData = true;
    const formData = this.form?.value;
    formData['index'] = this.lastDocIndex + 1;

    if (isDataSet(this.entity)) {
      this.updateDataset({ ...formData });
    } else if (isPosts(this.entity)) {
      this.uploadImageAndContinue({ ...formData }, { isPost: true });
    } else if (isPapers(this.entity)) {
      this.updatePapers({ ...formData });
    } else if (isEvent(this.entity)) {
      this.uploadImageAndContinue({ ...formData }, { isEvent: true });
    } else if (isConference(this.entity)) {
      this.uploadImageAndContinue({ ...formData }, { isConference: true });
    } else if (isGrants(this.entity)) {
      this.updateGrants({ ...formData });
    } else if (isJobs(this.entity)) {
      this.uploadImageAndContinue({ ...formData }, { isJobs: true });
    }

    // this.dialogRef.close('SUCCESS');
  }

  // DATASET
  updateDataset(formData) {
    const payload = datasetDbo(formData, this.value || {}, this.profile);
    this.createNew(payload);
  }

  // JOBS
  updateJobs(formData) {
    const payload = jobsDbo(formData, this.value || {}, this.profile);
    this.createNew(payload);
  }

  // GRANTS
  updateGrants(formData) {
    const payload = grantsDbo(formData, this.value || {}, this.profile);
    this.createNew(payload);
  }

  // PAPERS
  updatePapers(payload) {
    payload['author'] = this.profile?.name ? this.profile?.name : this.profile?.firstName + ' ' + this.profile?.lastName;
    payload['authorId'] = this.profile.id;
    const data = paperDBO(payload);
    this.createNew(data);
  }

  // POST
  addPosts(payload) {
    payload['author'] = this.profile?.name ? this.profile?.name : this.profile?.firstName + ' ' + this.profile?.lastName;
    if (this.profile.entityId) {
      payload['authorId'] = this.profile.entityId;
    } else {
      payload['authorId'] = this.profile.id;
    }

    const data = postDbo(payload, this.value);
    this.createNew(data);
    this.dialogRef.close('SUCCESS');
  }

  addEvent(payload) {
    payload['author'] = this.profile?.name ? this.profile?.name : this.profile?.firstName + ' ' + this.profile?.lastName;
    payload['authorId'] = this.profile.id;
    const data = eventDbo(payload, this.value);
    this.createNew(data);
  }

  addConference(payload) {
    payload['author'] = this.profile?.name ? this.profile?.name : this.profile?.firstName + ' ' + this.profile?.lastName;
    payload['authorId'] = this.profile.id;
    const data = conferenceDbo(payload, this.value);
    this.createNew(data);
  }

  uploadImageAndContinue(formData, moduleEntity) {
    const { isPost, isEvent, isConference, isJobs } = moduleEntity;
    if (this.imageFile) {
      this.uploadImageToStorage(this.imageFile, formData, moduleEntity);
    } else {
      if (isPost) {
        this.addPosts(formData);
      } else if (isEvent) {
        this.addEvent(formData);
      } else if (isConference) {
        this.addConference(formData);
      } else if (isJobs) {
        this.updateJobs({ ...formData });
      }
    }
  }

  uploadFile(file) {
    this.imageFile = file;
    if (!file) {
      if (this.form?.get('logo')) {
        this.form?.get('logo').setValue(null);
      }

      if (this.form?.get('image')) {
        this.form?.get('image').setValue(null);
      }
    }
  }

  uploadImageToStorage(file: any, formData, moduleEntity?) {
    const { isPost, isEvent, isConference, isJobs } = moduleEntity;
    var now = Date.now();
    const filePath = `${isPost ? logoStoragePath.posts : logoStoragePath.images}/${now}`;
    const fileRef = this.storage.ref(filePath);
    return this.imageUploadService.uploadImage(file, filePath)
      .pipe(
        finalize(() => {
          try {
            const downloadURL = fileRef.getDownloadURL();
            downloadURL.subscribe(url => {
              if (url) {

                if (isPost) {
                  formData.image = url;
                  this.addPosts(formData);
                } else if (isEvent) {
                  formData.logo = url;
                  this.addEvent(formData);
                } else if (isConference) {
                  formData.logo = url;
                  this.addConference(formData);
                } else if (isJobs) {
                  formData.logo = url;
                  this.updateJobs(formData);
                }
              }
            });
          } catch (error) {
          }
        }),
        catchError(e => e)
      ).subscribe();
  }
  // END OF POST

  createNew(payload) {
    let obs$ = null;
    let message = "";
    const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

    if (!this.value) {
      obs$ = this.fbService.addDocument(this.collection, payload);
      message = `"${capitalize(payload?.name || payload?.title)}" added successfully!`;
      this.close();
    } else {
      obs$ = this.fbService.updateDocument(this.collection, this.value.id, payload);
      message = `"${capitalize(payload?.name || payload?.title)}" updated successfully!`;
      this.close();
    }

    obs$.pipe(
      map(res => {
        this.snackBar.open(message, 'OK');
        closeDialog(this.dialogRef, this.bottomSheetData);
        this.uploadingData = false;
      }),
      catchError(e => {
        this.uploadingData = false;
        return of(e)
      })
    ).subscribe();
  }

  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();
    }
  }
}
