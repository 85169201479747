import { Component, Inject, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ShTextareaBoxComponent } from 'src/app/components/shared_components/sh-textarea-box/sh-textarea-box.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { isResearcher } from 'src/app/utils/utils';
import { COLLECTION_NAMES } from 'src/app/constants/constants';
import { catchError, map, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';
import { closeDialog } from '../util';

@Component({
  selector: 'app-sh-about-add-profile-model',
  standalone: true,
  imports: [CommonModule, ShTextareaBoxComponent, MatIconModule, MatButtonModule, FormsModule,
    ReactiveFormsModule, ShSpinnerLoaderComponent],
  templateUrl: './sh-about-add-profile-model.component.html',
  styleUrls: ['./sh-about-add-profile-model.component.scss']
})
export class ShAboutAddProfileModelComponent {
  aboutmeControl: FormControl;
  profile: any;
  currentUser: any;
  uploadingData: boolean;

  constructor(
    private fbService: FirebaseCollectionService,
    private snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<ShAboutAddProfileModelComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShAboutAddProfileModelComponent>,  // Inject MatBottomSheetRef if bottom sheet is used
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: { profile: any, user: any },  // Inject dialog data
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: { profile: any, user: any }  // Inject bottom sheet data
  ) {
    this.profile = this.dialogData ? this.dialogData.profile : this.bottomSheetData?.profile;
    this.currentUser = this.dialogData ? this.dialogData.user : this.bottomSheetData?.user;

    // Initialize the FormControl with the passed aboutme data
    this.aboutmeControl = new FormControl(this.profile.description);
  }

  cancelClick(value): void {
    if (value === 'model' && this.dialogRef) {
      this.dialogRef.close();  // Close dialog if it exists
    }
    if (value === '' && this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();  // Close bottom sheet if it exists
    }
  }

  saveAboutMe(): void {
    this.uploadingData = true;
    const updatedAboutMe = this.aboutmeControl.value;

    const payload = { description: updatedAboutMe };
    this.updateData(payload);
  }

  updateData(payload) {
    const collectionName = this.getCollectionInfoRespectiveToTheSpecialization();
    this.fbService.updateDocument(collectionName, this.profile.id, payload).pipe(
      map(res => {
        this.snackBar.open('Updated successfully!', 'OK');
        closeDialog(this.dialogRef, this.bottomSheetRef);
        this.uploadingData = false;
      }),
      catchError(e => {
        this.uploadingData = false;
        return of(e);
      })
    ).subscribe();
  }

  getCollectionInfoRespectiveToTheSpecialization() {
    let collection = "";
    if (isResearcher(this.currentUser)) {
      collection = COLLECTION_NAMES.researchers;
    } else {
      collection = COLLECTION_NAMES.institutes;
    }
    return collection;
  }
}
