<div [class.dark-theme]="true" class="flex flex-col p-6 overflow-hidden">
    <div class="flex w-full justify-between">
        <span class="text-lg md:text-2xl text-darkBlack font-bold">Edit About</span>

        <mat-icon (click)="cancelClick('model')"
            class="text-[#959DA5] text-lg cursor-pointer !hidden md:!block">close</mat-icon>
        <mat-icon (click)="cancelClick('')"
            class="text-[#959DA5] text-lg cursor-pointer md:!hidden !block">close</mat-icon>
    </div>

    <section class="w-full flex flex-col mt-4">
        <app-sh-textarea-box [label]="''" [rows]="6" [placeholder]="'About Me'"
            [control]="aboutmeControl"></app-sh-textarea-box>

        <div class="flex justify-end mt-4">
            <button mat-raised-button color="primary" class="w-24" (click)="saveAboutMe()" [disabled]="uploadingData">
                <app-sh-spinner-loader *ngIf="uploadingData" [colorCode]="'#fff'" [roundWidth]=20
                    [thinWidth]=2></app-sh-spinner-loader>
                {{uploadingData ? '' : 'SAVE'}}
            </button>
        </div>
    </section>

</div>