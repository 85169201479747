import { inject, Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateFn,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, map, Observable, of, switchMap, take } from 'rxjs';
import { ALLOWED_DOMAIN, APP_URL } from '../constants/constants';

@Injectable({
    providedIn: 'root',
})
export class UserGuardService implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private afa: AngularFireAuth) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const emailVerificationReleaseDate = '2024-12-16T20:00';
        return this.afa.authState.pipe(
            take(1),
            switchMap(auth => {
                if (!!auth) {
                    return this.auth.getUser().pipe(
                        take(1),
                        switchMap((user: any) => {
                            const domain = user?.email?.split('@')[1];
                            if (auth?.emailVerified ||
                                (ALLOWED_DOMAIN.includes(domain)) ||
                                (!user?.created) ||
                                (user?.created &&
                                    new Date(user?.created) < new Date(emailVerificationReleaseDate))) {
                                return of(true);
                            } else {
                                this.router.navigate(['/' + APP_URL.verifyEmail]);
                                return of(false);
                            }
                        })
                    );
                } else {
                    return of(true);
                }
            })
        );
    }
}

export const OnBoardingGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(UserGuardService).canActivate(next, state);
}