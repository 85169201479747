<div class="lg:p-0 lg:px-4 rounded-xl w-full overflow-x-auto scroll-thin bg-white">
  <div class="p-3 flex gap-x-2 w-[fit-content]">
    <!-- Loop through the tabs array -->
    <div *ngFor="let tab of tabs; let i = index" (click)="selectTab(i, tab.label)"
      class="h-12 w-32 cursor-pointer text-base flex justify-center items-center rounded-lg" [ngClass]="{
          'bg-[#D6ECFF] text-primary': selectedTab === i,
          'hover:bg-[#D6ECFF] hover:text-primary text-[#959DA5]': selectedTab !== i
        }">
      <span>{{ tab.label }}</span>
    </div>
  </div>
</div>


<!-- Tab Content -->
<ng-container *ngIf="tabs[selectedTab]?.component">
  <div class="flex w-full mt-4">
    <!-- Add a div wrapper with w-full class to apply width -->
    <div class="w-full">
      <ng-container *ngComponentOutlet="tabs[selectedTab].component; injector: dynamicComponentInjector"></ng-container>
    </div>
  </div>
</ng-container>