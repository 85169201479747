import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { map, Observable, Subject, take, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DataPassService } from 'src/app/services/datapass.service';
import { ShModifyMembersComponent } from './sh-modify-members/sh-modify-members.component';
import { ShMembersListComponent } from './sh-members-list/sh-members-list.component';
import { User } from 'src/app/models/userInfo.model';
import { AuthenticationService } from 'src/app/services/auth.service';
import { COLLECTION_NAMES, PAGINATION_PER_PAGE_DATA } from 'src/app/constants/constants';
import { FilterType, FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { ShSpinnerLoaderComponent } from '../sh-spinner-loader/sh-spinner-loader.component';
import { ShLoadMoreComponent } from '../sh-load-more/sh-load-more.component';
import { PROFILE } from 'src/app/containers/profile/sh-tab-profile/sh-tab-profile.component';

@Component({
  selector: 'app-sh-teams',
  standalone: true,
  imports: [CommonModule, MatButtonModule, ShMembersListComponent, ShSpinnerLoaderComponent, ShLoadMoreComponent],
  templateUrl: './sh-teams.component.html',
  styleUrls: ['./sh-teams.component.scss']
})
export class ShTeamsComponent {

  PAGINATION_COUNT = 4;
  destroy$ = new Subject();
  researchers = [];
  userAuthUser$: Observable<User> = this.authService.getUser();
  entityId: any;
  loading: boolean;
  startAfter: any;
  loadMore: boolean;
  profile: any;

  constructor(
    @Inject(PROFILE) private profileInjected: any,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private _bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private fbService: FirebaseCollectionService
  ) {
    this.profile = { ...this.profileInjected.profile };
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getCurrentUser() {
    this.userAuthUser$.pipe(
      takeUntil(this.destroy$),
      map((user: any) => {
        this.entityId = user?.entityId;
        this.getResearchers();
      })
    ).subscribe();
  }

  getResearchers(loadMore?) {
    this.loading = true;
    const filter: FilterType[] = [{
      field: 'InstituteIds',
      comparator: 'array-contains',
      value: this.entityId,

    }];

    return this.fbService
      .queryCollectionSnapshot(
        COLLECTION_NAMES.researchers,
        filter,
        [{ field: 'index', condition: 'asc' }],
        this.PAGINATION_COUNT,
        null,
        this.startAfter,
        true
      )
      .pipe(
        takeUntil(this.destroy$),
        map((collectionData) => {
          this.loading = false;
          this.loadMore = false;
          this.startAfter = collectionData[collectionData?.length - 1]?.ref || null;
          if (collectionData?.length >= this.PAGINATION_COUNT) {
            this.loadMore = true;
          }
          let researchers = this.researchers;
          if (!loadMore) {
            researchers = [];
          }
          collectionData.forEach(researcher => {
            if (Array.isArray(researcher.InstituteIds) && researcher.InstituteIds?.includes(this.entityId)) {
              if (!researchers?.find(res => res.id === researcher.id)) {
                researchers.push(researcher);
              }
            }
          });
          this.researchers = researchers;
        })
      ).subscribe();
  }



  openAddMebers(isAdd): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShModifyMembersComponent, {
          data: {
            isAdd,
            entityId: this.entityId,
            institute: this.profile
          }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialog.open(ShModifyMembersComponent, {
          width: '450px',
          panelClass: 'profile-model',
          data: {
            isAdd,
            entityId: this.entityId,
            institute: this.profile
          }
        });
      }
    });
  }
}
