import { Component, InjectionToken, Injector, Input, ReflectiveInjector } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ShAboutProfileComponent } from '../sh-about-profile/sh-about-profile.component';
import { ShMyitemsProfileComponent } from '../sh-myitems-profile/sh-myitems-profile.component';
import { ShMyNetworksComponent } from 'src/app/components/sh-my-networks/sh-my-networks.component';
import { DataPassService } from 'src/app/services/datapass.service';
import { entityConstants } from '../../onboarding/utils';
import { isResearcher } from 'src/app/utils/utils';
import { ShTeamsComponent } from 'src/app/components/common/sh-teams/sh-teams.component';

export const PROFILE = new InjectionToken<string>('profile', { providedIn: 'root', factory: () => 'profile' });

@Component({
  selector: 'app-sh-tab-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sh-tab-profile.component.html',
  styleUrls: ['./sh-tab-profile.component.scss']
})
export class ShTabProfileComponent {
  dynamicComponentInjector: any;
  tabs: ({ label: string; component: typeof ShAboutProfileComponent; } | { label: string; component: typeof ShMyitemsProfileComponent; } | { label: string; component: typeof ShMyNetworksComponent; } | { label: string; component: typeof ShTeamsComponent; })[];

  @Input() set profile(data) {
    this.dynamicComponentInjector = ReflectiveInjector.resolveAndCreate([{ provide: PROFILE, useValue: { ...data } }], this.parentInjector);
    const { user } = data;
    if (isResearcher(user)) {
      // Define your tabs with label and dynamic component
      this.tabs = [
        { label: 'Profile', component: ShAboutProfileComponent },
        { label: 'My Items', component: ShMyitemsProfileComponent },
        { label: 'My Network', component: ShMyNetworksComponent },
        { label: 'Saved Items', component: ShMyitemsProfileComponent },
      ];
    } else {
      // Define your tabs with label and dynamic component
      this.tabs = [
        { label: 'Page Info', component: ShAboutProfileComponent },
        { label: 'Shared Items', component: ShMyitemsProfileComponent },
        { label: 'Teams', component: ShTeamsComponent },
      ];
    }
  }


  selectedTab = 0; // Default selected tab

  constructor(private parentInjector: Injector, private dataService: DataPassService) { }

  // Function to handle tab selection
  selectTab(index: number, label): void {
    this.selectedTab = index;
    this.dataService.changeData.next(label);
  }
}
