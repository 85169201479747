<div [class.dark-theme]="true" class="flex flex-col p-6 overflow-hidden">
  <div class="flex w-full justify-between">
    <span class="text-lg md:text-2xl text-darkBlack font-bold" *ngIf="!title?.title">Delete {{title.entity}}</span>
    <span class="text-lg md:text-2xl text-darkBlack font-bold" *ngIf="title?.title">{{title?.title}}</span>

    <mat-icon (click)="cancel()" class="text-[#959DA5] text-lg cursor-pointer">close</mat-icon>
  </div>

  <!-- <div>{{title | json}}</div> -->

  <div class="flex flex-col  my-4 text-[#24292E]">
    {{title?.content || 'Are you sure you want to delete this item? This action cannot be undone.'}}
  </div>


  <div class="flex gap-x-4 mt-4">
    <button mat-stroked-button class="w-1/2 !text-primary !bg-white !rounded-lg" (click)="cancel()">

      {{title?.cancelBtnTxt || 'CANCEL'}}
    </button>
    <button mat-stroked-button
      class="w-1/2 !bg-[#FFE3E6] flex items-center !rounded-lg !text-[#B60A1C] border !border-[#FFE3E6]"
      (click)="confirm()">
      <span class="pr-2" *ngIf="!title?.hideDeleteIcon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.1461 3.79964H17.3128V5.46631H15.6461V16.2996C15.6461 16.7599 15.273 17.133 14.8128 17.133H3.14612C2.68588 17.133 2.31278 16.7599 2.31278 16.2996V5.46631H0.646118V3.79964H4.81279V1.29964C4.81279 0.839409 5.18588 0.466309 5.64612 0.466309H12.3128C12.773 0.466309 13.1461 0.839409 13.1461 1.29964V3.79964ZM13.9795 5.46631H3.97945V15.4663H13.9795V5.46631ZM6.47945 7.96631H8.14612V12.9663H6.47945V7.96631ZM9.81279 7.96631H11.4795V12.9663H9.81279V7.96631ZM6.47945 2.13298V3.79964H11.4795V2.13298H6.47945Z"
            fill="#B60A1C" />
        </svg>

      </span>
      {{ title?.confirmBtnTxt || 'DELETE'}}
    </button>
  </div>

</div>