import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-sh-load-more',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './sh-load-more.component.html',
  styleUrls: ['./sh-load-more.component.scss']
})
export class ShLoadMoreComponent {

  @Output() loadMore = new EventEmitter();

  onclickLoadMore() {
    this.loadMore.emit();
  }

}
