import { Component, Inject, InjectionToken, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ShAboutAddProfileModelComponent } from '../sh-about-add-profile-model/sh-about-add-profile-model.component';
import { ShEducationAddProfileModelComponent } from '../sh-education-add-profile-model/sh-education-add-profile-model.component';
import { PROFILE } from '../sh-tab-profile/sh-tab-profile.component';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { catchError, finalize, map, of, Subject, take, takeUntil } from 'rxjs';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShAffiliationAddProfileModelComponent } from '../sh-affiliation-add-profile-model/sh-affiliation-add-profile-model.component';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth.service';
import { SortByPipe } from 'src/app/pipes/sortBy.pipe';
import { COLLECTION_NAMES, dateFormat, logoStoragePath } from 'src/app/constants/constants';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { isResearcher } from 'src/app/utils/utils';
import { format } from 'src/app/utils/date-service.utils';
import { HttpClient } from '@angular/common/http';
import { ShProfileDeleteModelComponent } from '../sh-profile-delete-model/sh-profile-delete-model.component';

@Component({
  selector: 'app-sh-about-profile',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatMenuModule,
    ShSpinnerLoaderComponent, CommonModule, MatIconModule, MatMenuModule,
    MatDialogModule, MatBottomSheetModule, ShProfileDeleteModelComponent,
    ShAffiliationAddProfileModelComponent, SortByPipe],
  templateUrl: './sh-about-profile.component.html',
  styleUrls: ['./sh-about-profile.component.scss']
})
export class ShAboutProfileComponent implements OnDestroy {
  destroy$ = new Subject();
  @Input() profile;
  dateFormat = dateFormat;
  profileData: any;
  loadingAffiliation: boolean;
  institutesObj: {};
  user: any;
  uploadingData: boolean;
  resume: any;
  resumeUrl: any;
  isResearcher: boolean;

  constructor(
    @Inject(PROFILE) private profileInjected: any,
    private authService: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private fbService: FirebaseCollectionService,
    private snackBar: MatSnackBar,
    private imageUploadService: ImageUploadService,
    private storage: AngularFireStorage,
    private http: HttpClient,
  ) {
    this.profileData = this.profile || { ...this.profileInjected.profile };
    this.user = this.profileInjected.user;
    this.getAffiliation();
    this.getCollectionInfoRespectiveToTheSpecialization();
  }


  getAffiliation() {
    if (this.profileData?.InstituteIds?.length) {
      this.loadingAffiliation = true;
      this.fbService.getAffiliation(this.profileData)
        .pipe(
          takeUntil(this.destroy$),
          map((institutesCollection: any) => {
            this.loadingAffiliation = false;
            const institutesObj = {};
            // console.log(institutesCollection);
            for (const doc of institutesCollection) {
              const data = doc.payload;
              institutesObj[data.id] = data.data();
            }
            // console.log(institutesObj);
            this.institutesObj = institutesObj;
          }),
          catchError(e => {
            this.loadingAffiliation = false;
            return of(e);
          })
        ).subscribe()
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openEditAboutModel(): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShAboutAddProfileModelComponent, {
          data: {
            profile: { ...this.profileData },
            user: this.user
          }
        });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialog.open(ShAboutAddProfileModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: {
            profile: { ...this.profileData },
            user: this.user
          }
        });
      }
    });
  }

  openEditEducationModel(): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShEducationAddProfileModelComponent, {
          // data: { aboutme: this.profile.bio }
          data: { profile: { ...this.profileData }, user: this.user }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialog.open(ShEducationAddProfileModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: { profile: { ...this.profileData }, user: this.user }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.profile.bio = result;
          }
        });
      }
    });
  }

  resetPassword() {
    const auth = getAuth();
    sendPasswordResetEmail(auth, this.profileData.email)
      .then(() => {
        this.snackBar.open(`Password reset link sent to ${this.profileData.email}`, 'OK');
      })
      .catch((error) => {
        const err = this.authService.generateAuthErrorMessage(error);
        this.snackBar.open(err, 'OK');
      });
  }

  openEditAffiliation(): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShAffiliationAddProfileModelComponent, {
          data: { profile: { ...this.profileData }, user: this.user }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialog.open(ShAffiliationAddProfileModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: { profile: { ...this.profileData }, user: this.user }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.profile.bio = result;
          }
        });
      }
    });
  }

  routeTo(path: string) {
    this.router.navigate([path]);

  }
  privacyRoute(event: MouseEvent): void {
    event.preventDefault();
    window.open('/privacy-policy', '_blank');
  }

  trackById(data) {
    return data;
  }

  deleteResume() {
    const payload = { resume: null };
    const message = 'Resume removed successfully!';
    this.updateData(payload, message);
  }

  confirmDelete() {
    const modalRef = this.dialog.open(ShProfileDeleteModelComponent, {
      width: '350px',
      //  height: '300px',
      panelClass: 'profile-model',
      autoFocus: false,
      data: {
        // title: "Confirm",
        // content: "Are you sure discarding the changes?",
        // confirmBtnTxt: "Abort editing",
        // cancelBtnTxt: "Keep editing",
        // hideDeleteIcon: true
      }
    });

    modalRef.afterClosed().pipe(
      take(1),
      map(confirmed => {
        if (confirmed) {
          this.deleteResume();
        }
      })
    ).subscribe();

  }


  updateResume() {
    document.getElementById('fileInputResume').click();
  }

  uploadResume(event: any) {
    const file: File = event.target.files[0];

    const isValidFormat = file.type === 'application/pdf';
    if (!isValidFormat) {
      this.snackBar.open('Please upload a valid pdf file.', 'OK');
      return false;
    }

    var now = Date.now();
    const filePath = `${logoStoragePath.resume}/${now + '-' + file?.name}`;
    const fileRef = this.storage.ref(filePath);
    this.uploadingData = true;
    return this.imageUploadService.uploadPdf(file, filePath)
      .pipe(
        finalize(() => {
          const downloadURL = fileRef.getDownloadURL();
          downloadURL.subscribe(url => {
            if (url) {
              const payload = { resume: { url, name: file?.name, uploadedDate: format(new Date()) } };
              const message = 'Resume uploaded successfully!';
              this.resumeUrl = url;
              this.updateData(payload, message);
            }
          });
        }),
        catchError(e => {
          this.uploadingData = false;
          return of(e)
        })
      )
      .subscribe();
  }


  updateData(payload, message) {
    const collectionName = this.getCollectionInfoRespectiveToTheSpecialization();
    this.fbService.updateDocument(collectionName, this.profileData.id, payload).pipe(
      map(res => {
        this.uploadingData = false;
        this.snackBar.open(message, 'OK');
      }),
      catchError(e => {
        this.uploadingData = false;
        return of(e);
      })
    ).subscribe();
  }

  // View the resume by opening the PDF in a new tab
  viewResume() {
    if (this.profileData?.resume?.url) {
      window.open(this.profileData.resume.url, '_blank');
    } else {
      this.snackBar.open('No resume available to view.', 'OK');
    }
  }

  downloadResume() {
    if (this.profileData?.resume?.url) {
      this.http.get(this.profileData.resume.url, { responseType: 'blob' }).subscribe((blob) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = this.profileData.resume.name || 'resume.pdf';
        link.click();
        // Clean up the object URL
        window.URL.revokeObjectURL(url);
      });
    } else {
      this.snackBar.open('No resume available to download.', 'OK');
    }
  }



  getCollectionInfoRespectiveToTheSpecialization() {
    let collection = "";
    this.isResearcher = false;
    if (isResearcher(this.user)) {
      this.isResearcher = true;
      collection = COLLECTION_NAMES.researchers;
    } else {
      collection = COLLECTION_NAMES.institutes;
    }
    return collection;
  }

}
