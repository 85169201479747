<div class="flex flex-col">
    <ng-container *ngIf="!(jobs$ | async)?.['length'] && !loading">
        <app-sh-empty-state [btnText]="emptyStateAddButtonLabel" (add)="edit()"></app-sh-empty-state>
    </ng-container>

    <div class="flex align-center justify-center">
        <app-sh-spinner-loader *ngIf="loading" [colorCode]="'#004481'" [roundWidth]="30"
            [thinWidth]="2"></app-sh-spinner-loader>
    </div>


    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <!-- Loop through the items and render them in the grid -->
        <div [routerLink]="['/jobs', job?.id]" *ngFor="let job of (jobs$ | async)"
            class="bg-white p-4 rounded-xl shadow-sm  gap-2">

            <div class="flex flex-col text-[#24292E]">
                <div class="flex justify-between items-center w-full">
                    <div class="flex gap-x-4">
                        <span class="text-sm text-darkBlack font-bold pt-2 normal-case">{{job?.name}}
                            <span class="text-xs text-darkBlack font-normal"
                                *ngIf="job?.jobSector != ''">&nbsp;•&nbsp;</span> <span
                                class="text-xs text-darkBlack font-normal">{{job?.jobSector}}</span>
                        </span>

                    </div>
                    <button [matMenuTriggerFor]="menu" (click)="onClickMenu($event)"
                        *ngIf="job?.authorId === profile?.id">
                        <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-dropdown">
                        <button mat-menu-item (click)="edit(job)">Edit</button>
                        <button mat-menu-item (click)="confirmDelete(job)">Delete</button>
                    </mat-menu>
                </div>
                <div class="flex py-1">
                    <div class="h-10 w-10 bg-white flex items-center justify-center rounded-lg">
                        <img class="rounded-lg border border-[#E1E4E8]" [src]="job?.logo || './assets/job_none.svg'"
                            alt="">
                    </div>
                    <div class="flex flex-col items-centertext-xs text-darkBlack px-2 normal-case">
                        <div>{{job?.company}}</div>

                        <div>{{job?.city}}</div>
                    </div>
                </div>

                <div class="flex justify-between items-center w-full pt-2">
                    <div class="text-xs text-darkBlack flex gap-x-2 align-center normal-case">
                        <div>{{job.type}}</div>
                        <div *ngIf="job.experience != ''">&nbsp;•&nbsp;</div>
                        <div>{{job.experience}}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>