import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-sh-check-box',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule, FormsModule],
  templateUrl: './sh-check-box.component.html',
  styleUrls: ['./sh-check-box.component.scss']
})
export class ShCheckBoxComponent {
  @Input() text: string;
  @Input() control: any = new FormControl();
  @Input() element?;
}
