<ng-container>
    <ng-container *ngIf="!(post$ | async)?.['length'] && !loading">
        <app-sh-empty-state [viewOnly]="onlyView" [btnText]="'ADD A POST'" (add)="edit()"></app-sh-empty-state>
    </ng-container>
</ng-container>

<div class="flex align-center justify-center">
    <app-sh-spinner-loader *ngIf="loading" [colorCode]="'#004481'" [roundWidth]="30"
        [thinWidth]="2"></app-sh-spinner-loader>
</div>


<div class="rounded-xl  flex flex-col justify-center items-center mx-auto bg-white"
    [ngClass]="onlyView ? '' : 'md:p-6' " *ngIf="(post$ | async)?.['length']">
    <div
        [ngClass]="{'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4': onlyView, 'w-full md:w-3/4': !onlyView}">
        <div class="flex flex-col border border-[#E1E4E8] p-3 h-[fit-content] rounded-xl mb-3  bg-white font-normal"
            *ngFor="let post of (post$ | async)">
            <div class="flex justify-between items-center">
                <div class="flex gap-x-2 items-center cursor-pointer" routerLink="/post/{{post.id}}">
                    <img class="rounded-full w-10 h-10"
                        [src]="profileData?.logo ? profileData?.logo : '../../../../assets/images/person.png'"
                        alt="Profile Banner" />

                    <span class="text-[#24292E] text-sm font-bold">{{profileData?.name || (profileData?.firstName+ '
                        '+profileData?.lastName)}}
                    </span>
                    <span class="text-xs text-[#6A737D] normal-case" *ngIf="post?.title">&nbsp;•&nbsp;{{ post?.title
                        }}</span>
                </div>

                <button [matMenuTriggerFor]="menu" *ngIf="!postData">
                    <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base rotate-90">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu-dropdown">
                    <button mat-menu-item (click)="edit(post)">Edit</button>
                    <button mat-menu-item (click)="confirmDelete(post)">Delete</button>
                </mat-menu>
            </div>

            <!-- <app-markdown class="text-sm text-[#24292E] pt-2 markdown_profile_post"
                [markDown]="post?.content"></app-markdown> -->
            <p class="text-sm text-[#24292E] pt-2 innerHtml leading-relaxed truncate-2-lines cursor-pointer" routerLink="/posts/{{post.id}}"  [innerHtml]="post?.content"></p>

            <!-- <p class="text-sm text-[#24292E] pt-2">{{ post?.content }}</p> -->
            <div *ngIf="post?.image" class="max-h-[200px] md:max-h-[350px] flex justify-center items-center cursor-pointer" routerLink="/posts/{{post.id}}">
                <img class="rounded-xl aspect-video " src="{{post?.image}}" >
            </div>


            <div class="flex justify-between w-full items-center pt-2">
                <div class="flex items-center gap-x-1 border border-[#E1E4E8] rounded-full px-2 py-1 cursor-pointer"
                    (click)="updateLike(post)" *ngIf="!onlyView || postData">
                    <svg *ngIf="post?.likedBy?.includes(profileData?.id)" width="19" height="17" viewBox="0 0 19 17"
                        fill="#24292E" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.3597 6.90232H3.8597V16.9023H1.3597C0.899467 16.9023 0.526367 16.5293 0.526367 16.069V7.73565C0.526367 7.27542 0.899467 6.90232 1.3597 6.90232ZM5.77044 5.82491L11.1042 0.491185C11.2508 0.34456 11.4829 0.328068 11.6488 0.452476L12.3593 0.985343C12.7632 1.28828 12.9452 1.80445 12.8206 2.29373L11.8595 6.06898H17.193C18.1135 6.06898 18.8597 6.81518 18.8597 7.73565V9.48926C18.8597 9.70701 18.817 9.92259 18.7342 10.1238L16.1555 16.3863C16.0269 16.6985 15.7226 16.9023 15.3849 16.9023H6.3597C5.89947 16.9023 5.52637 16.5293 5.52637 16.069V6.41417C5.52637 6.19315 5.61417 5.98119 5.77044 5.82491Z"
                            fill="#004481" />
                    </svg>
                    <svg *ngIf="!post?.likedBy?.includes(profileData?.id)" width="19" height="18" viewBox="0 0 19 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.8595 7.06945H17.193C18.1135 7.06945 18.8597 7.81564 18.8597 8.73609V10.4898C18.8597 10.7074 18.817 10.923 18.7342 11.1243L16.1555 17.3868C16.0269 17.699 15.7226 17.9028 15.3849 17.9028H1.3597C0.899467 17.9028 0.526367 17.5297 0.526367 17.0694V8.73609C0.526367 8.27587 0.899467 7.90278 1.3597 7.90278H4.26123C4.53201 7.90278 4.78589 7.77122 4.94204 7.55001L9.48653 1.11197C9.60528 0.943745 9.82912 0.887488 10.0133 0.979576L11.525 1.73543C12.4014 2.17362 12.8539 3.16327 12.6123 4.1128L11.8595 7.06945ZM5.52637 9.22567V16.2361H14.8269L17.193 10.4898V8.73609H11.8595C10.7723 8.73609 9.9762 7.71191 10.2444 6.65826L10.9971 3.70162C11.0455 3.51171 10.9549 3.31378 10.7796 3.22615L10.2287 2.95067L6.30366 8.51116C6.09541 8.80617 5.82921 9.04801 5.52637 9.22567ZM3.8597 9.56942H2.19303V16.2361H3.8597V9.56942Z"
                            fill="#24292E" />
                    </svg>


                    <span class="text-xs pt-1"
                        [ngClass]="{'text-primary': post?.likedBy?.includes(profileData?.id), 'text-darkBlack': !post?.likedBy?.includes(profileData?.id)}"
                        *ngIf="post?.likedBy?.length">{{post?.likedBy?.length || 1}}</span>
                </div>


                <div class="flex gap-x-1">
                    <span class="text-xs text-[#6A737D]">{{ post?.date | dateAgo }}</span>
                    <!-- <span class="text-xs text-[#6A737D]">Weeks</span> -->
                </div>


            </div>
        </div>
    </div>

</div>