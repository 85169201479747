import { COLLECTION_NAMES, filterParameters, FILTERS, formValidationConstants, getYearFilter, getYears } from "src/app/constants/constants";
import { dynamicElementTypes, entityConstants } from "../onboarding/utils";

export const staticTemplates = [
    '../../../../assets/profile/profile_banner.png',
    '../../../../assets/profile/profile_banner-1.png',
    '../../../../assets/profile/profile_banner-2.png',
    '../../../../assets/profile/profile_banner-3.png',
    '../../../../assets/profile/profile_banner-4.jpg',
    '../../../../assets/profile/profile_banner-5.jpg',
    '../../../../assets/profile/profile_banner-6.jpg',
    '../../../../assets/profile/profile_banner-7.jpg',
    '../../../../assets/profile/profile_banner-8.jpg',
    '../../../../assets/profile/profile_banner-9.jpg',
    '../../../../assets/profile/profile_banner-10.jpg',
    '../../../../assets/profile/profile_banner-11.jpg'
]

export const profileElements = {
    researcher: {
        general: [
            {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "First name",
                data: [],
                showSearch: true,
                attribute: "firstName"
            },
            {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Last name",
                data: [],
                showSearch: true,
                attribute: "lastName"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "University",
                data: [],
                showSearch: true,
                attribute: "university"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Faculty",
                data: [],
                showSearch: true,
                attribute: "universityFaculty"
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Designation",
                data: [],
                showSearch: true,
                attribute: "universityTitle"
            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Bio",
                data: [],
                attribute: "bio"
            }
        ],
        links: [
            {
                type: dynamicElementTypes.toggle,
                title: "Hide/disable email",
                attribute: "hideEmail",
                value: true
            },
            {
                type: dynamicElementTypes.email,
                validation: formValidationConstants.email,
                title: "Email",
                attribute: "email",
                isDisabled: true
            },
            {
                type: dynamicElementTypes.url,
                validation: formValidationConstants.url,
                icon: "",
                description: "",
                title: "Google scholar profile",
                data: [],
                attribute: "googleScholar"
            }, {
                type: dynamicElementTypes.url,
                validation: formValidationConstants.url,
                icon: "",
                description: "",
                title: "Academic homepage",
                data: [],
                attribute: "website"
            },
        ],
        expertise: [
            {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                attribute: "subThemeIds",
                showSearch: true
            }
        ]
    },
    institute: {
        general: [
            // {
            //     type: dynamicElementTypes.text,
            //     icon: "",
            //     description: "",
            //     title: "First name",
            //     data: [],
            //     showSearch: true,
            //     attribute: "firstName"
            // },
            // {
            //     type: dynamicElementTypes.text,
            //     icon: "",
            //     description: "",
            //     title: "Last name",
            //     data: [],
            //     showSearch: true,
            //     attribute: "lastName"
            // },
            {
                type: dynamicElementTypes.text,
                title: "Name",
                data: [],
                attribute: "name"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "University",
                data: [],
                showSearch: true,
                attribute: "university"
            },
            {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add headline",
                data: [],
                showSearch: false,
                attribute: "headline"
            },
            {
                type: dynamicElementTypes.select,
                title: "Country",
                data: [],
                showSearch: true,
                loadCityByCountry: true,
                attribute: "country"
            },
            {
                type: dynamicElementTypes.select,
                title: "City",
                data: [],
                showSearch: true,
                addCustomTag: true,
                attribute: "city"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Street address",
                data: [],
                showSearch: false,
                attribute: "street"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "ZIP code",
                data: [],
                showSearch: false,
                attribute: "pinCode"
            }
        ],
        links: [
            {
                type: dynamicElementTypes.toggle,
                title: "Hide/disable email",
                attribute: "hideEmail",
                value: true
            },
            {
                type: dynamicElementTypes.email,
                validation: formValidationConstants.email,
                title: "Email",
                attribute: "email",
                isDisabled: true
            },
            {
                type: dynamicElementTypes.url,
                validation: formValidationConstants.url,
                icon: "",
                description: "",
                title: "Hope page",
                data: [],
                attribute: "website"
            }
        ],
        expertise: [
            {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                attribute: "subThemeIds",
                showSearch: true
            }
        ]
    },
    student: {
        general: [
            {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "First name",
                data: [],
                showSearch: true,
                attribute: "firstName"
            },
            {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Last name",
                data: [],
                showSearch: true,
                attribute: "lastName"
            },
            {
                type: dynamicElementTypes.select,
                title: "University",
                data: [],
                showSearch: true,
                attribute: "university"
            }, {
                type: dynamicElementTypes.text,
                title: "Program",
                attribute: "program"
            }, {
                type: dynamicElementTypes.text,
                title: "Specialization",
                attribute: "specialization"
            }, {
                type: dynamicElementTypes.textArea,
                title: "Bio",
                attribute: "bio"
            }
        ],
        links: [
            {
                type: dynamicElementTypes.toggle,
                title: "Hide/disable email",
                attribute: "hideEmail",
                value: true
            },
            {
                type: dynamicElementTypes.email,
                validation: formValidationConstants.email,
                title: "Email",
                attribute: "email",
                isDisabled: true
            },
            {
                type: dynamicElementTypes.url,
                validation: formValidationConstants.url,
                icon: "",
                description: "",
                title: "Google scholar profile",
                data: [],
                attribute: "googleScholar"
            }, {
                type: dynamicElementTypes.url,
                validation: formValidationConstants.url,
                icon: "",
                description: "",
                title: "Academic homepage",
                data: [],
                attribute: "website"
            },
        ],
        expertise: [
            {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                attribute: "subThemeIds",
                showSearch: true
            }
        ]
    },
    studentorg: {
        general: [
            {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Name",
                data: [],
                showSearch: true,
                attribute: "name"
            },
            {
                type: dynamicElementTypes.select,
                title: "University",
                data: [],
                showSearch: true,
                attribute: "university"
            }, {
                type: dynamicElementTypes.text,
                title: "Add headline",
                attribute: "headline"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Country",
                data: [],
                showSearch: true,
                attribute: "country",
                loadCityByCountry: true
            }, {
                type: dynamicElementTypes.select,
                title: "City",
                data: [],
                showSearch: true,
                attribute: "city",
                addCustomTag: true
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Street address",
                data: [],
                showSearch: false,
                attribute: "street"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "ZIP code",
                data: [],
                showSearch: false,
                attribute: "pinCode"
            }
        ],
        links: [
            {
                type: dynamicElementTypes.toggle,
                title: "Hide/disable email",
                attribute: "hideEmail",
                value: true
            },
            {
                type: dynamicElementTypes.email,
                validation: formValidationConstants.email,
                title: "Email",
                attribute: "email",
                isDisabled: true
            }, {
                type: dynamicElementTypes.url,
                validation: formValidationConstants.url,
                icon: "",
                description: "",
                title: "Homepage",
                data: [],
                attribute: "website"
            },
        ],
        expertise: [
            {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                attribute: "subThemeIds",
                showSearch: true
            }
        ]
    }
}

export const profileConstants = {
    general: "General",
    links: "Links",
    expertise: "Expertise"
};

export const educationForm = {
    elements: [
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "University/School/College name",
            data: [],
            showSearch: true,
            attribute: "university"
        },
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "Degree or field of study",
            data: [],
            showSearch: true,
            attribute: "fieldOfStudy"
        },
        {
            type: dynamicElementTypes.select,
            icon: "",
            description: "",
            title: "Year of graduation",
            data: getYearFilter(),
            filterData: getYearFilter(),
            showSearch: false,
            attribute: "yearOfGraduation"
        }
    ]
}

export const dbConstants = {
    institute: {
        db: COLLECTION_NAMES.institutes,
        loadFromDB: true,
        data: []
    },
    yearOfGraduation: {
        loadAsset: false,
        constant: "name",
        loadFromDB: false,
        isInternalSearch: true
    }
};

export const affiliationForm = {
    elements: [
        {
            type: dynamicElementTypes.select,
            icon: "",
            description: "",
            title: "Institute",
            data: [],
            showSearch: true,
            attribute: "institute"
        },
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "Designation",
            data: [],
            showSearch: true,
            attribute: "designation"
        }
    ]
}

export function closeDialog(matDialog, bottomSheet) {
    try {
        matDialog.close();
    } catch (error) {
    }
    try {
        bottomSheet.dismiss();
    } catch (error) {
    }
}

export const entityConst = {
    researcher: "RESEARCHERS",
    student: "STUDENTS",
    institute: "INSTITUTES",
    studentOrg: "STUDENT ORGANISATIONS",
    ngo: "NGOS",
    govtOrg: "GOVT ORGANISATIONS",
    pvtOrg: "PRIVATE ORGANISATIONS",
    others: "OTHERS"
};

export const myProfileItems = {
    dataset: 'datasets',
    papers: 'papers',
    posts: 'posts',
    events: 'events',
    conference: 'conferences',
    grants: 'grants',
    jobs: 'jobs'
};


export const entities = [
    {
        id: entityConst.researcher,
        name: entityConst.researcher
    },
    {
        id: entityConst.student,
        name: entityConst.student
    },
    {
        id: entityConst.institute,
        name: entityConst.institute
    },
    {
        id: entityConst.studentOrg,
        name: entityConst.studentOrg
    },
    {
        id: entityConst.ngo,
        name: entityConst.ngo
    },
    {
        id: entityConst.govtOrg,
        name: entityConst.govtOrg
    },
    {
        id: entityConst.pvtOrg,
        name: entityConst.pvtOrg
    },
    {
        id: entityConst.others,
        name: entityConst.others
    }
];

export const profileMyItems = {
    datasets: [
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "Name",
            data: [],
            showSearch: false,
            attribute: "name",
            required: true
        },
        {
            type: dynamicElementTypes.url,
            icon: "",
            description: "",
            title: "Link",
            data: [],
            showSearch: true,
            attribute: "link"
        },
        {
            type: dynamicElementTypes.select,
            title: "Year",
            showSearch: true,
            loading: false,
            data: getYearFilter() || [],
            filterData: getYearFilter() || [],
            selected: null,
            constant: filterParameters.year,
            showLoadMore: false,
            attribute: FILTERS.year.toLowerCase()
        }, {
            type: dynamicElementTypes.select,
            title: "Format",
            showSearch: true,
            loading: false,
            data: [
                {
                    id: 'csv',
                    name: 'CSV'
                },
                {
                    id: 'xls',
                    name: 'XLS'
                },
                {
                    id: 'xlsx',
                    name: 'XLSX'
                },
                {
                    id: 'json',
                    name: 'JSON'
                },
                {
                    id: 'txt',
                    name: 'TXT'
                },
                {
                    id: 'ods',
                    name: 'ODS'
                },
                {
                    id: 'png',
                    name: 'PNG'
                },
                {
                    id: 'jpg',
                    name: 'JPG'
                },
                {
                    id: 'multiple formats',
                    name: 'Multiple Formats'
                },
                {
                    id: 'Do',
                    name: 'do'
                },
                {
                    id: 'HTML',
                    name: 'html'
                },
                {
                    id: 'Ipk',
                    name: 'mdb'
                },
                {
                    id: 'mp3',
                    name: 'mp3'
                },
                {
                    id: 'NC',
                    name: 'nc'
                }, {
                    id: 'RDATA',
                    name: 'rdata'
                },
                {
                    id: 'RDS',
                    name: 'rds'
                },
                {
                    id: 'sav',
                    name: 'sav'
                },
                {
                    id: 'shp',
                    name: 'shp'
                },
                {
                    id: 'tab',
                    name: 'tab'
                },
                {
                    id: 'tif',
                    name: 'tif'
                },
                {
                    id: 'ODS',
                    name: 'ods'
                },
                {
                    id: 'XML',
                    name: 'xml'
                }
            ],
            filterData: [
                {
                    id: 'csv',
                    name: 'CSV'
                },
                {
                    id: 'xls',
                    name: 'XLS'
                },
                {
                    id: 'xlsx',
                    name: 'XLSX'
                },
                {
                    id: 'json',
                    name: 'JSON'
                },
                {
                    id: 'txt',
                    name: 'TXT'
                },
                {
                    id: 'ods',
                    name: 'ODS'
                },
                {
                    id: 'png',
                    name: 'PNG'
                },
                {
                    id: 'jpg',
                    name: 'JPG'
                },
                {
                    id: 'multiple formats',
                    name: 'Multiple Formats'
                },
                {
                    id: 'Do',
                    name: 'do'
                },
                {
                    id: 'HTML',
                    name: 'html'
                },
                {
                    id: 'Ipk',
                    name: 'mdb'
                },
                {
                    id: 'mp3',
                    name: 'mp3'
                },
                {
                    id: 'NC',
                    name: 'nc'
                }, {
                    id: 'RDATA',
                    name: 'rdata'
                },
                {
                    id: 'RDS',
                    name: 'rds'
                },
                {
                    id: 'sav',
                    name: 'sav'
                },
                {
                    id: 'shp',
                    name: 'shp'
                },
                {
                    id: 'tab',
                    name: 'tab'
                },
                {
                    id: 'tif',
                    name: 'tif'
                },
                {
                    id: 'ODS',
                    name: 'ods'
                },
                {
                    id: 'XML',
                    name: 'xml'
                }
            ],
            selected: null,
            constant: 'format',
            showLoadMore: false,
            attribute: 'format',
            required: true
        }, ,
        {
            type: dynamicElementTypes.select,
            title: "Access",
            showSearch: false,
            loading: false,
            data: [
                {
                    name: 'Direct Download',
                    id: 'direct download'
                },
                {
                    name: 'Free Subscription',
                    id: 'free subscription'
                }
            ],
            filterData: [
                {
                    name: 'Direct Download',
                    id: 'direct download'
                },
                {
                    name: 'Free Subscription',
                    id: 'free subscription'
                }
            ],
            selected: null,
            constant: 'dataset_access',
            showLoadMore: false,
            attribute: 'dataset_access',
            required: true
        },
        {
            type: dynamicElementTypes.multiSelect,
            icon: "",
            description: "",
            title: "Expertise/Interest",
            data: [],
            showSearch: true,
            filterData: [],
            selected: null,
            attribute: "subThemeIds",
            required: true
        },
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "Source",
            data: [],
            filterData: [],
            showSearch: false,
            attribute: "source"
        },
        {
            type: dynamicElementTypes.textArea,
            icon: "",
            description: "",
            title: "Description",
            data: [],
            showSearch: false,
            attribute: "abstract",
            showMarkDown: true
        },
        {
            type: dynamicElementTypes.textArea,
            icon: "",
            description: "",
            title: "Collection methodology",
            data: [],
            showSearch: false,
            attribute: "collectionMethodology"
        }
    ],
    papers: [
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "Name",
            data: [],
            showSearch: false,
            attribute: "name",
            required: true
        },
        {
            type: dynamicElementTypes.url,
            icon: "",
            description: "",
            title: "Link",
            data: [],
            showSearch: true,
            attribute: "link"
        },
        {
            type: dynamicElementTypes.select,
            title: "Journal",
            showSearch: true,
            loading: false,
            data: [],
            filterData: [],
            selected: null,
            constant: filterParameters.journals,
            showLoadMore: false,
            addCustomTag: true,
            attribute: FILTERS.journals.toLowerCase(),
            showCustomMore: true,
            required: true,
            loadFromDB: true,
            valueAttribute: 'journalName'
        },
        {
            type: dynamicElementTypes.select,
            title: "Year",
            showSearch: true,
            loading: false,
            data: getYearFilter() || [],
            filterData: getYearFilter() || [],
            selected: null,
            constant: filterParameters.year,
            showLoadMore: false,
            attribute: FILTERS.year.toLowerCase(),
            required: true
        },
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "Volume",
            data: [],
            isNumber: true,
            errorMsg: "Only numeric values are allowed",
            showSearch: false,
            attribute: "volume"
        },
        {
            type: dynamicElementTypes.text,
            icon: "",
            description: "",
            title: "Issue",
            data: [],
            isNumber: true,
            errorMsg: "Only numeric values are allowed",
            showSearch: false,
            attribute: "issue"
        },
        {
            type: dynamicElementTypes.textArea,
            icon: "",
            description: "",
            title: "Abstract",
            data: [],
            showSearch: false,
            attribute: "abstract",
            showMarkDown: true
        },
        {
            type: dynamicElementTypes.multiSelect,
            icon: "",
            description: "",
            title: "Expertise/Interest",
            data: [],
            showSearch: true,
            filterData: [],
            selected: null,
            attribute: "subThemeIds",
            required: true
        },
        {
            type: dynamicElementTypes.multiSelect,
            title: "Co-authors",
            data: [],
            showSearch: true,
            filterData: [],
            selected: null,
            attribute: "researchers",
            required: true,
            loadFromDB: true,
            valueAttribute: 'firstName',
            addCustomTag: true
        },
    ],
    posts: [
        {
            type: dynamicElementTypes.text,
            title: "Title",
            attribute: "title",
            required: true
        },
        {
            type: dynamicElementTypes.textArea,
            title: "",
            placeHolder: "Write here",
            attribute: "content",
            showMarkDown: true
        },
        {
            type: dynamicElementTypes.imageUpload,
            title: "",
            attribute: "image"
        }
    ],
    events: {
        inPerson: [
            {
                type: dynamicElementTypes.select,
                title: "Mode",
                loading: false,
                data: [
                    { name: 'Virtual', id: 'virtual' },
                    { name: 'In-person', id: 'in-person' }
                ],
                filterData: [
                    { name: 'Virtual', id: 'virtual' },
                    { name: 'In-person', id: 'in-person' }
                ],
                selected: 'in-person',
                constant: filterParameters.mode,
                showLoadMore: false,
                dynamicLayoutUpdate: true,
                attribute: FILTERS.mode.toLowerCase(),
                required: true
            },
            {
                type: dynamicElementTypes.text,
                title: "Title",
                attribute: "name",
                required: true
            },
            {
                type: dynamicElementTypes.date,
                title: "Start date",
                data: [],
                attribute: "startDate",
                required: true
            },
            {
                type: dynamicElementTypes.select,
                title: "Start time",
                data: getTime(),
                attribute: "startTime",
                required: true
            },
            {
                type: dynamicElementTypes.date,
                title: "End date",
                data: [],
                attribute: "endDate",
                required: true
            },
            {
                type: dynamicElementTypes.select,
                title: "End time",
                data: getTime(),
                attribute: "endTime",
                required: true
            },
            {
                type: dynamicElementTypes.url,
                title: "Registration link",
                attribute: "eventUrl"
            },
            {
                type: dynamicElementTypes.url,
                title: "Location link (Google map)",
                attribute: "locationLink"
            },
            {
                type: dynamicElementTypes.multiSelect,
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                filterData: [],
                selected: null,
                attribute: "subThemeIds"
            },
            {
                type: dynamicElementTypes.select,
                title: "Country",
                data: [],
                showSearch: true,
                loadCityByCountry: true,
                attribute: "country"
            },
            {
                type: dynamicElementTypes.select,
                title: "City",
                data: [],
                showSearch: true,
                addCustomTag: true,
                attribute: "city"
            },
            {
                type: dynamicElementTypes.text,
                title: "Venue",
                attribute: "venue"
            },
            {
                type: dynamicElementTypes.text,
                title: "Organizer",
                attribute: "organizer",
                required: true
            },
            {
                type: dynamicElementTypes.textArea,
                title: "Description",
                attribute: "description",
                showMarkDown: true
            },
            {
                type: dynamicElementTypes.imageUpload,
                title: "Event poster",
                attribute: "logo"
            },
        ],
        virtual: [
            {
                type: dynamicElementTypes.select,
                title: "Mode",
                loading: false,
                data: [
                    { name: 'Virtual', id: 'virtual' },
                    { name: 'In-person', id: 'in-person' }
                ],
                filterData: [
                    { name: 'Virtual', id: 'virtual' },
                    { name: 'In-person', id: 'in-person' }
                ],
                selected: 'virtual',
                constant: filterParameters.mode,
                showLoadMore: false,
                dynamicLayoutUpdate: true,
                attribute: FILTERS.mode.toLowerCase(),
                required: true
            },
            {
                type: dynamicElementTypes.text,
                title: "Title",
                attribute: "name",
                required: true
            },
            {
                type: dynamicElementTypes.date,
                title: "Date",
                data: [],
                attribute: "startDate",
                required: true
            },
            {
                type: dynamicElementTypes.select,
                title: "Time",
                data: getTime(),
                attribute: "startTime",
                required: true
            },
            {
                type: dynamicElementTypes.select,
                title: "Start time",
                data: getTime(),
                attribute: "startTime",
                required: true
            },
            {
                type: dynamicElementTypes.date,
                title: "End date",
                data: [],
                attribute: "endDate",
                required: true
            },
            {
                type: dynamicElementTypes.url,
                title: "Registration link",
                attribute: "eventUrl"
            },
            {
                type: dynamicElementTypes.multiSelect,
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                filterData: [],
                selected: null,
                attribute: "subThemeIds"
            },
            {
                type: dynamicElementTypes.select,
                title: "Country",
                data: [],
                showSearch: true,
                attribute: "country"
            },
            // {
            //     type: dynamicElementTypes.text,
            //     title: "Venue",
            //     attribute: "venue"
            // },
            {
                type: dynamicElementTypes.text,
                title: "Organizer",
                attribute: "organizer",
                required: true
            },
            {
                type: dynamicElementTypes.textArea,
                title: "Description",
                attribute: "description",
                showMarkDown: true
            },
            {
                type: dynamicElementTypes.imageUpload,
                title: "Event poster",
                attribute: "logo"
            },
        ]
    },
    conference: [
        {
            type: dynamicElementTypes.text,
            title: "Title",
            attribute: "name",
            required: true
        },
        {
            type: dynamicElementTypes.date,
            title: "Start date",
            attribute: "startDate",
            required: true
        }, {
            type: dynamicElementTypes.date,
            title: "End date",
            attribute: "endDate",
            required: true
        },
        {
            type: dynamicElementTypes.select,
            title: "Country",
            data: [],
            showSearch: true,
            loadCityByCountry: true,
            attribute: "country",
            required: true
        },
        {
            type: dynamicElementTypes.select,
            title: "City",
            data: [],
            showSearch: true,
            attribute: "city",
            addCustomTag: true,
        },
        {
            type: dynamicElementTypes.text,
            title: "Venue",
            attribute: "venue"
        },
        {
            type: dynamicElementTypes.multiSelect,
            title: "Expertise/Interest",
            data: [],
            showSearch: true,
            filterData: [],
            selected: null,
            attribute: "subThemeIds",
            required: true
        },

        {
            type: dynamicElementTypes.text,
            title: "Organizer",
            attribute: "organizer"
        },
        {
            type: dynamicElementTypes.url,
            title: "Registration link",
            attribute: "conferenceUrl"
        },
        {
            type: dynamicElementTypes.date,
            title: "Submission deadline",
            attribute: "paper_submission_deadline",
            required: true
        }, {
            type: dynamicElementTypes.date,
            title: "Registration deadline",
            attribute: "registration_deadline",
            required: true
        },
        {
            type: dynamicElementTypes.text,
            title: "Contact",
            attribute: "contact",
            errorMsg: "Invalid phone",
            isPhone: true
        },
        {
            type: dynamicElementTypes.textArea,
            title: "Description",
            attribute: "description",
            showMarkDown: true
        },
        {
            type: dynamicElementTypes.imageUpload,
            title: "Event poster",
            attribute: "logo"
        },
    ],
    grants: [
        {
            type: dynamicElementTypes.text,
            title: "Name",
            attribute: "name",
            required: true
        },
        {
            type: dynamicElementTypes.url,
            title: "Link",
            attribute: "grantUrl"
        },
        {
            type: dynamicElementTypes.multiSelect,
            title: "Expertise/Interest",
            data: [],
            showSearch: true,
            filterData: [],
            selected: null,
            attribute: "subThemeIds",
            required: true
        },
        {
            type: dynamicElementTypes.text,
            title: "Currency",
            attribute: "currency"
        },
        {
            type: dynamicElementTypes.text,
            title: "Amount",
            attribute: "amount"
        },
        {
            type: dynamicElementTypes.select,
            title: "Type",
            attribute: "type",
            isLocalData: true,
            data: [
                {
                    name: "Grant", id: "grant"
                },
                {
                    name: "Scholarship", id: "scholarship"
                },
                {
                    name: "Award", id: "award"
                }
            ]
        },
        {
            type: dynamicElementTypes.text,
            title: "Audience",
            showSearch: true,
            data: [],
            attribute: "recipient",
            required: true
        },
        {
            type: dynamicElementTypes.text,
            title: "Sponsor",
            attribute: "sponsor"
        },
        {
            type: dynamicElementTypes.date,
            title: "Deadline",
            attribute: "deadline",
            required: true
        },
        {
            type: dynamicElementTypes.textArea,
            title: "Objective",
            attribute: "description",
            showMarkDown: true
        }
    ],
    jobs: [
        {
            type: dynamicElementTypes.text,
            title: "Title",
            attribute: "name",
            required: true
        },
        {
            type: dynamicElementTypes.url,
            title: "Link to apply",
            attribute: "jobUrl"
        },
        {
            type: dynamicElementTypes.text,
            title: "Type",
            attribute: "type",
            placeHolder: 'Full time / Part time / Contract / etc',
            // showSearch: true,
            data: [],
            addCustomTag: true,
            required: true
        },

        {
            type: dynamicElementTypes.text,
            title: "Experience level",
            attribute: "experience",
            required: true
        },
        {
            type: dynamicElementTypes.multiSelect,
            title: "Skills",
            showSearch: true,
            isCustomChips: true,
            attribute: "jobSkills",
            addCustomTag: true,
            isInternalSearch: true,
            closeDropdownOnSelect: false
        },
        {
            type: dynamicElementTypes.textArea,
            title: "Description",
            attribute: "description",
            showMarkDown: true
        },

        // {
        //     type: dynamicElementTypes.title,
        //     title: "Exployer details",
        // },

        {
            type: dynamicElementTypes.text,
            title: "Employer name",
            attribute: "company",
            required: true
        },
        {
            type: dynamicElementTypes.text,
            title: "Job sector",
            attribute: "jobSector",
            placeHolder: 'Consulting / Corporate / Energy / etc',
            data: [],
            // valueAttribute: 'jobSector',
            addCustomTag: true,
            required: true
        },
        {
            type: dynamicElementTypes.select,
            title: "Country",
            data: [],
            showSearch: true,
            loadCityByCountry: true,
            attribute: "country",
            required: true
        },
        {
            type: dynamicElementTypes.select,
            title: "City",
            data: [],
            showSearch: true,
            attribute: "city",
            addCustomTag: true,
            required: true
        },
        {
            type: dynamicElementTypes.imageUpload,
            title: "Logo",
            attribute: "logo"
        },
    ]
}


export function getTime() {
    const interval = 30; //minutes interval

    const startDate = 60 * 0; // start time in minutes

    const endDate = 60 * 24; // end time in minutes

    return generateHoursInterval(startDate, endDate, interval);
}
export function generateHoursInterval(
    startHourInMinute,
    endHourInMinute,
    interval,
) {
    const times = [];

    for (let i = 0; startHourInMinute < 24 * 60; i++) {
        if (startHourInMinute > endHourInMinute) break;

        var hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format

        var mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format

        const obj = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);
        times[i] = {
            name: obj,
            id: obj
        }

        startHourInMinute = startHourInMinute + interval;
    }

    return times;
};


