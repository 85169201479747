import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ShProfilePostsComponent } from './components/sh-profile-posts/sh-profile-posts.component';
import { take, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ShAddMyItemsModelComponent } from './components/sh-profile-datasets/sh-add-datasets-model/sh-add-my-items-model.component';
import { ShProfileDatasetsPapersComponent } from './components/sh-profile-datasets/sh-profile-datasets-papers.component';
import { ShProfileEventConferenceComponent } from './components/sh-profile-event-conference/sh-profile-event-conference.component';
import { ShProfileJobComponent } from './components/sh-profile-job/sh-profile-job.component';
import { ShProfileGrantsComponent } from './components/sh-profile-grants/sh-profile-grants.component';
import { myProfileItems } from '../util';
import { PROFILE } from '../sh-tab-profile/sh-tab-profile.component';
import { DataPassService } from 'src/app/services/datapass.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-sh-myitems-profile',
  standalone: true,
  imports: [CommonModule, MatButtonModule, ShProfileDatasetsPapersComponent, ShProfilePostsComponent, ShProfileGrantsComponent, ShProfileEventConferenceComponent, ShProfileJobComponent],
  templateUrl: './sh-myitems-profile.component.html',
  styleUrls: ['./sh-myitems-profile.component.scss']
})
export class ShMyitemsProfileComponent implements OnInit {
  selectedTab: string = 'datasets'; // Default tab
  myProfileItems = myProfileItems;
  destroy$ = new Subject();

  // Example menu items
  menuItems = [
    { name: 'Datasets', value: 'datasets' },
    { name: 'Papers', value: 'papers' },
    { name: 'Posts', value: 'posts' },
    { name: 'Events', value: 'events' },
    { name: 'Conferences', value: 'conferences' },
    { name: 'Jobs', value: 'jobs' },
    { name: 'Grants', value: 'grants' },
  ];
  profile: any;
  data: boolean;
  isSavedItems = null;
  currentTab: string;

  constructor(
    @Inject(PROFILE) private profileInjected: any,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private dataService: DataPassService
  ) {
    this.profile = { ...this.profileInjected.profile };
  }
  ngOnInit() {
    this.dataService.changeData.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.isSavedItems = (data === 'Saved Items');
      this.currentTab = data;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete();
  }

  setTab(valueOrEvent: string | Event) {
    if (valueOrEvent instanceof Event) {
      const event = valueOrEvent as Event;
      const value = (event.target as HTMLSelectElement).value;
      this.selectedTab = value;
    } else {
      this.selectedTab = valueOrEvent;
    }
  }

  openAddDatasets(): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShAddMyItemsModelComponent, {
          data: {
            entity: this.selectedTab,
            profile: this.profile,
            data: null
          }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialog.open(ShAddMyItemsModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: {
            entity: this.selectedTab,
            profile: this.profile,
            data: null
          }
        });
      }
    });
  }
}
