import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeValue',
  standalone: true, // Make the pipe standalone
})
export class SafeValuePipe implements PipeTransform {
  transform(value: any, fallback: string = ''): any {
    return value !== null && value !== undefined && !Number.isNaN(value) ? value : fallback;
  }
}
