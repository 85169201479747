<div class="institute" *ngIf="institute">
  <div class="institute__loading-screen" *ngIf="isLoading; else loadedContent">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #loadedContent>
    <div class="institute__hero">
      <div
        class="institute__hero__background institute__hero__background"
      ></div>
      <img class="institute__hero__preview" [src]="institute.logo" alt="" />
      <div class="institute__hero__title">
        {{ institute.displayName }}
      </div>
      <div class="institute__hero__university">
        <ng-container *ngFor="let university of institute.displayUniversity">
          <div class="institute__hero__university__item">
            {{ university }}
          </div>
        </ng-container>
      </div>
      <div class="institute__hero__description" *ngIf="institute.blurb">
        {{ institute.displayBlurb }}
      </div>
    </div>

    <mat-tab-group class="institute__tab">
      <mat-tab label="{{ 'institute.label-about.title' | translate }}">
        <div class="institute__section__contacts">
          <div class="institute__section__contacts__item">
            <div class="institute__section__contacts__item__title">
              Type of Institute
            </div>

            <div class="institute__section__contacts__item__value">
              {{ institute.type }}
            </div>
          </div>
          <div
            class="institute__section__contacts__item"
            *ngIf="institute.website"
          >
            <p
              class="institute__section__contacts__item__title"
              src="../../../assets/images/icon-web.png"
              alt="website"
            >
              Website
            </p>
            <a
              class="institute__section__contacts__item__value--link"
              href="{{ institute.website }}"
              target="_blank"
            >
              Click to open
            </a>
          </div>
          <div class="institute__section__contacts__item">
            <div class="institute__section__contacts__item__title">E-mail</div>

            <div class="institute__section__contacts__item__value">
              {{ institute.email }}
            </div>
          </div>
          <div class="institute__section__contacts__item">
            <div class="institute__section__contacts__item__title">Faculty</div>

            <div class="institute__section__contacts__item__value">
              {{ institute.faculty }}
            </div>
          </div>
        </div>

        <!-- Description of the institute -->
        <app-institute-page-section
          class="institute__section"
          title="Description"
          content="{{ institute.displayDescription }}"
        ></app-institute-page-section>

        <!-- Details of the institute -->
        <app-institute-page-section
          class="institute__section"
          title="Research Themes"
        ></app-institute-page-section>

        <div class="institute__section">
          <div class="institute__section__research_themes">
            <div class="institute__section__research_themes__item">
              <img
                class="institute__section__research_themes__item__icon"
                src="../../../assets/institute-icons/{{
                  institute.mainCategory
                }}.png"
                alt="theme"
              />
              <div class="institute__section__research_themes__item__title">
                {{ institute.mainCategory }}
              </div>
              <div class="institute__section__research_themes__item__subtitle">
                {{ institute.mainSubcategory }}
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'institute.label-team.title' | translate }}">
        <ng-container *ngFor="let level of researcherLevels">
          <app-institute-page-section
            class="institute__section"
            title="{{ level }}"
          ></app-institute-page-section>

          <div class="institute__section--grid">
            <div
              class="institute__section__researcher__no-researchers"
              *ngIf="
                !filterResearchers(level) ||
                  filterResearchers(level).length === 0;
                else instituteResearcherSection
              "
            >
              No researchers found!
            </div>
            <ng-template #instituteResearcherSection>
              <ng-container *ngFor="let researcher of filterResearchers(level)">
                <div
                  class="institute__section__researcher"
                  (click)="selectResearcher(researcher)"
                >
                  <div
                    class="institute__section__researcher__image"
                    [ngStyle]="{
                      backgroundImage: 'url(\'' + researcher.picture + '\')'
                    }"
                  ></div>
                  <div class="institute__section__researcher__body">
                    <div class="institute__section__researcher__body__name">
                      {{ researcher.firstName }} {{ researcher.lastName }}
                    </div>
                    <div class="institute__section__researcher__body__title">
                      {{ researcher.displayTitle }}
                    </div>
                    <div
                      class="institute__section__researcher__body__faculty"
                      *ngIf="researcher.faculty"
                    >
                      {{ researcher.faculty }}
                    </div>
                    <div
                      class="institute__section__researcher__body__university"
                      *ngIf="researcher.university"
                    >
                      {{ researcher.university }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="{{ 'institute.label-projects.title' | translate }}">
        <div class="institute__section--grid">
          <div
            class="institute__section__project__no-projects"
            *ngIf="
              !instituteProjects || instituteProjects.length === 0;
              else instituteProjectsSection
            "
          >
            No projects found!
          </div>

          <ng-template #instituteProjectsSection>
            <ng-container *ngFor="let project of instituteProjects">
              <div
                class="institute__section__project"
                (click)="selectProject(project)"
              >
                <div
                  class="institute__section__project__image"
                  [ngStyle]="{
                    backgroundImage: 'url(\'' + project.icon + '\')'
                  }"
                ></div>
                <div class="institute__section__project__body">
                  <div class="institute__section__project__body__title">
                    {{ project.displayTitle }}
                  </div>
                  <div class="institute__section__project__body__description">
                   <p [innerHTML]="project.displayDescription"></p> 
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="institute__opacity-background"></div>
  </ng-template>
</div>
