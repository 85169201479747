<div [class.dark-theme]="true" class="flex flex-col overflow-hidden">
  <div class="flex w-full justify-between p-6">
    <span class="text-lg md:text-2xl text-darkBlack font-bold capitalize">{{ !value ? 'Create new '+myItem.toLowerCase()
      : 'Update '+myItem.toLowerCase() }}</span>

    <mat-icon (click)="cancelClick('model')"
      class="text-[#959DA5] text-lg cursor-pointer !hidden md:!block">close</mat-icon>
    <mat-icon (click)="cancelClick('')" class="text-[#959DA5] text-lg cursor-pointer md:!hidden !block">close</mat-icon>
  </div>

  <div class="flex flex-col gap-y-2 mt-4 max-h-[calc(100vh_-_21rem)] overflow-y-auto px-6" formGroup="form">
    <ng-container *ngFor="let element of pageLayout">
      <ng-container *ngIf="element?.type == dynamicElementTypes.textArea">
        <app-sh-textarea-box [control]="form.get(element?.attribute)" [element]="element"
          [placeholder]="element?.placeHolder || element?.title" [label]="element?.title"></app-sh-textarea-box>
      </ng-container>

      <ng-container *ngIf="element?.type == dynamicElementTypes.text">
        <app-sh-input-text [control]="form.get(element?.attribute)" [element]="element"
          [placeholder]="element?.placeHolder || element?.title" [errorMessage]="element?.errorMsg"
          [label]="element?.title"></app-sh-input-text>
      </ng-container>

      <ng-container *ngIf="element?.type == dynamicElementTypes.url">
        <app-sh-input-text [control]="form.get(element?.attribute)" [isUrl]="true" [placeholder]="element?.title"
          [errorMessage]="'Invalid URL'" [label]="element?.title" [element]="element"></app-sh-input-text>
      </ng-container>

      <ng-container *ngIf="element?.type == dynamicElementTypes.checkbox">
        <app-sh-check-box [control]="form.get(element?.attribute)" [text]="element?.title"
          [element]="element"></app-sh-check-box>
      </ng-container>

      <ng-container
        *ngIf="element?.type == dynamicElementTypes.select || element?.type == dynamicElementTypes.multiSelect">
        <app-ng-custom-select [control]="form.get(element?.attribute)"
          [isMultiple]="element?.type == dynamicElementTypes.multiSelect" [data]="element.filterData"
          [PlaceholderText]="element?.placeHolder || element?.title" [LabelText]="element?.title" [element]="element"
          [model]="element?.selected || null" (search)="onSearch($event)"
          [bindValue]="(element?.attribute == 'city' ? 'name' : 'id')" [bindLabel]="element?.valueAttribute || 'name'"
          [canAddTag]="element?.addCustomTag"></app-ng-custom-select>
        <!-- [showCustomMore]="element?.showCustomMore" -->
      </ng-container>

      <ng-container *ngIf="element?.type == dynamicElementTypes.date">
        <app-sh-calendar [form]="form" [addCss]="true" [control]="form.get(element?.attribute)" [title]="element.title"
          [element]="element" [isSignleDateSelectionComponent]="true"></app-sh-calendar>
      </ng-container>

      <ng-container *ngIf="element?.type == dynamicElementTypes.imageUpload">
        <app-sh-common-upload [buttonLabel]="'ADD A PHOTO'" [data]="value?.image || value?.logo"
          [label]="element?.title" (fileSelected)="uploadFile($event)"></app-sh-common-upload>
      </ng-container>

    </ng-container>

  </div>


  <div class="flex justify-end mt-4 px-6 pb-6">
    <button mat-raised-button color="primary" class="w-24" (click)="create()" [disabled]="uploadingData || !form.valid">
      <app-sh-spinner-loader *ngIf="uploadingData" [colorCode]="'#fff'" [roundWidth]=20
        [thinWidth]=2></app-sh-spinner-loader>
      {{uploadingData ? '' : value ? 'UPDATE' : 'CREATE'}}
    </button>
  </div>

</div>