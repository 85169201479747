import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, of, Subject, Observable, lastValueFrom, throwError } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { User as Profile } from '../models/userInfo.model';
import firebase from 'firebase/compat/app';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import UserCredential = firebase.auth.UserCredential;
import User = firebase.User;
import { format, formatDate } from '../utils/date-service.utils';
import { FirebaseCollectionService } from './firebase-collection.service';
import { getAuth, updateEmail } from "firebase/auth";
import { environment } from 'src/environments/environment';
import { APP_URL } from '../constants/constants';

export interface MessagesIndex {
  [index: string]: string;
}

export const firebaseAuthErrorMessages = {
  'auth/invalid-email': 'The email address is incorrectly formatted.',
  'auth/user-not-found': `Account doesn't exist`,
  'auth/invalid-login-credentials': `Account credentials doesn't exist`,
  'auth/wrong-password': `Invalid or non existent password.`,
  'auth/email-already-in-use': `Email already in use`,
  'auth/weak-password': `Password is too weak`,
  'auth/too-many-requests': `Too many attempts. Please wait.`,
  'auth/expired-action-code': `Your session has expired`,
  'auth/invalid-action-code': `Invalid session. please restart the process`,
  'auth/platform-no-access': `No access to platform`,
  'auth/email-not-verified': `Email not verified.`,
  'auth/unknown': `Something went wrong, please contact administrator`,
} as MessagesIndex;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _firebaseAuthUserData: User = null;

  public profileChange: EventEmitter<Profile> = new EventEmitter<Profile>();

  constructor(
    private firebaseAuth: AngularFireAuth,
    private firebaseStorage: AngularFireStorage,
    private userService: UserService,
    private fsFunction: AngularFireFunctions,
    private snackBar: MatSnackBar,
    private router: Router,
    private fbService: FirebaseCollectionService
  ) {
    this.refreshLocalProfileData();
  }

  async refreshLocalProfileData() {
    const user_auth_data = await firstValueFrom(this.firebaseAuth.user);

    if (!user_auth_data) {
      this.cleanUserCacheData();
      return;
    }
    this._firebaseAuthUserData = user_auth_data;

    // await this.checkAuthConnection(this._firebaseAuthUserData);
    this.userService
      .getValueChanges(this._firebaseAuthUserData.uid)
      .pipe(
        takeUntil(this.destroy$),
        map(async () => {
          const updatedUserData = await firstValueFrom(
            this.userService.getData(this._firebaseAuthUserData.uid)
          );

          this.changeProfileInStorage(updatedUserData);
        })
      )
      .subscribe();
  }

  getAuth() {
    return this._firebaseAuthUserData;
  }

  getAuthUser() {
    return this.firebaseAuth.user;
  }

  changeProfileInStorage(profileData: any) {
    localStorage.setItem('user', JSON.stringify(new Profile(profileData)));
    this.profileChange.emit(new Profile(profileData));
  }

  cleanUserCacheData() {
    const localStorageKeys = Object.keys(localStorage).filter((item) => {
      return item.startsWith('user') || item.startsWith('firebase:authUser');
    });

    for (const key of localStorageKeys) {
      localStorage.removeItem(key);
    }

    this.profileChange.emit(null);
  }

  async checkAuthConnection(authUserData: User = null) {
    const user = !!authUserData ? authUserData : this._firebaseAuthUserData;

    if (!user?.email) {
      throw { code: 'auth/user-not-found' };
    }

    return user
      .getIdTokenResult()
      .then((token) => {
        if (!token?.claims?.['email_verified']) {
          throw { code: 'auth/email-not-verified' };
        }

        return true;
      })
      .catch((e) => {
        this.logout();
        throw e;
      });
  }

  get currentProfile$() {
    return this.firebaseAuth.user.pipe(
      take(1),
      switchMap((authUser) => {
        if (!this._firebaseAuthUserData) {
          return of(null);
        }

        const userLocal = JSON.parse(localStorage.getItem('user'));

        if (!!userLocal) {
          const userData = new Profile(userLocal);
          if (this._firebaseAuthUserData.uid === userData.id) {
            return of(new Profile(userData));
          }
        }
        return this.userService.getData(this._firebaseAuthUserData.uid);
      }),
      takeUntil(this.destroy$)
    );
  }

  getUser() {
    return this.firebaseAuth.user.pipe(
      switchMap((authUser) => {
        if (authUser?.uid) {
          return this.userService.getData(authUser.uid);
        } else {
          return of();
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  async validateSignInWithEmailAndPassword(email: string, password: string) {
    const userCredential = await this.firebaseAuth.signInWithEmailAndPassword(
      email,
      password
    );

    return userCredential;
  }

  getFirebaseAuth() {
    return firebase.auth();
  }

  async signInWithEmailLink(email: string) {
    return this.firebaseAuth.signInWithEmailLink(email, window.location.href);
  }

  async sendSignInLinkToEmail(email: string, url?: string) {
    let confirmationUrl = `/onboarding/${APP_URL.emailAuthentication}`;
    if (url) {
      confirmationUrl = `/${url}`;
    }
    return this.firebaseAuth.sendSignInLinkToEmail(email,
      {
        url: `${environment.host}${confirmationUrl}`,
        handleCodeInApp: true
      });
  }

  async sendEmailVerification() {
    return (await this.firebaseAuth.currentUser).sendEmailVerification();
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    const userCredential = await this.firebaseAuth.signInWithEmailAndPassword(
      email,
      password
    );
    await this.firebaseAuth.setPersistence(
      firebase.auth.Auth.Persistence.LOCAL
    );

    const connectionValid = await this.checkAuthConnection(userCredential.user);

    if (connectionValid) {
      this._firebaseAuthUserData = userCredential.user;
      const userData = await firstValueFrom(
        this.userService.getData(this._firebaseAuthUserData.uid)
      );
      this.changeProfileInStorage(userData);
      return true;
    }
    return false;
  }

  async logout() {
    try {
      this.cleanUserCacheData();

      await this.firebaseAuth.signOut();

      this.destroy$.next(true);
      this.destroy$.complete();
      this._firebaseAuthUserData = null;

      // this.snackBar.open('Sign out successful', 'OK', {
      //   duration: 5000,
      // });

      return this.router.navigateByUrl('/');
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createUserWithEmailAndPassword(email, password) {
    return this.firebaseAuth.createUserWithEmailAndPassword(
      email,
      password
    );
  }

  async register(
    email: string,
    password: string,
    profile: Profile
  ): Promise<void> {

    return this.firebaseAuth.createUserWithEmailAndPassword(
      email,
      password
    ).then((userCredential: UserCredential) => {
      userCredential.user
        .updateProfile({
          displayName: `${profile.firstName} ${profile.lastName}`,
        })
        .then();

      profile.id = userCredential.user.uid;
      profile['created'] = format(new Date());

      return this.userService
        .createWithId(userCredential.user.uid, profile.toJSON())
        .then(() => {
          return userCredential.user;
        }).catch(e => e);
    })
      .then((res) => {

        return res;
      })
      .catch(error => {
        // return error;
        throw (error);
      })
  }

  async reauthenticate(currentPassword): Promise<firebase.auth.UserCredential> {
    const user = await this.firebaseAuth.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  }

  async changePassword(oldPassword, newPassword) {
    return this.reauthenticate(oldPassword).then(async () => {
      const user = await this.firebaseAuth.currentUser;
      return user.updatePassword(newPassword).then();
    });
  }

  sendPasswordResetEmail(email): Promise<void> {
    return firstValueFrom(
      this.fsFunction.httpsCallable('passwordResetEmail')({
        email,
        language: 'en',
      })
    );
  }

  resendEmailVerification(email): Promise<void> {
    return firstValueFrom(
      this.fsFunction.httpsCallable('resendEmailActivation')({
        email,
      })
    );
  }

  confirmPasswordReset(oobCode, password) {
    return this.firebaseAuth.confirmPasswordReset(oobCode, password);
  }

  validationEmail(code): Promise<void> {
    return this.firebaseAuth.applyActionCode(code);
  }

  generateAuthErrorMessage(error_response) {
    return firebaseAuthErrorMessages[error_response?.code]
      ? firebaseAuthErrorMessages[error_response?.code]
      : firebaseAuthErrorMessages[error_response?.details?.code]
        ? firebaseAuthErrorMessages[error_response?.details?.code]
        : firebaseAuthErrorMessages['auth/unknown'];
  }

  forgotPassword(email: string, language: string): any {
    return this.fsFunction.httpsCallable('callSendPasswordResetEmail')({
      email,
      language,
    });
  }

  resetPassword(oobCode, password) {
    return this.firebaseAuth.confirmPasswordReset(oobCode, password);
  }

  updatePassword(newPassword) {
    // const user = this.firebaseAuth.currentUser;
    const user = firebase.auth().currentUser;
    return user.updatePassword(newPassword);
  }

  updateAuthUserEmail(email) {
    // firebase.auth().user
    const auth = getAuth();
    return updateEmail(auth.currentUser, email);
  }

  async isAdmin(): Promise<boolean> {
    try {
      const user = await lastValueFrom(
        this.firebaseAuth.authState.pipe(take(1))
      );

      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        return !!idTokenResult.claims['isAdmin'];
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
