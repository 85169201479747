import { format } from "../utils/date-service.utils";
import { getCharCombo, getMonthYearTerms, getSubThemes, getSubThemesKeywords, getTermsAsKeywords, getThemeIds, getWordsCombo } from "./utils";

export interface Conference {
    ThemeIds?: string[],
    city?: string,
    conferenceUrl?: string,
    country?: string,
    description?: string
    endDate?: string,
    index?: number,
    keywords?: string[],
    name?: string,
    paper_submission_deadline?: string,
    price?: string,
    startDate?: string,
    subThemeIds?: string[],
    unorderedAddress?: string,
    venue?: string,
    authorId?: string,
    author?: string,
    logo?: string,
    organizer?: string,
    registration_deadline?: string,
    contact?: number
}

export function conferenceDbo(data, conference) {
    const dbo: Conference = {};

    dbo.author = data?.author || null;
    dbo.authorId = data?.authorId || null;
    dbo.city = data?.city?.id || data?.city || null
    dbo.country = data?.country?.id || data?.country || null
    dbo.description = data.description || null;
    dbo.index = data.index || null;
    dbo.keywords = buildKeywords(data, conference?.keywords);
    dbo.logo = data?.logo || null;
    dbo.name = data?.name || null;
    dbo.organizer = data?.organizer || null;
    dbo.price = data?.price || null;
    dbo.startDate = format(data?.startDate) || null;
    dbo.endDate = format(data.endDate) || null;
    dbo.subThemeIds = getSubThemes(data?.subThemeIds);
    dbo.ThemeIds = getThemeIds(data?.subThemeIds, conference?.ThemeIds);
    dbo.unorderedAddress = data?.venue + ", " + (data?.city?.id || data?.city) + ", " + (data?.country?.id || data?.country);
    dbo.venue = data?.venue || null;
    dbo.paper_submission_deadline = format(data?.paper_submission_deadline) || null;
    dbo.registration_deadline = format(data?.paper_submission_deadline) || null;
    dbo.conferenceUrl = data?.conferenceUrl || null;
    dbo.contact = data?.contact || null;

    // console.log(dbo);
    return dbo;
}


export function buildKeywords(data, existingKeywords) {
    let keywords = [];

    let nameArr = data?.name?.split("");
    keywords = [...keywords, ...getCharCombo(nameArr, existingKeywords)];

    const wordKeyword = [...data?.name?.split(" ")];
    keywords = [...keywords, ...getTermsAsKeywords(wordKeyword)];

    const { organizer } = data;
    const country = data?.country?.id || data?.country;
    const city = data?.city?.id || data?.city;
    const otherKeywordAttr = [organizer, country, city];
    keywords = [...keywords, ...getTermsAsKeywords(otherKeywordAttr, existingKeywords)];

    // Sub themes into Keywords
    if (data?.subThemeIds?.length) {
        keywords = [...keywords, ...getSubThemesKeywords(data.subThemeIds, existingKeywords)];
    }

    keywords = [...keywords, ...getMonthYearTerms(data?.startDate)];

    keywords = [...new Set([...keywords, ...existingKeywords || []])];

    return keywords;
}
