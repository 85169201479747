<div class="flex flex-col">

  <ng-container *ngIf="!(events$ | async)?.['length'] && !loading">
    <app-sh-empty-state [btnText]="emptyStateAddButtonLabel" (add)="edit()"></app-sh-empty-state>
  </ng-container>

  <div class="flex align-center justify-center">
    <app-sh-spinner-loader *ngIf="loading" [colorCode]="'#004481'" [roundWidth]="30"
      [thinWidth]="2"></app-sh-spinner-loader>
  </div>


  <ng-container>
    <a [routerLink]="(moduleEntity === myProfileItems.events) ? ['/events', event.id] : ['/conferences', event.id]"
      class="flex rounded-lg border border-lightGrey bg-white p-4 rounded-xl shadow-sm mb-3"
      *ngFor="let event of (events$ | async)">
      <div class="flex md:flex-row flex-col-reverse w-full justify-between ">
        <div class="flex gap-x-3">
          <div class="flex flex-col  border border-[#D6ECFF] rounded-lg h-[fit-content] w-[52px]">
            <div class="bg-primary flex justify-center items-center py-2 text-xs text-white rounded-t-lg uppercase">
              {{event?.month}}
            </div>
            <div class="flex flex-col justify-center items-center">
              <div>
                <strong class="font-bold text-lg text-[#24292E]">{{event?.day}}</strong>
              </div>
              <div class="text-xs text-[#24292E] flex justify-center items-center">{{event?.year}}</div>
            </div>
          </div>
          <div class="flex flex-col">

            <span class="text-sm text-darkBlack font-bold normal-case">{{event?.title || event?.name}}
            </span>
            <div class="text-xs text-darkBlack flex gap-x-2 align-center pt-1 flex-wrap">
              <div *ngIf="event?.startTime">{{event?.startTime}}</div>
              <div *ngIf="event?.startTime">&nbsp;•&nbsp;</div>

              <div class="text-xs flex gap-x-2 ">
                <span class="" *ngIf="event?.venue">{{ event.venue }},</span>
                <span class="" *ngIf="event?.city">{{ event.city }},</span>
                <span class="capitalize" *ngIf="event?.country">{{
                  event.country
                  }}</span>
              </div>
              <div class="card__info__content__venue capitalize" *ngIf="event?.organizer">
                <a *ngIf="event?.venue"
                  href="https://maps.google.com/?q={{event?.venue}}, {{event.city}}, {{event.country}}" target="_blank">
                  {{ event?.venue }},&nbsp;
                </a>
                {{
                event?.organizer
                }}
              </div>

            </div>

            <div class="flex flex-wrap gap-x-2 mt-2 ml-2">
              <mat-chip-set *ngFor="let id of event.subThemeIds">
                <ng-container *ngIf="subThemes?.[id]?.name">
                  <app-sh-theme-item [classesData]="true" [themeData]="subThemes?.[id]"
                    [imageShow]="true"></app-sh-theme-item>
                </ng-container>
              </mat-chip-set>
            </div>

            <div class="text-xs text-darkBlack flex gap-x-2 align-center pt-2">
              <div class="normal-case"><span *ngIf="event?.city">{{ event?.city }},</span>&nbsp; <span class="capitalize">{{ event?.country }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-start mb-2 md:mb-0 relative">
          <div
            class="h-[150px] md:h-[107px] w-full md:w-[190px] bg-white flex items-center justify-center rounded-lg border border-[#E1E4E8]">
            <img [ngClass]="event?.logo ? '' : 'object-cover'" class="rounded-lg w-full h-full"
              [src]="event?.logo || './assets/noImage.WEBP'" alt="">

          </div>

          <button *ngIf="event?.authorId === profile?.id"
            class="absolute right-2 top-2 bg-white rounded-full w-8 h-8 flex justify-center items-center block md:hidden"
            [matMenuTriggerFor]="menu" (click)="onClickMenu($event)">
            <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base">more_vert</mat-icon>
          </button>
          <button class="hidden md:block" [matMenuTriggerFor]="menu" (click)="onClickMenu($event)"
            *ngIf="event?.authorId === profile?.id">
            <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu-dropdown">
            <button mat-menu-item (click)="view(event)">Open</button>
            <button mat-menu-item (click)="edit(event)">Edit</button>
            <button mat-menu-item (click)="confirmDelete(event)">Delete</button>
          </mat-menu>
        </div>


      </div>

    </a>
  </ng-container>

  <div class="flex align-center justify-center">
    <app-sh-load-more (loadMore)="loadMoreData()" *ngIf="showLoadMore"></app-sh-load-more>
  </div>
</div>