    
    <div class=" gap-4 mt-4" [class]="cssClass">
      <div [ngClass]="profileImage ? 'bg-[#FAFBFC]' : 'bg-white'" class="card shadow-sm hover:shadow-lg p-3 rounded-xl" *ngFor="let item of posts | async">
<!-- {{ (item.researcher$ | async) | json }} -->

        <a class="card__sec-1" >
          <div class="flex justify-between w-full items-center cursor-pointer" *ngIf="profileImage">
            <div class="flex gap-x-2 items-center" routerLink="/posts/{{item.id}}">
              <img class="rounded-full w-10 h-10"
                  [src]="(item.researcher$ | async)?.['logo'] ? (item.researcher$ | async)?.['logo'] : '../../../../assets/images/person.png'"
                  alt="Profile Banner" />
<div class="flex flex-col">
  <span class="text-[#24292E] text-sm font-bold">{{ item.author }}     </span>
  <span class="text-xs text-[#6A737D] normal-case" *ngIf="item?.title">{{ item?.title }}</span>
</div>
            
          </div>
        
          <button [matMenuTriggerFor]="menu" *ngIf="entityId === (item.researcher$ | async)?.['id']">
            <mat-icon class="!font-material-icons text-[#24292E] !w-4 !text-base rotate-90">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="menu-dropdown">
            <button mat-menu-item (click)="edit(item)">Edit</button>
            <button mat-menu-item (click)="confirmDelete(item)">Delete</button>
        </mat-menu>
          </div>
          <div *ngIf="!profileImage">
            <strong class="text-base normal-case">{{ item.author }}</strong>
            <span class="text-xs text-[#6A737D] normal-case" *ngIf="item?.title">&nbsp;•&nbsp;{{ item?.title }}</span>
          </div>

          <ng-container>
            <div>
              <div class="text-ellipsis overflow-hidden font-semibold mb-1 cursor-pointer" routerLink="/posts/{{item.id}}">
                <p class="text-sm text-[#24292E] pt-2 innerHtml leading-relaxed font-normal text-sm" [innerHTML]="item?.content"></p>

              </div>
              <div class="flex justify-center items-center cursor-pointer" routerLink="/posts/{{item.id}}">
                <img class=" rounded-xl  my-2 " [ngClass]="imageRatio ? '' : ' aspect-video'" *ngIf="item?.image" src="{{item?.image}}"
                  alt="post-image" />
              </div>
              <div class="card__sec-1__uni">
                <div>
                
                  <div class="flex justify-between w-full items-center ">
                    <div
                      class="flex items-center gap-x-1 border border-[#E1E4E8] rounded-full px-2 py-[2px] cursor-pointer"
                      (click)="updateLike(item)">
                      <svg *ngIf="item?.likedBy?.includes(profileId)" width="19" height="17" viewBox="0 0 19 17"
                        fill="#24292E" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.3597 6.90232H3.8597V16.9023H1.3597C0.899467 16.9023 0.526367 16.5293 0.526367 16.069V7.73565C0.526367 7.27542 0.899467 6.90232 1.3597 6.90232ZM5.77044 5.82491L11.1042 0.491185C11.2508 0.34456 11.4829 0.328068 11.6488 0.452476L12.3593 0.985343C12.7632 1.28828 12.9452 1.80445 12.8206 2.29373L11.8595 6.06898H17.193C18.1135 6.06898 18.8597 6.81518 18.8597 7.73565V9.48926C18.8597 9.70701 18.817 9.92259 18.7342 10.1238L16.1555 16.3863C16.0269 16.6985 15.7226 16.9023 15.3849 16.9023H6.3597C5.89947 16.9023 5.52637 16.5293 5.52637 16.069V6.41417C5.52637 6.19315 5.61417 5.98119 5.77044 5.82491Z"
                          fill="#004481" />
                      </svg>
                      <svg *ngIf="!item?.likedBy?.includes(profileId)" width="19" height="18" viewBox="0 0 19 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.8595 7.06945H17.193C18.1135 7.06945 18.8597 7.81564 18.8597 8.73609V10.4898C18.8597 10.7074 18.817 10.923 18.7342 11.1243L16.1555 17.3868C16.0269 17.699 15.7226 17.9028 15.3849 17.9028H1.3597C0.899467 17.9028 0.526367 17.5297 0.526367 17.0694V8.73609C0.526367 8.27587 0.899467 7.90278 1.3597 7.90278H4.26123C4.53201 7.90278 4.78589 7.77122 4.94204 7.55001L9.48653 1.11197C9.60528 0.943745 9.82912 0.887488 10.0133 0.979576L11.525 1.73543C12.4014 2.17362 12.8539 3.16327 12.6123 4.1128L11.8595 7.06945ZM5.52637 9.22567V16.2361H14.8269L17.193 10.4898V8.73609H11.8595C10.7723 8.73609 9.9762 7.71191 10.2444 6.65826L10.9971 3.70162C11.0455 3.51171 10.9549 3.31378 10.7796 3.22615L10.2287 2.95067L6.30366 8.51116C6.09541 8.80617 5.82921 9.04801 5.52637 9.22567ZM3.8597 9.56942H2.19303V16.2361H3.8597V9.56942Z"
                          fill="#24292E" />
                      </svg>


                      <span class="text-xs pt-1"
                        [ngClass]="{'text-primary': item?.likedBy?.includes(profileId), 'text-darkBlack': !item?.likedBy?.includes(profileId)}"
                        *ngIf="item?.likedBy?.length">{{item?.likedBy?.length || 1}}</span>
                    </div>


                    <div class="flex gap-x-1">
                      <span class="text-xs text-[#6A737D]">{{ item?.date | dateAgo }}</span>
                    </div>
                  </div>
                  <!-- <div class="text-xs text-[#6A737D]">
                    {{ item?.date | date }}
                  </div> -->
                </div>
              </div>
            </div>

          </ng-container>
         </a>
      </div>
      </div>