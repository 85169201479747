import { COLLECTION_NAMES } from "src/app/constants/constants";


export const dynamicElementTypes = {
    imageUpload: 'IMAGE_UPLOAD',
    select: "SELECT",
    textArea: "TEXT_AREA",
    text: "TEXT",
    email: "EMAIL",
    url: "URL",
    multiSelect: "MULTI_SELECT",
    date: "DATE",
    time: "TIME",
    title: "TITLE",
    checkbox: "CHECKBOX",
    toggle: "TOGGLE"
};

export const dynamicPageElements = {
    researcher: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/Icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "",
                description: "",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your university",
                data: [],
                showSearch: true,
                attribute: "university"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Add your faculty",
                data: [],
                showSearch: true,
                attribute: "universityFaculty"
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your title",
                data: [],
                showSearch: true,
                attribute: "universityTitle"
            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add your bio",
                data: [],
                attribute: "bio"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add your google scholar profile",
                data: [],
                attribute: "googleScholar"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add your academic homepage",
                data: [],
                attribute: "website"
            }, {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    },
    student: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/unicvercity_student_Icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "",
                description: "",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your university",
                data: [],
                showSearch: true,
                attribute: "university"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Choose your program",
                data: [],
                showSearch: false,
                attribute: "program"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Specialization",
                data: [],
                showSearch: false,
                attribute: "specialization"
            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add your bio",
                data: [],
                attribute: "bio"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add your google scholar profile",
                data: [],
                attribute: "googleScholar"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add your academic homepage",
                data: [],
                attribute: "website"
            }, {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    },
    others: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/other_icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "",
                description: "",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add your workplace",
                data: [],
                attribute: "workPlace"
            },
            {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add your bio",
                data: [],
                attribute: "bio"
            },
            {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add your website",
                data: [],
                attribute: "website"
            },
            {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    },
    institute: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/institute_icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "upload-institute.svg",
                description: "Use  .png or .jpg logo with transparent background and dimensions of 512x512px",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your university",
                data: [],
                showSearch: true,
                attribute: "university"
            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add headline",
                data: [],
                showSearch: false,
                attribute: "headline"
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Country",
                data: [],
                showSearch: true,
                attribute: "country",
                loadCityByCountry: true
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "City",
                data: [],
                showSearch: true,
                attribute: "city",
                addCustomTag: true,
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Street address",
                data: [],
                showSearch: false,
                attribute: "street"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "ZIP code",
                data: [],
                showSearch: false,
                attribute: "pinCode"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add homepage",
                data: [],
                attribute: "website"
            }, {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    },
    studentorg: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/institute_icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "upload-institute.svg",
                description: "Use  .png or .jpg logo with transparent background and dimensions of 512x512px",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your university",
                data: [],
                showSearch: true,
                attribute: "university"
            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add headline",
                data: [],
                showSearch: false,
                attribute: "headline"
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Country",
                data: [],
                showSearch: true,
                attribute: "country",
                loadCityByCountry: true
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "City",
                data: [],
                showSearch: true,
                attribute: "city",
                addCustomTag: true,
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Street address",
                data: [],
                showSearch: false,
                attribute: "street"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "ZIP code",
                data: [],
                showSearch: false,
                attribute: "pinCode"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add homepage",
                data: [],
                attribute: "website"
            }, {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    },
    ngo: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/institute_icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "upload-institute.svg",
                description: "Use  .png or .jpg logo with transparent background and dimensions of 512x512px",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your university",
                data: [],
                showSearch: true,
                attribute: "university"

            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add headline",
                data: [],
                showSearch: false,
                attribute: "headline"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Street address",
                data: [],
                showSearch: false,
                attribute: "street"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "City",
                data: [],
                showSearch: false,
                attribute: "city"
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Country",
                data: [],
                showSearch: true,
                attribute: "country"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "ZIP code",
                data: [],
                showSearch: false,
                attribute: "pinCode"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add homepage",
                data: [],
                attribute: "homePage"
            }, {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    },
    govtorg: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/institute_icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "upload-institute.svg",
                description: "Use  .png or .jpg logo with transparent background and dimensions of 512x512px",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your university",
                data: [],
                showSearch: true,
                attribute: "university"

            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add headline",
                data: [],
                showSearch: false,
                attribute: "headline"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Street address",
                data: [],
                showSearch: false,
                attribute: "street"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "City",
                data: [],
                showSearch: false,
                attribute: "city"
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Country",
                data: [],
                showSearch: true,
                attribute: "country"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "ZIP code",
                data: [],
                showSearch: false,
                attribute: "pinCode"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add homepage",
                data: [],
                attribute: "homePage"
            }, {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    },
    pvtorg: {
        title: "Complete your profile",
        icon: "../../../assets/signup-list/institute_icon.svg",
        showSkip: true,
        elements: [
            {
                type: dynamicElementTypes.imageUpload,
                icon: "upload-institute.svg",
                description: "Use  .png or .jpg logo with transparent background and dimensions of 512x512px",
                buttonLabel: "ADD PHOTO",
                attribute: "logo"
            },
            {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Choose your university",
                data: [],
                showSearch: true,
                attribute: "university"

            }, {
                type: dynamicElementTypes.textArea,
                icon: "",
                description: "",
                title: "Add headline",
                data: [],
                showSearch: false,
                attribute: "headline"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "Street address",
                data: [],
                showSearch: false,
                attribute: "street"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "City",
                data: [],
                showSearch: false,
                attribute: "city"
            }, {
                type: dynamicElementTypes.select,
                icon: "",
                description: "",
                title: "Country",
                data: [],
                showSearch: true,
                attribute: "country"
            }, {
                type: dynamicElementTypes.text,
                icon: "",
                description: "",
                title: "ZIP code",
                data: [],
                showSearch: false,
                attribute: "pinCode"
            }, {
                type: dynamicElementTypes.url,
                icon: "",
                description: "",
                title: "Add homepage",
                data: [],
                attribute: "homePage"
            }, {
                type: dynamicElementTypes.multiSelect,
                icon: "",
                description: "",
                title: "Expertise/Interest",
                data: [],
                showSearch: true,
                attribute: "subThemeIds"
            },
        ]
    }
}

export const dbConstants = {
    university: {
        db: COLLECTION_NAMES.universities,
        loadFromDB: true,
        data: []
    },
    researchers: {
        db: COLLECTION_NAMES.researchers,
        loadFromDB: true,
        data: []
    },
    universityFaculty: {
        db: COLLECTION_NAMES.faculties,
        loadFromDB: true
    },
    subThemeIds: {
        db: COLLECTION_NAMES.themes,
        loadFromDB: true,
        isThemes: true,
        isInternalSearch: true
    },
    universityTitle: {
        loadAsset: true,
        constant: "designation",
        loadFromDB: false,
        isInternalSearch: true
    },
    country: {
        loadAsset: true,
        constant: "countries",
        loadFromDB: false,
        isInternalSearch: true
    },
    year: {
        loadAsset: false,
        constant: "year",
        loadFromDB: false,
        isInternalSearch: true
    },
    format: {
        loadAsset: false,
        constant: "format",
        loadFromDB: false,
        isInternalSearch: true
    },
    journal: {
        db: COLLECTION_NAMES.journals,
        loadFromDB: true,
        isInternalSearch: false
    },
    instituteId: {
        db: COLLECTION_NAMES.institutes,
        loadFromDB: true,
        isInternalSearch: false
    },
    city: {
        loadAsset: false,
        constant: "city",
        loadFromDB: false,
        isInternalSearch: true
    },
    audience: {
        db: COLLECTION_NAMES.grants,
        loadFromDB: true,
        isInternalSearch: false
    },
    type: {
        db: COLLECTION_NAMES.jobs,
        loadFromDB: true,
        isInternalSearch: false
    },
    jobSkills: {
        loadAsset: true,
        constant: "skills",
        loadFromDB: false,
        isInternalSearch: true
    },
};

export const entityConstants = {
    researcher: "RESEARCHER",
    student: "STUDENT",
    institute: "INSTITUTE",
    studentOrg: "STUDENT_ORG",
    ngo: "NGO",
    govtOrg: "GOVT_ORG",
    pvtOrg: "PVT_ORG",
    others: "OTHERS"
};

export const signUpEntityElements = [
    {
        icon: '../../../../../assets/signup-list/Icon.svg',
        constant: entityConstants.researcher,
        title: 'University researcher',
        description: 'An academic conducting research on sustainability-related topics.'
    },
    {
        icon: '../../../../../assets/signup-list/unicvercity_student_Icon.svg',
        constant: entityConstants.student,
        title: 'University student',
        description: 'A student enrolled in a university, interested or involved in sustainability initiatives.'
    },
    {
        icon: '../../../../../assets/signup-list/institute_icon.svg',
        constant: entityConstants.institute,
        title: 'University institute',
        description: 'An institute affiliated to a university, involved in sustainability.'
    },
    {
        icon: '../../../../../assets/signup-list/student_icon.svg',
        constant: entityConstants.studentOrg,
        title: 'Student organization',
        description: 'A group of students organized around sustainability causes and activities.'
    },
    // {
    //     icon: '../../../../../assets/signup-list/ngo_icon.svg',
    //     constant: entityConstants.ngo,
    //     title: 'NGO',
    //     description: 'A non-governmental organization dedicated to sustainability and environmental advocacy.'
    // },
    // {
    //     icon: '../../../../../assets/signup-list/gov_icon.svg',
    //     constant: entityConstants.govtOrg,
    //     title: 'Government organization',
    //     description: 'A public sector body working on policies and projects for sustainable development.'
    // },
    // {
    //     icon: '../../../../../assets/signup-list/private_icon.svg',
    //     constant: entityConstants.pvtOrg,
    //     title: 'Private organization',
    //     description: 'A business or corporation implementing sustainable practices or producing eco-friendly products.'
    // },
    // {
    //     icon: '../../../../../assets/signup-list/other_icon.svg',
    //     constant: entityConstants.others,
    //     title: 'Other',
    //     description: 'Any other entity or individual engaged in sustainability efforts that doesn t fit the above categories.'
    // }
]

export const reasearchEntityElements = signUpEntityElements.slice(0, 2)
export const instituteEntityElements = signUpEntityElements.slice(2, signUpEntityElements.length)