import { Component, HostListener, Input, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ShEmptyStateComponent } from '../sh-profile-datasets/sh-empty-state/sh-empty-state.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, of, Subject, take, takeUntil } from 'rxjs';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { ShAddMyItemsModelComponent } from '../sh-profile-datasets/sh-add-datasets-model/sh-add-my-items-model.component';
import { COLLECTION_NAMES, COLORS, PAGINATION_PER_PAGE_DATA } from 'src/app/constants/constants';
import { ShProfileDeleteModelComponent } from '../../../sh-profile-delete-model/sh-profile-delete-model.component';
import { myProfileItems } from '../../../util';
import { getColorByName, getLighterColor } from 'src/app/utils/utils';
import { ShThemeItemComponent } from 'src/app/components/sh-theme-item/sh-theme-item.component';
import { MatChipsModule } from '@angular/material/chips';
import { Router, RouterModule } from '@angular/router';
import { ShLoadMoreComponent } from 'src/app/components/common/sh-load-more/sh-load-more.component';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';

@Component({
  selector: 'app-sh-profile-event-conference',
  standalone: true,
  imports: [
    ShLoadMoreComponent, ShSpinnerLoaderComponent,
    RouterModule, CommonModule, MatMenuModule, MatIconModule, MatButtonModule, ShThemeItemComponent, MatChipsModule, ShEmptyStateComponent],
  templateUrl: './sh-profile-event-conference.component.html',
  styleUrls: ['./sh-profile-event-conference.component.scss']
})
export class ShProfileEventConferenceComponent {
  @Input() profile: any
  @Input() moduleEntity: string;
  @Input() entity;
  @Input() isSavedItems?: boolean;
  showLoadMore: boolean;
  events: any;
  loading: boolean;

  @Input() set selectedTab(value) {
    this.loadData()
  }

  destroy$ = new Subject();
  myProfileItems = myProfileItems;
  events$: any;
  themesArray: any;
  subThemes: any;
  collectionName: any;
  emptyStateAddButtonLabel: string;

  constructor(
    private router: Router,
    private fbService: FirebaseCollectionService,
    private breakpointObserver: BreakpointObserver,
    public dialogModel: MatDialog,
    private _bottomSheet: MatBottomSheet,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShAddMyItemsModelComponent>,
    @Optional() public dialogRef: MatDialogRef<ShAddMyItemsModelComponent>,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    // this.loadData();
  }

  @HostListener('click', ['$event'])
  public onClickMenu(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadData() {
    const authorId = this.profile?.id;
    this.collectionName = null;
    if (this.isEvents()) {
      this.emptyStateAddButtonLabel = "ADD AN EVENT";
      this.collectionName = COLLECTION_NAMES.events;
    } else {
      this.emptyStateAddButtonLabel = "ADD A CONFERENCE";
      this.collectionName = COLLECTION_NAMES.conferences;
    }

    this.fetchData(authorId, this.collectionName);
    this.fetchThemes();
  }

  isEvents() {
    return this.moduleEntity === myProfileItems.events;
  }

  isConference() {
    return this.moduleEntity === myProfileItems.conference;
  }

  fetchData(authorId, collectionName, next?) {
    this.loading = true;
    const filters: any = !this.isSavedItems ? [{
      field: 'authorId',
      comparator: '==',
      value: authorId,
    }] : [{
      field: 'savedBy',
      comparator: 'array-contains',
      value: authorId,
    }];
    // this.collectionName = COLLECTION_NAMES.datasets;
    this.events$ = this.fbService
      .queryCollectionSnapshot(
        collectionName,
        filters || [],
        [{ field: 'index', condition: 'desc' }],
        PAGINATION_PER_PAGE_DATA,
        !next ? null : this.events[this.events?.length - 1]['ref'],
        null,
        true
      ).pipe(
        takeUntil(this.destroy$),
        map(events => {
          this.loading = false;
          events?.forEach(event => {
            event.day = this.getDays(event.startDate, event.endDate);
            event.month = this.getMonths(event.startDate, event.endDate);
            event.year = this.getYears(event.startDate, event.endDate);
          });

          if (!next) {
            this.events = events;
          } else {
            // this.events = [...(this.events || []), ...(events || [])];
            events.forEach(event => {
              if (!this.events.find(ev => ev.id === event.id)) {
                this.events.push(event);
              }
            });
          }

          if (this.events?.length >= PAGINATION_PER_PAGE_DATA) {
            this.showLoadMore = true;
          } else {
            this.showLoadMore = false;
          }


          return this.events;
        }),
        catchError(e => {
          this.loading = false;
          return of(e);
        })
      );
  }

  loadMoreData() {
    this.fetchData(this.profile?.id, this.collectionName, true);
  }

  fetchThemes() {
    this.fbService
      .queryCollectionSnapshot(COLLECTION_NAMES.themes)
      .pipe(
        take(1),
        map((themes) => {
          this.themesArray = themes;
          themes.forEach((theme, index) => {
            theme['color'] = theme.color || getColorByName(theme.name);
            theme['secondaryColor'] = getLighterColor(theme.color, 0.3);

            (theme?.subThemes || []).forEach(subTheme => {
              subTheme.secondaryColor = theme.secondaryColor;
              this.subThemes = Object.assign(this.subThemes || {}, { [subTheme.id]: subTheme }); // [subTheme.id] = subTheme;
            });
          });
        }),
        catchError(err => {
          return of(err);
        })
      )
      .subscribe();
  }

  getDays(startDate, endDate) {
    const d1 = new Date(startDate).toLocaleString('default', {
      day: '2-digit',
    });
    const d2 = new Date(endDate).toLocaleString('default', { day: '2-digit' });
    return d1.toString();
  }

  getYears(startDate, endDate) {
    const d1 = new Date(startDate).getFullYear();
    const d2 = new Date(endDate).getFullYear();
    return d1.toString();
  }

  getMonths(startDate, endDate) {
    const d1 = new Date(startDate).toLocaleString('default', {
      month: 'short',
    });
    return d1.toString();
  }

  edit(post?): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShAddMyItemsModelComponent, {
          data: {
            entity: this.isEvents() ? myProfileItems.events : myProfileItems.conference,
            profile: this.profile,
            data: post
          }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialogModel.open(ShAddMyItemsModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: {
            entity: this.isEvents() ? myProfileItems.events : myProfileItems.conference,
            profile: this.profile,
            data: post
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {

          }
        });
      }
    });
  }

  confirmDelete(item) {
    const modalRef = this.dialogModel.open(ShProfileDeleteModelComponent, {
      width: '350px',
      //  height: '300px',
      panelClass: 'profile-model',
      autoFocus: false,
      data: {
        entity: this.isEvents() ? myProfileItems.events : myProfileItems.conference,
      }
    });

    modalRef.afterClosed().pipe(
      take(1),
      map(confirmed => {
        if (confirmed) {
          this.delete(item);
        }
      })
    ).subscribe();

  }

  delete(event) {
    this.fbService.deleteDocument(this.collectionName, event?.id).pipe(
      take(1),
      map(res => {
        const entity = this.isEvents() ? "Events" : "Conference";
        this.snackBar.open(entity + " deleted successfully!", "OK");
      })
    ).subscribe();
  }

  view(event) {
    if (this.isEvents()) {
      this.router.navigate(['./events/' + event?.id]);
    } else {
      this.router.navigate(['./conferences/' + event?.id]);
    }
  }
}
