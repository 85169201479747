import { format } from "../utils/date-service.utils";


export interface Post {
    author?: string,
    content?: string,
    date?: string,
    image?: string,
    instituteIds?: string[],
    likedBy?: string[] | [],
    likes?: number,
    link?: string,
    authorId?: string,
    title?: string,
    updated?: string
}

export function postDbo(data, existingPost?) {
    const dbo: Post = {};

    dbo.author = data?.author || null;
    dbo.content = data?.content || null;
    dbo.date = existingPost?.date || format(new Date()) || null;
    dbo.image = data?.image || null;
    dbo.instituteIds = getInstitutes(data);
    dbo.likedBy = existingPost?.likedBy || [];
    dbo.likes = existingPost?.likes || 0;
    dbo.link = data?.link || null;
    dbo.authorId = data?.authorId || null;
    dbo.title = data?.title || null;
    if (existingPost) {
        dbo.updated = format(new Date());
    }
    // console.log(dbo);
    return dbo;
}

export function getInstitutes(data) {
    const instituteIds = [];
    if (data?.instituteIds?.length) {
        data?.instituteIds?.forEach(institute => {
            const id = institute.id || institute;
            if (!instituteIds.includes(id)) {
                instituteIds.push(id);
            }
        });
    }

    return instituteIds;
}
