import { myProfileItems } from "src/app/containers/profile/util";

export function isDataSet(entity) {
    return entity === myProfileItems.dataset;
}

export function isPapers(entity) {
    return entity === myProfileItems.papers;
}

export function isPosts(entity) {
    return entity === myProfileItems.posts;
}

export function isEvent(entity) {
    return entity === myProfileItems.events;
}

export function isConference(entity) {
    return entity === myProfileItems.conference;
}

export function isGrants(entity) {
    return entity === myProfileItems.grants;
}

export function isJobs(entity) {
    return entity === myProfileItems.jobs;
}