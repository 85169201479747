import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { DefaultLayoutComponent } from './layouts/default.layout/default.layout.component';
import { ProjectComponent } from './components/project/project.component';
import { NewsComponent } from './components/news/news.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SessionComponent } from './components/session/session.component';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';
import { FirebaseEmailActionComponent } from './components/session/firebase-email-action/firebase-email-action.component';
import { OpenDataAccessComponent } from './components/open-data-access/open-data-access.component';
import { ConfirmationResetComponent } from './components/session/confirmation-reset/confirmation-reset.component';
import { AdminComponent } from './components/admin/admin.component';
import { IsAdminGuard } from './guards/isAdmin.guard';
import { ErrorForbiddenComponent } from './components/shared/error-forbidden/error-forbidden.component';
import { SustainabilityDirectoryComponent } from './components/sustainability-directory/sustainability-directory.component';
import { InstitutePageComponent } from './components/institute-page/institute-page.component';
import { LoginPageComponent } from './containers/login/login-page/login-page.component';
import { AuthGuardService } from './guards/auth.guard';
import { UnAuthGuardService } from './guards/un-auth.guard';
import { ComponentDeactivateGuard } from './guards/component-deactivate.guard';
import { APP_URL } from './constants/constants';
import { UserGuardService } from './guards/user.guard';
import { AdminPanelGuardService } from './guards/admin-panel.guard';

const redirectAuthorizedToHome = () => redirectLoggedInTo(['/']);

const routes: Routes = [
  {
    path: '',
    canActivate: [UserGuardService],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        component: HomepageComponent,
      },
      {
        path: 'about',
        loadComponent: () =>
          import('./components/about/about.component').then(
            (comp) => comp.AboutComponent
          ),
      },
      {
        path: 'team',
        loadComponent: () =>
          import('./components/team/team.component').then(
            (comp) => comp.TeamComponent
          ),
      },
      {
        path: 'news',
        component: NewsComponent,
      },
      {
        path: 'institute-directory',
        component: SustainabilityDirectoryComponent,
      },
      {
        path: 'institutes',
        loadChildren: () =>
          import('./containers/institutes/institutes.module').then(
            (module) => module.InstitutesModule
          ),
      },
      {
        path: 'researchers',
        loadChildren: () =>
          import('./containers/researchers/researchers.module').then(
            (module) => module.ResearchersModule
          ),
      },
      {
        path: 'research-papers',
        loadChildren: () =>
          import('./containers/papers/papers.module').then(
            (module) => module.PapersModule
          ),
      },
      {
        path: 'datasets',
        loadChildren: () =>
          import('./containers/datasets/datasets.module').then(
            (module) => module.DatasetsModule
          ),
      },
      {
        path: 'conferences',
        loadChildren: () =>
          import('./containers/conferences/conferences.module').then(
            (module) => module.ConferencesModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./containers/events/events.module').then(
            (module) => module.EventsModule
          ),
      },
      {
        path: 'grants',
        loadChildren: () =>
          import('./containers/grants/grants.module').then(
            (module) => module.GrantsModule
          ),
      },
      {
        path: 'jobs',
        loadChildren: () =>
          import('./containers/jobs/jobs.module').then(
            (module) => module.JobsModule
          ),
      },
      {
        path: 'posts',
        loadChildren: () =>
          import('./containers/post/post.module').then(
            (module) => module.PostModule
          ),
      },
      {
        path: 'privacy-policy',
        loadComponent: () =>
          import('./components/privacy/privacy.component').then(
            (comp) => comp.PrivacyComponent
          ),
      },
      {
        path: 'terms',
        loadComponent: () =>
          import('./components/terms/terms.component').then(
            (comp) => comp.TermsComponent
          ),
      },
      {
        path: 'institute/:id',
        component: InstitutePageComponent,
      },
      {
        path: 'project/:id',
        component: ProjectComponent,
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [IsAdminGuard],
      },
      {
        path: '403',
        component: ErrorForbiddenComponent,
      },
      {
        path: 'profile',
        canActivate: [UnAuthGuardService],
        loadChildren: () =>
          import('./containers/profile/profile.module').then(
            (module) => module.ProfileModule
          ),
      },
      {
        path: 'settings',
        canActivate: [UnAuthGuardService],
        canDeactivate: [ComponentDeactivateGuard],
        loadComponent: () =>
          import('./containers/account-settings/account-settings.component').then(
            (module) => module.AccountSettingsComponent
          ),
      },
      {
        path: APP_URL.adminPanel,
        canActivate: [AdminPanelGuardService],
        loadComponent: () =>
          import('../app/components//admin-panel/admin-panel.component').then(
            (module) => module.AdminPortalComponent
          ),
      },
    ],
  },
  {
    path: 'action',
    component: FirebaseEmailActionComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectAuthorizedToHome },
  },
  {
    path: 'open-data-access',
    component: OpenDataAccessComponent,
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuardService, UserGuardService],
    loadChildren: () =>
      import('./containers/onboarding/onboarding.module').then(
        (module) => module.OnboardingModule
      ),
  },
  {
    path: 'auth',
    canActivate: [AuthGuardService, UserGuardService],
    loadChildren: () =>
      import('./containers/login/login.module').then(
        (module) => module.LoginModule
      ),
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'session',
        component: SessionComponent,
      },
      {
        path: 'session/confirmation',
        component: ConfirmationResetComponent,
      },
    ],
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectAuthorizedToHome },
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./components/logout/logout.component').then(
        (module) => module.LogoutComponent
      ),
  },
  {
    path: APP_URL.verifyEmail,
    // canActivate: [UserGuardService],
    loadComponent: () =>
      import('../app/components/verify-email/verify-email.component').then(
        (module) => module.VerifyEmailComponent
      ),
  },
  {
    path: APP_URL.claimProfileInstitute,
    // canActivate: [UserGuardService],
    loadComponent: () =>
      import('../app/components/email-verification/email-verification.component').then(
        (module) => module.EmailVerificationComponent
      ),
  },
  {
    path: APP_URL.claimProfileResearcher,
    // canActivate: [UserGuardService],
    loadComponent: () =>
      import('../app/components/email-verification/email-verification.component').then(
        (module) => module.EmailVerificationComponent
      ),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
    }),
  ],
  exports: [RouterModule],
})
export class
  AppRoutingModule { }
