import { inject, Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateFn,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, map, Observable, of, switchMap, take } from 'rxjs';
import { ALLOWED_DOMAIN, APP_URL, USER_ROLES } from '../constants/constants';

@Injectable({
    providedIn: 'root',
})
export class AdminPanelGuardService implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private afa: AngularFireAuth) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.afa.authState.pipe(
            take(1),
            switchMap(auth => {
                if (!!auth) {
                    return this.auth.getUser().pipe(
                        take(1),
                        switchMap((user: any) => {
                            if (user?.roles?.includes(USER_ROLES.superAdmin)) {
                                return of(true);
                            }

                            return this.router.navigate(['/']);
                        })
                    );
                } else {
                    // return of(true);
                    return this.router.navigate(['/']);
                }
            })
        );
    }
}

export const OnBoardingGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AdminPanelGuardService).canActivate(next, state);
}