<div [class.dark-theme]="true" [ngClass]="unAuthPage ? 'p-4 md:pt-10 md:pb-4' : ' md:pt-10 md:pb-4'" class="flex flex-col justify-between  overflow-y-auto">
    <div class="flex flex-col items-center">

        <!-- <p class="text-2xl md:text-xl font-bold text-darkBlack pt-6 tracking-[-0.5px] text-center">Let’s join forces.
            <br class="md:hidden block"> There’s no time to lose
        </p> -->
        <div *ngIf="unAuthPage">
            <img src="/assets/images/susan-logo.svg" width="135" height="56" alt="susan-logo" />
        </div>




        <p class="text-2xl md:text-xl font-bold text-darkBlack pt-6 tracking-[-0.5px] text-center">
            Login to your Account
        </p>
        <small *ngIf="unAuthPage">Please create an account to add a {{unAuthPage}}.</small>

        <div [ngClass]="unAuthPage ? '' : 'h-[calc(100vh_-_17rem)]'" class=" w-full md:w-[400px] overflow-x-hidden overflow-y-auto py-4">
            <div class="flex flex-col text-darkBlack w-full md:w-[400px] gap-y-2 ">

                <div class="error-message" *ngIf="showFBError" id="firebaseError">
                    <span>{{ fbErr }}</span>
                </div>


                <span class="text-sm">Login as</span>
                <app-sh-sign-role></app-sh-sign-role>
                <div class="flex flex-col text-darkBlack w-full md:w-[400px] gap-y-4 mt-2" fromGroup="form">

                    <!-- USEREMAIL FIELD-->
                    <app-sh-input-text [label]="'Email address'" [control]="form.get('email')"
                        [placeholder]="'eg: sara@mcgill.ca'" [errorMessage]="'Invalid email'"></app-sh-input-text>

                    <!-- PASSWORD FIELD -->
                    <div class="relative">
                        <app-sh-input-text [errorMessage]="'Password must be at least 8 characters'" [password]="true"
                            [control]="form.get('password')" [label]="'Create a password'"
                            [placeholder]="'Password must be at least 8 characters'">
                        </app-sh-input-text>
                        <a (click)="forgot()"
                            class="absolute right-0 top-0 text-primary hover:underline cursor-pointer">Forgot
                            Password?</a>
                    </div>


                    <div class="flex justify-between items-center md:flex-row flex-col-reverse pt-5">
                        <div class="flex text-sm text-primary mt-4 md:mt-0">
                            <span>New here?</span>

                            <a class="font-bold uppercase pl-1 cursor-pointer" (click)="signup()">Sign Up</a>
                        </div>
                        <button mat-flat-button color="primary" class="text-sm font-semibold w-full md:w-[120px] !h-11"
                            (click)="login()" [disabled]="loggingIn || !form.valid">
                            <div class="flex w-full justify-center">
                                <app-sh-spinner-loader *ngIf="loggingIn" [colorCode]="'#fff'" [roundWidth]=20
                                    [thinWidth]=2></app-sh-spinner-loader>
                                {{ loggingIn ? '' : 'LOGIN'}}
                            </div>
                        </button>
                    </div>

                </div>
            </div>
        </div>

        <span class="text-xs text-[#6A737D] text-center mt-5">© 2024 Sustainability Academic Network. All Right
            Reserved.</span>
    </div>