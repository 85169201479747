import { format } from "../utils/date-service.utils";
import { getCharCombo, getSubThemes, getSubThemesKeywords, getTermsAsKeywords, getThemeIds, getWordsCombo } from "./utils";

export interface Grants {
    ThemeIds?: string[],
    amount?: string,
    currency?: string,
    deadline?: string,
    description?: string,
    duration?: string,
    grantUrl?: string,
    index?: number,
    keywords?: string[],
    name?: string,
    recipient?: string,
    sponsor?: string,
    subThemeIds?: string[],
    type?: string,
    authorId?: string
}


export function grantsDbo(data, grant?, profile?) {
    const dbo: Grants = {};

    dbo.ThemeIds = getThemeIds(data?.subThemeIds, grant?.ThemeIds);
    dbo.amount = data.amount || null;
    dbo.currency = data.currency || null;
    dbo.deadline = format(data.deadline) || null;
    dbo.description = data.description || null;
    dbo.duration = data.duration || null;
    dbo.grantUrl = data?.grantUrl || null;;
    dbo.index = data?.index;
    dbo.keywords = buildKeywords(data, grant?.keywords);
    dbo.name = data.name;
    dbo.recipient = data?.recipient || null;
    dbo.sponsor = data?.sponsor || null;
    dbo.subThemeIds = getSubThemes(data?.subThemeIds);
    dbo.type = data.type?.id || data?.type || 'Grant';
    dbo.authorId = profile?.id

    // console.log(dbo);
    return dbo;
}


export function buildKeywords(data, existingKeywords) {
    let keywords = [];

    let nameArr = data?.name?.split("") || [];
    keywords = [...keywords, ...getCharCombo(nameArr, (existingKeywords || []))];


    const wordKeyword = [data.sponsor, ...data?.name?.split(" ")];
    keywords = [...keywords, ...getTermsAsKeywords(wordKeyword)];


    // Sub themes into Keywords
    if (data?.subThemeIds?.length) {
        keywords = [...keywords, ...getSubThemesKeywords(data.subThemeIds, existingKeywords)];
    }

    keywords = [...new Set([...keywords, ...existingKeywords || []])];

    return keywords;
}
