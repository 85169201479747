import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'cards-listing',
  templateUrl: './cards-listing.component.html',
  styleUrls: ['./cards-listing.component.scss'],
})
export class CardsListingComponent implements OnChanges, AfterViewInit {
  @Input() cssClasses = 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'
  @Input() colNumMobile = 1;
  @Input() colNumTablet = 2;
  @Input() colNumLarge = 2;
  @Input() colNumExtraLarge = 3;
  @Input() cardWidth: number = null;
  @Input() gap = 12;
  @Input() cardsPerPage = 6;
  @Input() totalPages = 1;
  @Input() isShowPageCount = true;

  private clientWidth = 0;

  @ViewChild('cardsListing') listingElem;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.clientWidth = document.body.clientWidth;
    if (this.listingElem) {
      this.applyStyles();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.listingElem) {
      this.applyStyles();
    }
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  applyStyles() {
    const elem = this.listingElem.nativeElement as HTMLDivElement;
    if (this.clientWidth < 760) {
      // mobile
      elem.style.gridTemplateColumns = this.applyGridTemplateColumns(
        elem,
        this.colNumMobile
      );
    } else if (this.clientWidth < 1024) {
      // tablet
      elem.style.gridTemplateColumns = this.applyGridTemplateColumns(
        elem,
        this.colNumTablet
      );
    } else if (this.clientWidth < 1280) {
      // large
      elem.style.gridTemplateColumns = this.applyGridTemplateColumns(
        elem,
        this.colNumLarge
      );
    } else {
      // xl screen
      elem.style.gridTemplateColumns = this.applyGridTemplateColumns(
        elem,
        this.colNumExtraLarge
      );
    }

    elem.style.gap = this.gap.toString() + 'px';
  }

  private applyGridTemplateColumns(elem: HTMLDivElement, colCount = 1) {
    let cardWidth = '';
    if (colCount === 1) {
      cardWidth = 'auto';
    } else {
      cardWidth =
        (
          this.cardWidth ||
          Math.floor(elem.clientWidth / colCount) - this.gap * colCount
        ).toString() + 'px';
    }

    let gridTemplateColumns = '';
    for (let index = 0; index < colCount; index++) {
      gridTemplateColumns += cardWidth + ' ';
    }

    return gridTemplateColumns;
  }
}
