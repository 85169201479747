import { format } from "../utils/date-service.utils";
import { getCharCombo, getSubThemes, getSubThemesKeywords, getTermsAsKeywords, getThemeIds, getWordsCombo } from "./utils";


export interface Event {
    city?: string,
    country?: string,
    description?: string,
    endDate?: string,
    endTime?: string,
    eventUrl?: string,
    locationUrl?: string,
    index?: number,
    keywords?: string[],
    logo?: string,
    mode?: string,
    name?: string,
    organizer?: string,
    postalCode?: string,
    price?: string,
    startDate?: string,
    startTime?: string,
    subThemeIds?: string[],
    ThemeIds?: string[],
    unorderedAddress?: string,
    venue?: string,
    author?: string,
    authorId?: string
}


export function eventDbo(data, event?, profile?) {
    const dbo: Event = {};
    dbo.author = data?.author || null;
    dbo.authorId = data?.authorId || null;
    dbo.city = data?.city?.id || data?.city || null
    dbo.country = data?.country?.id || data?.country || null
    dbo.description = data.description || null;
    dbo.endDate = format(data.endDate) || null;
    dbo.endTime = data?.endTime?.id || data.endTime || null;
    dbo.eventUrl = data.eventUrl || null;
    dbo.locationUrl = data.locationLink || null;
    dbo.index = data.index || null;
    dbo.keywords = buildKeywords(data, event?.keywords);
    dbo.logo = data?.logo || null;
    dbo.mode = data?.mode || null;
    dbo.name = data?.name || null;
    dbo.organizer = data?.organizer || null;
    dbo.postalCode = data?.postalCode || null;
    dbo.price = data?.price || null;
    dbo.startDate = format(data?.startDate) || null;
    dbo.startTime = data?.startTime?.id || data?.startTime || null
    dbo.subThemeIds = getSubThemes(data?.subThemeIds);
    dbo.ThemeIds = getThemeIds(data?.subThemeIds, event?.ThemeIds);
    dbo.unorderedAddress = data?.venue + ", " + (data?.city?.id || data?.city) + ", " + (data?.country?.id || data?.country);
    dbo.venue = data?.venue || null;

    // console.log(dbo);
    return dbo;
}

export function buildKeywords(data, existingKeywords) {
    let keywords = [];

    let nameArr = data?.name?.split("") || [];
    keywords = [...keywords, ...getCharCombo(nameArr, (existingKeywords || []))];

    const wordKeyword = [...data?.name?.split(" ")];
    keywords = [...keywords, ...getTermsAsKeywords(wordKeyword)];

    const { mode, organizer } = data;
    const country = data?.country?.id || data?.country;
    const city = data?.city?.id || data?.city;
    const otherKeywordAttr = [mode, organizer, country, city];
    keywords = [...keywords, ...getTermsAsKeywords(otherKeywordAttr, existingKeywords)];

    // Sub themes into Keywords
    if (data?.subThemeIds?.length) {
        keywords = [...keywords, ...getSubThemesKeywords(data.subThemeIds, existingKeywords)];
    }

    keywords = [...new Set([...keywords, ...existingKeywords || []])];

    return keywords;
}

