<div class="flex flex-col justify-center items-center mb-6 mt-4 rounded-xl w-ffull bg-white py-6">
  <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0916 45.1992H60.0916M12.0916 57.1992H60.0916M12.0916 18.1992C12.0916 17.4036 12.4076 16.6405 12.9702 16.0779C13.5328 15.5153 14.2959 15.1992 15.0916 15.1992H27.0916C27.8872 15.1992 28.6503 15.5153 29.2129 16.0779C29.7755 16.6405 30.0916 17.4036 30.0916 18.1992V30.1992C30.0916 30.9949 29.7755 31.7579 29.2129 32.3205C28.6503 32.8831 27.8872 33.1992 27.0916 33.1992H15.0916C14.2959 33.1992 13.5328 32.8831 12.9702 32.3205C12.4076 31.7579 12.0916 30.9949 12.0916 30.1992V18.1992Z"
      stroke="#6A737D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  <span class="text-[#24292E] text-center leading-relaxed" *ngIf="!isSavedItems">No Items Yet! Start contributing
    <br /> for a sustainable
    future. </span>

  <span class="text-[#24292E] text-center leading-relaxed" *ngIf="isSavedItems">No Items Yet! Start bookmarking <br />
    for a sustainable
    future. </span>

  <div class="w-full md:w-auto my-4" *ngIf="!isSavedItems && !viewOnly">
    <button class="!h-11 w-full !bg-white !px-4" mat-stroked-button (click)="addItem()">
      <span class="load-more text-base !text-primary font-bold">
        {{ btnText }}
      </span>
    </button>
  </div>
</div>