import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ShSpinnerLoaderComponent } from '../../sh-spinner-loader/sh-spinner-loader.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sh-members-list',
  standalone: true,
  imports: [CommonModule,RouterModule, MatButtonModule, ShSpinnerLoaderComponent],
  templateUrl: './sh-members-list.component.html',
  styleUrls: ['./sh-members-list.component.scss']
})
export class ShMembersListComponent {
  @Input() teams: any[];
  @Input() isAdd: boolean;
  @Input() boxClass: any = 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ';
  @Input() borderClass: any = 'gap-1 p-4 rounded-xl';
  @Input() isModel: boolean = false;

  @Output() updateResearcher = new EventEmitter();

  uploadingData: boolean = false;

  trackByResearcher(researcher) {
    return researcher?.id;
  }

  updateMember(member) {
    member.loading = true;
    this.updateResearcher.emit({
      researcher: member,
      isAdd: this.isAdd
    });
  }
}
