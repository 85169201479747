import { Component, HostListener, Input, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ShEmptyStateComponent } from '../sh-profile-datasets/sh-empty-state/sh-empty-state.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil, map, take, catchError, of, switchMap } from 'rxjs';
import { dateFormat, COLLECTION_NAMES, COLORS } from 'src/app/constants/constants';
import { FirebaseCollectionService } from 'src/app/services/firebase-collection.service';
import { getLighterColor } from 'src/app/utils/utils';
import { ShProfileDeleteModelComponent } from '../../../sh-profile-delete-model/sh-profile-delete-model.component';
import { myProfileItems } from '../../../util';
import { ShAddMyItemsModelComponent } from '../sh-profile-datasets/sh-add-datasets-model/sh-add-my-items-model.component';
import { Router, RouterModule } from '@angular/router';
import { ShSpinnerLoaderComponent } from 'src/app/components/common/sh-spinner-loader/sh-spinner-loader.component';

@Component({
  selector: 'app-sh-profile-job',
  standalone: true,
  imports: [CommonModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule, ShEmptyStateComponent, ShSpinnerLoaderComponent],
  templateUrl: './sh-profile-job.component.html',
  styleUrls: ['./sh-profile-job.component.scss']
})
export class ShProfileJobComponent {
  @Input() profile: any
  @Input() moduleEntity: string;
  @Input() isSavedItems?: boolean;
  loading: boolean;
  @Input() set selectedTab(value) {
    this.loadData()
  }

  destroy$ = new Subject();
  dateFormat = dateFormat;
  emptyStateAddButtonLabel = "ADD A JOB";
  myProfileItems = myProfileItems;
  jobs$: any;
  themesArray: any;
  subThemes: any;
  collectionName: any;

  constructor(
    private router: Router,
    private fbService: FirebaseCollectionService,
    private breakpointObserver: BreakpointObserver,
    public dialogModel: MatDialog,
    private _bottomSheet: MatBottomSheet,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ShAddMyItemsModelComponent>,
    @Optional() public dialogRef: MatDialogRef<ShAddMyItemsModelComponent>,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    // this.loadData();
  }

  @HostListener('click', ['$event'])
  public onClickMenu(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadData() {
    const authorId = this.profile?.id;
    this.collectionName = COLLECTION_NAMES.jobs;

    this.fetchData(authorId, this.collectionName);
    // this.fetchThemes();
  }

  fetchData(authorId, collectionName) {
    this.loading = true;
    const filters: any = !this.isSavedItems ? [{
      field: 'authorId',
      comparator: '==',
      value: authorId,
    }] : [{
      field: 'savedBy',
      comparator: 'array-contains',
      value: authorId,
    }];
    // this.collectionName = COLLECTION_NAMES.datasets;
    this.jobs$ = this.fbService
      .queryCollectionSnapshot(
        collectionName,
        filters || [],
        [], // { field: 'index', condition: 'asc' }
        10,
        null,
        null,
        true
      ).pipe(
        takeUntil(this.destroy$),
        switchMap(res => {
          this.loading = false;
          return of(res);
        }),
        catchError(e => {
          this.loading = false;
          return of(e);
        })
      );
  }

  edit(job?): void {
    // Use BreakpointObserver to check screen size
    this.breakpointObserver.observe([
      '(max-width: 767px)' // Custom breakpoint for below 767px
    ]).pipe(take(1)).subscribe(result => {
      if (result.breakpoints['(max-width: 767px)']) {
        // If the screen size matches mobile or tablet, open a bottom sheet
        const bottomSheetRef = this._bottomSheet.open(ShAddMyItemsModelComponent, {
          data: {
            entity: myProfileItems.jobs,
            profile: this.profile,
            data: job
          }
        });
        bottomSheetRef.afterDismissed().subscribe(result => { });
      } else {
        // Otherwise, open a dialog for larger screens
        const dialogRef = this.dialogModel.open(ShAddMyItemsModelComponent, {
          width: '450px',
          // height: '650px',
          panelClass: 'profile-model',
          data: {
            entity: myProfileItems.jobs,
            profile: this.profile,
            data: job
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {

          }
        });
      }
    });
  }

  confirmDelete(item) {
    const modalRef = this.dialogModel.open(ShProfileDeleteModelComponent, {
      width: '350px',
      //  height: '300px',
      panelClass: 'profile-model',
      autoFocus: false,
      data: {
        entity: myProfileItems.jobs,
      }
    });

    modalRef.afterClosed().pipe(
      take(1),
      map(confirmed => {
        if (confirmed) {
          this.delete(item);
        }
      })
    ).subscribe();

  }

  delete(event) {
    this.fbService.deleteDocument(this.collectionName, event?.id).pipe(
      take(1),
      map(res => {
        const entity = event?.name || event?.title;
        const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
        this.snackBar.open(`"${capitalize(entity)}" deleted successfully!, "OK"`);
        // this.snackBar.open(entity + " deleted successfully!", "OK");
      })
    ).subscribe();
  }

  view(event) {
    this.router.navigate(['./jobs/' + event?.id]);
  }
}
